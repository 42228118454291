import { ActionCreators } from 'redux-undo'
import { useSelector, useDispatch } from 'react-redux'
import { useClipboard } from '@chakra-ui/core'
import { KEY } from '../../constants/keyboard'
import { useOnDownKeyPress } from '../../hooks/use-onKeyDown'
import { arrayMove, stepDuration } from '../../shared-fns/shared-fns'
import {
    removeMultipleFromTimeline,
    getTimeline,
    getTimelineItem,
    getSceneDuration,
    getActiveSceneIdx,
    updateScene,
    removeScene,
    getScenes,
} from './stores/project-reducer'
import { useEdit, useTimer } from './edit-context'
import { useModalStore } from './modal-context'
import {
    getDragItemId,
    isTopLayer,
    isBottomLayer,
    getLayerIdx,
    CLIPBOARD_KEY,
    SIDE_BAR_LABELS,
    SCENE_CLIPBOARD_KEY,
} from './edit-config'
import { REDUX_HISTORY_UNDO_REDO } from '../../constants/events'
import { markerUtil } from '../Marker/marker-fns'

export const KeyboardShortcuts = ({ children }) => {
    const { setActiveMenu, setActiveItemId, active_item_id, active_item: all_active_items } = useEdit()
    const { onCopy } = useClipboard(all_active_items.map((i) => CLIPBOARD_KEY + getDragItemId(i)))

    const { timer, playVideo } = useTimer()

    const video_duration = useSelector(getSceneDuration)
    const active_scene_idx = useSelector(getActiveSceneIdx)
    const scenes = useSelector(getScenes)
    const active_item = useSelector(getTimelineItem(active_item_id))[0]
    const timeline = useSelector(getTimeline)
    const { onCopy: onCopyScene } = useClipboard(SCENE_CLIPBOARD_KEY + active_scene_idx)

    const {
        openImageSelectModal,
        closeImageSelectModal,
        openVideoSelectModal,
        closeVideoSelectModal,
        is_image_select_open,
        is_video_select_open,
        is_audio_select_open,
        openAudioSelectModal,
        closeAudioSelectModal,
        replaceImage,
        replaceVideo,
        is_link_upload_open,
        closeLinkUploadModal,
        openLinkUpload,
    } = useModalStore()

    const dispatch = useDispatch()
    const onRemoveItems = (id_arr) => dispatch(removeMultipleFromTimeline({ ids: id_arr }))

    const layerUp = () => {
        const item_idx = getLayerIdx(timeline, active_item_id)
        if (isTopLayer(timeline, item_idx)) return
        const new_timeline = arrayMove(timeline, item_idx, item_idx + 1)
        dispatch(updateScene({ key: 'timeline', updated_value: new_timeline }))
    }

    const layerDown = () => {
        const item_idx = getLayerIdx(timeline, active_item_id)
        if (isBottomLayer(item_idx)) return
        const new_timeline = arrayMove(timeline, item_idx, item_idx - 1)
        dispatch(updateScene({ key: 'timeline', updated_value: new_timeline }))
    }

    const clickHandler = (e) => {
        if (e.keep_active_layer) return
        if (
            !e.target.classList.contains('no-global-click') &&
            !e.target.classList.contains('moveable-control') &&
            !e.target.classList.contains('moveable-avoid')
        ) {
            setActiveItemId(null)
        }
    }

    const keyPressHandler = (key, e) => {
        if (e.target.classList.contains('no-global-key')) return
        if (e.target.id === 'color-picker') return
        if (e.target.id === 'link-upload-input') return
        if (e.target.nodeName === 'INPUT') return
        if (e.target.nodeName === 'TEXTAREA') return

        const is_ctrl = e.metaKey || e.ctrlKey

        if (key === KEY.U) {
            setActiveMenu(SIDE_BAR_LABELS.UPLOAD)
            return setActiveItemId(null)
        }
        if (key === KEY.T) {
            setActiveMenu(SIDE_BAR_LABELS.TEXT)
            return setActiveItemId(null)
        }
        if (key === KEY.B) {
            setActiveMenu(SIDE_BAR_LABELS.BACKGROUND)
            return setActiveItemId(null)
        }
        if (key === KEY.E) {
            setActiveMenu(SIDE_BAR_LABELS.ELEMENTS)
            return setActiveItemId(null)
        }
        if (key === KEY.I && !is_ctrl) {
            if (is_image_select_open) return closeImageSelectModal()
            else {
                e.preventDefault()
                return openImageSelectModal()
            }
        }
        if (key === KEY.V && !is_ctrl) {
            if (is_video_select_open) return closeVideoSelectModal()
            else {
                e.preventDefault()
                return openVideoSelectModal()
            }
        }
        if (key === KEY.A && !is_ctrl) {
            if (is_audio_select_open) return closeAudioSelectModal()
            else {
                e.preventDefault()
                return openAudioSelectModal()
            }
        }
        if (key === KEY.L && !is_ctrl) {
            if (is_link_upload_open) return closeLinkUploadModal()
            else {
                e.preventDefault()
                return openLinkUpload()
            }
        }

        if (key === KEY.ESC) {
            if (active_item_id) setActiveItemId(null)
        }
        if (key === KEY.BACK_SPACE || key === KEY.DELETE) {
            if (all_active_items && all_active_items.length) {
                const ids = all_active_items.map((e) => getDragItemId(e))
                onRemoveItems(ids)
                return setActiveItemId(null)
            } else {
                if (scenes.length > 1) {
                    timer.stopContinousPlay()
                    timer.shouldReset()

                    dispatch(removeScene({ scene_idx: active_scene_idx }))
                }
            }
        }
        if (key === KEY.C && is_ctrl) {
            if (active_item_id || all_active_items.length) onCopy()
            else onCopyScene()
        }
        if (key === KEY.Z && is_ctrl) {
            e.preventDefault()
            setActiveItemId(null)

            dispatch(ActionCreators.undo())
            return setTimeout(() => window.dispatchEvent(new Event(REDUX_HISTORY_UNDO_REDO)), 50)
        }
        if (key === KEY.Y && is_ctrl) {
            e.preventDefault()
            setActiveItemId(null)

            dispatch(ActionCreators.redo())
            return setTimeout(() => window.dispatchEvent(new Event(REDUX_HISTORY_UNDO_REDO)), 50)
        }
        if (key === KEY.R && !is_ctrl) {
            if (active_item_id && active_item) {
                e.preventDefault()

                if (markerUtil.isImage(active_item)) return replaceImage(active_item)
                if (markerUtil.isVideo(active_item) || markerUtil.isLottie(active_item)) {
                    return replaceVideo(active_item)
                }
            }
        }
        if (key === KEY.OPEN_BRACKET && is_ctrl && active_item_id) {
            e.preventDefault()
            return layerUp()
        }
        if (key === KEY.CLOSE_BRACKET && is_ctrl && active_item_id) {
            e.preventDefault()
            return layerDown()
        }
        if (key === KEY.TAB) {
            if (!timeline?.length) return

            if (!active_item && !active_item_id) {
                e.preventDefault()
                setActiveItemId(timeline[timeline.length - 1].id)
            }
            if (active_item_id && active_item) {
                e.preventDefault()
                const item_idx = timeline.findIndex((t) => t.id === active_item_id)
                const next_idx = item_idx - 1
                if (timeline[next_idx]) setActiveItemId(timeline[next_idx].id)
                else setActiveItemId(timeline[timeline.length - 1].id)
            }
        }
        if (key === KEY.SPACE_BAR) {
            e.preventDefault()
            return playVideo()
        }
        if (key === KEY.LEFT || key === KEY.RIGHT) {
            const time_step = key === KEY.LEFT ? -1 : 1
            e.preventDefault()
            timer.stopContinousPlay()
            timer.stop()

            return stepDuration({
                time_step,
                timer,
                total_duration: video_duration,
                current_time: timer.getMs() / 1000,
            })
        }
    }

    useOnDownKeyPress(keyPressHandler)

    return (
        <div onMouseDown={clickHandler} style={{ height: '100%', width: '100%' }}>
            {children}
        </div>
    )
}
