export const VIDEO_SIZES = [
    {
        name: 'Wide (Full HD)',
        resolution: [1920, 1080],
    },
    {
        name: 'Story',
        resolution: [1080, 1920],
    },
    {
        name: 'Square',
        resolution: [1080, 1080],
    },
]

export const getCanvasWidth = (ratio) => {
    return ratio[0]
}

export const getCanvasHeight = (ratio) => {
    return ratio[1]
}

export const getCanvasSize = (ratio) => {
    return ratio
}
