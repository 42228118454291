import { Redirect, Route } from 'react-router-dom'
import { useAuth } from '../Session'
import { FullWidthSpinner } from '../UI/spinner'

export const ProtectedRoute = ({ component, path }) => {
    const { is_logged_in, is_loading_auth } = useAuth()

    if (is_loading_auth) return <FullWidthSpinner />
    if (!is_logged_in) return <Redirect to="/signin" />

    return <Route exact component={component} path={path} />
}
