import uuid from 'react-uuid'
import clonedeep from 'lodash.clonedeep'
import { ALL_FONTS } from '../constants/fonts'
import { getCanvasWidth, getCanvasHeight } from '../constants/resolutions'
import { markerUtil } from '../components/Marker/marker-fns'

export const memo = (fn) => {
    const cache = {}

    function get(args) {
        let node = cache
        for (const arg of args) {
            if (!('next' in node)) node.next = new Map()
            if (!node.next.has(arg)) node.next.set(arg, {})
            node = node.next.get(arg)
        }
        return node
    }

    return function (...args) {
        const cache = get(args)
        if ('item' in cache) return cache.item
        cache.item = fn(...args)
        return cache.item
    }
}

export const isEmptyObject = (obj) => {
    if (!obj) return true
    return Object.keys(obj).length === 0
}

export const stepDuration = ({ time_step, current_time, total_duration, timer }) => {
    let actual_current_time = total_duration - current_time

    let new_time
    if (actual_current_time + time_step >= total_duration) {
        new_time = total_duration // end
    } else if (actual_current_time + time_step <= 0) {
        new_time = 0
    } else {
        new_time = actual_current_time + time_step
    }

    const time_formatted = total_duration * 1000 - new_time * 1000

    timer.reset(time_formatted === 0 ? 10 : time_formatted)
}

export const debounce = (func, wait, immediate) => {
    var timeout
    return function () {
        var context = this,
            args = arguments
        var later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

export const emptyFn = () => {}

export const throttle = (func, limit) => {
    let inThrottle
    return function () {
        const args = arguments
        const context = this
        if (!inThrottle) {
            func.apply(context, args)
            inThrottle = true
            setTimeout(() => (inThrottle = false), limit)
        }
    }
}

export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
    return { width: srcWidth * ratio, height: srcHeight * ratio }
}

export const createCanvasBg = (background) => {
    const style = {}
    style.background = background

    return style
}

export const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
}

export const stepRound = (value, step) => {
    step || (step = 1.0)
    var inv = 1.0 / step
    return Math.round(value * inv) / inv
}

export const loadStyleSheets = async (font_arr) => {
    const promise_arr = []
    for (let font of font_arr) {
        if (font) promise_arr.push(loadStyleSheet(font.link, font.name))
    }

    await Promise.all(promise_arr)
}

export const loadStyleSheet = function (url, id) {
    return new Promise((resolve, reject) => {
        // prevent loading the same stylesheet many times
        if (document.getElementById(id)) return resolve()
        const link = document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.id = id
        link.onload = function () {
            resolve()
        }
        link.href = url

        const headScript = document.querySelector('script')
        headScript.parentNode.insertBefore(link, headScript)
    })
}

export const getFont = (font_name) => ALL_FONTS.find((f) => f.name === font_name)

export const getAllFonts = (project) => {
    const all_fonts_in_project = [getFont("'Inter'")]

    project.scenes.forEach((s) => {
        s.timeline.forEach((marker) => {
            if (marker.type === 'text') {
                const { font_style } = marker
                const font = getFont(font_style)
                all_fonts_in_project.push(font)
            }
        })
    })

    return Array.from(new Set(all_fonts_in_project))
}

export function arrayMove(arr, fromIndex, toIndex) {
    let new_arr = [...arr]
    const element = new_arr[fromIndex]
    new_arr.splice(fromIndex, 1)
    new_arr.splice(toIndex, 0, element)

    return new_arr
}

let window_inner_width
export const isMobile = () => {
    if (!window_inner_width) window_inner_width = window.innerWidth
    return window_inner_width <= 768
}

const mac_regex = /(Mac|iPad)/i
export const isMacOs = () => mac_regex.test(navigator.platform)

export const getInnerWidth = () => {
    if (!window_inner_width) window_inner_width = window.innerWidth
    return window_inner_width
}

let window_inner_height
export const innerHeight = () => {
    if (!window.window_inner_height) window_inner_height = window.innerHeight
    return window_inner_height
}

export const getInitialScale = memo((video_ratio, box_width, box_height, is_percentage) => {
    const width = getCanvasWidth(video_ratio)
    const height = getCanvasHeight(video_ratio)

    let initial_ratio = 100
    const size_of_editor = width > height ? box_width : box_height
    const side = width > height ? width : height

    while (+side * (initial_ratio / 100) > size_of_editor) {
        initial_ratio = initial_ratio - 1
    }

    if (is_percentage) return initial_ratio / 100

    return initial_ratio
})

export const getAspectRatio = (project, active_scene) => {
    const { aspect_ratio } = project.scenes[active_scene]
    return aspect_ratio
}

export const getItemCount = (timeline, type) => {
    if (!timeline) return 0
    if (!type) return 0

    const count = timeline.filter((i) => i.type === type).length
    return count
}

export const snakeCase = (string) => {
    return string
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('_')
}

export const sanitize = (string) => {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;',
    }
    const reg = /[&<>"'/]/gi
    return string.replace(reg, (match) => map[match])
}

export function getTimeInterval(date) {
    let seconds = Math.floor((Date.now() - date) / 1000)
    let unit = 'second'
    let direction = 'ago'
    if (seconds < 0) {
        seconds = -seconds
        direction = 'from now'
    }
    let value = seconds
    if (seconds >= 31536000) {
        value = Math.floor(seconds / 31536000)
        unit = 'year'
    } else if (seconds >= 86400) {
        value = Math.floor(seconds / 86400)
        unit = 'day'
    } else if (seconds >= 3600) {
        value = Math.floor(seconds / 3600)
        unit = 'hour'
    } else if (seconds >= 60) {
        value = Math.floor(seconds / 60)
        unit = 'minute'
    }
    if (value !== 1) unit = unit + 's'
    return value + ' ' + unit + ' ' + direction
}

export const hasTemplateEditAccess = (user) => !!user?.has_template_edit_access
export const hasCopyProjectAccess = (user) => !!user?.has_copy_project_access

export const chunkArray = (arr, number_of_chunks) => {
    const chunk_length = Math.max(arr.length / number_of_chunks, 1)
    const chunks = []

    for (var i = 0; i < number_of_chunks; i++) {
        if (chunk_length * (i + 1) <= arr.length) {
            chunks.push(arr.slice(chunk_length * i, chunk_length * (i + 1)))
        }
    }

    return chunks
}

// find the time with the most layers in the scene
export const getThumbnailViewTime = (scene) => {
    const { timeline, duration } = scene

    let time_item_count = 0
    let time = 0

    for (let i = 0; i <= duration; i++) {
        let second_count = 0
        timeline.forEach((item) => {
            if (isWithinTime(item.time, i)) second_count++
        })
        if (second_count > time_item_count) {
            time_item_count = second_count
            time = i
        }
    }

    return time
}

export const isWithinTime = (item_time, time_ms) => {
    const [start, end] = item_time
    return start <= time_ms && end >= time_ms
}

export const getScenesVideoCount = (scenes) => {
    let count = 0
    scenes.forEach((scene) => {
        scene.timeline.forEach((layer) => {
            if (markerUtil.isVideo(layer)) {
                count++
            }
        })
    })
    return count
}

export const copyScene = (scene) => {
    const scene_copy = clonedeep(scene)
    scene_copy.size_name = uuid()

    scene_copy.timeline.forEach((layer) => {
        layer.id = uuid()
        layer.layer_name = layer.layer_name + '_copy'
    })

    return scene_copy
}
