import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { Box } from './stack'
import { Text as BaseText, Link as BaseLink } from '@chakra-ui/core'

export const Text = forwardRef(({ children, ...props }, ref) => (
    <BaseText ref={ref} color="var(--text-main)" {...props}>
        {children}
    </BaseText>
))

export const TextLink = ({ children, secondary, ...props }) => (
    <BaseLink color={secondary ? 'var(--main)' : 'var(--text-main)'} {...props}>
        {children}
    </BaseLink>
)

export const Label = ({ children, ...props }) => (
    <Text mt={5} mb={0.5} fontSize="12px" textTransform="capitalize" color="var(--grey-5)" {...props}>
        {children}
    </Text>
)

export const KeyboardShortcut = ({ children, ...props }) => (
    <Box fontSize="14px" fontFamily="monospace" backgroundColor="#6d6d8a" borderRadius="4px" p="0 4px" {...props}>
        {children}
    </Box>
)

export const ClickText = styled.span`
    &:hover {
        color: var(--main);
        cursor: pointer;
    }
`
