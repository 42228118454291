import styled from '@emotion/styled'

export const PopoverMenuButton = styled.a`
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 5px;
    margin: 4px;
    transition: all 0.12s;
    z-index: 10;
    font-weight: 500;
    color: #000;

    &:hover {
        cursor: pointer;
        color: var(--main);
        background-color: var(--hover-blue);
    }
`
