import { forwardRef } from 'react'
import { Button as BaseButton } from '@chakra-ui/core'

export const Button = forwardRef(({ children, is_active, variant, ...props }, ref) => {
    if (variant === 'outline') {
        return (
            <BaseButton
                variant={variant}
                color={is_active ? 'var(--main)' : 'var(--grey-5)'}
                ref={ref}
                borderColor={is_active ? 'var(--main)' : 'var(--grey-7)'}
                fontWeight={is_active ? '600' : 'normal'}
                borderRadius="4px"
                {...props}
            >
                {children}
            </BaseButton>
        )
    }
    return (
        <BaseButton borderRadius="4px" ref={ref} variant={variant} {...props}>
            {children}
        </BaseButton>
    )
})
