import { useState, useEffect } from 'react'
import AuthUserContext from './auth-context'
import { TOKEN_QUERY_KEY, INFOGRAPH_URL } from '../../constants/auth'

const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
}

const removeQueryParam = (param) => {
    const params = new URLSearchParams(window.location.search)
    params.delete(param)
    window.history.replaceState(null, '', '?' + params + window.location.hash)
}

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState()

    const signOut = () => {
        window.localStorage.removeItem(TOKEN_QUERY_KEY)
        setToken(null)
        window.location.replace(INFOGRAPH_URL)
    }

    useEffect(() => {
        if (window.location.pathname?.includes('/view')) return setToken('view')

        const token_in_query_param = getQueryParam(TOKEN_QUERY_KEY)
        if (token_in_query_param) {
            window.localStorage.setItem(TOKEN_QUERY_KEY, token_in_query_param)
            setToken(token_in_query_param)
            return removeQueryParam(TOKEN_QUERY_KEY)
        }

        const persisted_token = window.localStorage.getItem(TOKEN_QUERY_KEY, token)
        if (persisted_token) return setToken(persisted_token)

        return signOut()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AuthUserContext.Provider
            value={{
                token,
                is_loading_auth: !token,
                is_logged_in: !!token,
                signOut,
            }}
        >
            {children}
        </AuthUserContext.Provider>
    )
}
