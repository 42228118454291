export const ALL_FONTS = [
    {
        name: "'Pacifico'",
        link: 'https://fonts.googleapis.com/css2?family=Pacifico&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'EB Garamond'",
        link: 'https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;800&display=swap',
        weights: ['normal', 'bold', 400, 500, 800],
    },
    {
        name: "'Abril Fatface'",
        link: 'https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Bangers'",
        link: 'https://fonts.googleapis.com/css2?family=Bangers&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Carter One'",
        link: 'https://fonts.googleapis.com/css2?family=Carter+One&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Caveat'",
        link: 'https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap',
        weights: ['normal', 'bold', 400, 500, 600, 700],
    },
    {
        name: "'Knewave'",
        link: 'https://fonts.googleapis.com/css2?family=Knewave&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Prata'",
        link: 'https://fonts.googleapis.com/css2?family=Prata&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Sacramento'",
        link: 'https://fonts.googleapis.com/css2?family=Sacramento&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Sofia'",
        link: 'https://fonts.googleapis.com/css2?family=Sofia&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Inter'",
        link: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800;900&display=swap',
        weights: ['normal', 'bold', 300, 400, 500, 700, 800, 900],
    },
    {
        name: "'Permanent Marker'",
        link: 'https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Lato'",
        link: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,900;1,100;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 700, 900],
    },
    {
        name: "'Roboto'",
        link: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 500, 700, 900],
    },
    {
        name: "'Roboto Condensed'",
        link: 'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 500, 700],
    },
    {
        name: "'Indie Flower'",
        link: 'https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Open Sans'",
        link: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 600, 700, 800],
    },
    {
        name: "'Oswald'",
        link: 'https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;700&display=swap',
        weights: ['normal', 'bold', 300, 400, 500, 700],
    },
    {
        name: "'Slabo 27px'",
        link: 'https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Tillana'",
        link: 'https://fonts.googleapis.com/css2?family=Tillana:wght@400;500;700&display=swap',
        weights: ['normal', 'bold', 400, 500, 700],
    },
    {
        name: "'Montserrat'",
        link: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 500, 700, 900],
    },
    {
        name: "'Source Sans Pro'",
        link: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 600, 700, 900],
    },
    {
        name: "'Raleway'",
        link: 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 500, 700, 900],
    },
    {
        name: "'PT Sans'",
        link: 'https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
        weights: ['normal', 'bold', 400, 700],
    },
    {
        name: "'Merriweather'",
        link: 'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 700, 900],
    },
    {
        name: "'Anton'",
        link: 'https://fonts.googleapis.com/css2?family=Anton&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Lora'",
        link: 'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
        weights: ['normal', 'bold', 400, 500, 700],
    },
    {
        name: "'Ubuntu'",
        link: 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
        weights: ['normal', 'bold', 400, 500, 700],
    },
    {
        name: "'Noto Sans'",
        link: 'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
        weights: ['normal', 'bold', 400, 700],
    },
    {
        name: "'Monoton'",
        link: 'https://fonts.googleapis.com/css2?family=Monoton&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Playfair Display'",
        link: 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap',
        weights: ['normal', 'bold', 400, 500, 700, 900],
    },
    {
        name: "'Poppins'",
        link: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 500, 900],
    },
    {
        name: "'Orbitron'",
        link: 'https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700;900&display=swap',
        weights: ['normal', 'bold', 400, 500, 700, 900],
    },
    {
        name: "'Nunito'",
        link: 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 500, 700, 900],
    },
    {
        name: "'IBM Plex Sans'",
        link: 'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
        weights: ['normal', 'bold', 300, 400, 500, 700],
    },
    {
        name: "'Anonymous Pro'",
        link: 'https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap',
        weights: ['normal', 'bold', 400, 700],
    },
    {
        name: "'Rock Salt'",
        link: 'https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Yanone Kaffeesatz'",
        link: 'https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200;300;400;500;600;700&display=swap',
        weights: ['normal', 'bold', 200, 300, 400, 500, 600, 700],
    },
    {
        name: "'Luckiest Guy'",
        link: 'https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Black Ops One'",
        link: 'https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'VT323'",
        link: 'https://fonts.googleapis.com/css2?family=VT323&display=swap',
        weights: ['normal', 'bold'],
    },
    {
        name: "'Source Serif Pro'",
        link: 'https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;900&display=swap',
        weights: ['normal', 'bold', 300, 400, 600, 900],
    },
]

export const FONT_SIZES = [
    16, 20, 24, 28, 36, 38, 40, 42, 44, 48, 54, 58, 64, 72, 76, 80, 84, 86, 96, 100, 110, 125, 150, 175, 200, 225, 250,
    275, 300, 325, 350, 400, 500, 600,
]
