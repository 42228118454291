import { useState, useCallback, forwardRef, useImperativeHandle, createContext, useRef, useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { post } from 'axios'

import { useLocalStorage } from '../../hooks/use-localstorage'
import { ClipboardEvents } from './clipboard'
import { Modals } from './modals/modals'
import { useDebouncedEffect } from '../../hooks/use-debouncedEffect'
import { getState, getSceneDurationMs } from './stores/project-reducer'
import { KeyboardShortcuts } from './keyboard-shortcuts'
import { ModalStore } from './modal-context'
import { createCanvasRef } from '../Marker/canvas-base'
import { getAllActiveItemIds, getDragItemId, getActiveItemId } from './edit-config'
import { VERCEL_FUNCTION_URL } from '../../constants/api'
import { useUser } from '../Query/use-user'
import { PROJECTS, useProjects } from '../Query/use-projects'
import Timer from '../Timer/timer'

const INITIAL_MENU = 'Library'
const TIMER_OPTIONS = { refreshRateMS: 18 }

const itemExists = (active_item, id) => {
    const active_item_ids = getAllActiveItemIds(active_item)
    if (active_item_ids.includes(id)) return true
    return false
}

export const EditProvider = forwardRef(({ children }, ref) => {
    const [active_menu, setActiveMenu] = useLocalStorage('edit-side-menu-select', INITIAL_MENU)
    const [active_item, setActiveItem] = useState([])
    const [editor_state_saved_cb, setEditorStateSavedCb] = useState({})

    const canvas_ref = useRef(createCanvasRef())
    const moveable = useRef(null)

    const queryClient = useQueryClient()
    const { data: db_user } = useUser()
    const { isLoading } = useProjects(db_user.api_key)

    const state = useSelector(getState)
    let { id } = useParams()

    useImperativeHandle(ref, () => ({
        getState() {
            return state
        },
    }))

    const onSetActiveMenu = useCallback((menu) => setActiveMenu(menu), [setActiveMenu])

    const saveEditorStateInFirestore = () => {
        const updated_project = { ...state, last_edited: Date.now() }
        delete updated_project.asset_uploading

        const config = { headers: { 'x-api-key': db_user.api_key } }
        post(`${VERCEL_FUNCTION_URL}/project/${id}`, { updated_project }, config)
            .then(() => {
                if (!isLoading) {
                    queryClient.setQueryData(PROJECTS, (old) => {
                        if (old) {
                            return old.map((e) => {
                                if (e.id === state.id) return updated_project
                                return e
                            })
                        }
                    })
                }

                if (editor_state_saved_cb && editor_state_saved_cb.cb) editor_state_saved_cb.cb()
            })
            .catch((e) => {
                if (editor_state_saved_cb && editor_state_saved_cb.cb) editor_state_saved_cb.cb()
                return window.toast({
                    description: 'Unable to save your project',
                    status: 'error',
                    title: 'Unable to save project',
                })
            })
    }

    useDebouncedEffect(saveEditorStateInFirestore, 1000, [state, editor_state_saved_cb])

    const active_item_id = getActiveItemId(active_item)

    const setActiveItemId = (id) => {
        if (!id) return setActiveItem([])
        const el = document.getElementById(`drag${id}`)
        if (el) setActiveItem([el])
    }

    const addActiveItem = (id) => {
        if (itemExists(active_item, id)) {
            const new_active_item = active_item.filter((e) => getDragItemId(e) !== id)
            return setActiveItem(new_active_item)
        }

        const el = document.getElementById(`drag${id}`)
        if (el) setActiveItem([...active_item, el])
    }

    const addActiveItems = (ids) => {
        const els = ids.map((id) => document.getElementById(`drag${id}`)).filter(Boolean)
        if (els.length) setActiveItem(els)
    }

    return (
        <EditContext.Provider
            value={{
                moveable,
                active_menu,
                setActiveMenu: onSetActiveMenu,
                active_item,
                active_item_id,
                setActiveItemId,
                setActiveItem,
                addActiveItem,
                addActiveItems,
                setEditorStateSavedCb,
                canvas_ref: canvas_ref.current,
                should_set_drag: true,
            }}
        >
            <TextEditStore>
                <TimerStore>
                    <ModalStore>
                        <ClipboardEvents />
                        <KeyboardShortcuts>{children}</KeyboardShortcuts>
                        <Modals />
                    </ModalStore>
                </TimerStore>
            </TextEditStore>
        </EditContext.Provider>
    )
})

const TextContext = createContext()
const TextEditStore = ({ children }) => {
    const resetText = useRef(() => {})

    const setResetText = (resetFn) => {
        resetText.current = resetFn
    }

    return (
        <TextContext.Provider
            value={{
                resetText: resetText.current,
                setResetText,
            }}
        >
            {children}
        </TextContext.Provider>
    )
}

export const useTextStore = () => {
    const text = useContext(TextContext)

    return text
}

const TimerContext = createContext()
export const EditContext = createContext(null)
export const useEdit = () => {
    const edit = useContext(EditContext)

    return edit
}

const TimerStore = ({ children }) => {
    const video_duration_ms = useSelector(getSceneDurationMs)
    const timer = useRef(new Timer(video_duration_ms, TIMER_OPTIONS))
    const { resetText } = useTextStore()

    const playVideo = () => {
        if (window.window.is_dragging_scene) return
        if (timer.current.ms === 0) timer.current.reset()
        if (resetText) resetText()
        timer.current.startstop()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    return <TimerContext.Provider value={{ timer: timer.current, playVideo }}>{children}</TimerContext.Provider>
}

export const useTimer = () => {
    const timer = useContext(TimerContext)

    return timer
}
