import { Skeleton } from '@chakra-ui/core'
import { EDIT_NAVBAR_HEIGHT, BOTTOM_SECTION_HEIGHT } from './edit-config'
import { Box } from '../UI'

export const EditSkeleton = () => (
    <div style={{ display: 'flex', width: '100vw', height: '100vh', position: 'relative' }}>
        <Skeleton width="350px" mt={EDIT_NAVBAR_HEIGHT} mb={BOTTOM_SECTION_HEIGHT} />
        <Box
            backgroundColor="var(--dark-blue)"
            position="absolute"
            top="0"
            width="100%"
            height={EDIT_NAVBAR_HEIGHT}
            zIndex="2"
        />
        <Box
            backgroundColor="var(--grey-8)"
            position="absolute"
            bottom="0"
            width="100%"
            height={BOTTOM_SECTION_HEIGHT}
            zIndex="2"
        />
        <div
            style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Skeleton width="400px" mb="200px" height="200px" />
        </div>
    </div>
)
