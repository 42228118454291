import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { getCanvasSize } from '../../constants/resolutions'
import { createCanvasBg } from '../../shared-fns/shared-fns'
import { Box } from '../UI'

export const createCanvasRef = () => ({ ...CanvasRef })

const CanvasRef = {
    el: null,
}

export const AspectRatio = ({ children, canvas_scale = 30, ratio, background = {}, AspectRatio, top = '10%' }) => {
    const [real_width, real_height] = getCanvasSize(ratio)
    const aspect_ratio_ref = useRef()
    const scale_percentage = canvas_scale / 100

    useEffect(() => {
        if (aspect_ratio_ref && aspect_ratio_ref.current) {
            aspect_ratio_ref.current.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            })
        }
    }, [])

    const mx = (real_width * scale_percentage - real_width) / 2
    const my = (real_height * scale_percentage - real_height) / 2

    return (
        <>
            <Box
                id="aspect-ratio-box"
                ref={aspect_ratio_ref}
                width={`${real_width}px`}
                height={`${real_height}px`}
                style={background}
                transform={`scale(${canvas_scale / 100})`}
                transition="all 0.2s"
                mx={mx + 'px'}
                my={my + 'px'}
            >
                {children}
            </Box>
        </>
    )
}

export const getBorderRadius = (br) => {
    if (br === 'full') return '50%'
    return br
}

export const getShadowFilter = (shadow) => {
    if (!shadow) return ''
    return `drop-shadow(rgba(0, 0, 0, ${shadow / 100}) ${0.5}em ${0.6}em ${0.7}em)`
}

const Canvas = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;

    &::after {
        border-radius: 12px;
        content: '';
        height: 100%;
        left: 0;
        box-shadow: 0 0 0 10000px rgb(240 241 245 / 100%);
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1000;
    }
`
const CanvasWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;

    &::after {
        border-radius: 12px;
        content: '';
        height: 100%;
        left: 0;
        box-shadow: var(--shadow-elevation-medium);
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1001;
    }
`

export const CanvasWithLoader = ({ children, canvas_ref, onClick, background, video_ratio, show_canvas }) => {
    const background_style = createCanvasBg(background)

    return (
        <CanvasWrapper>
            <Canvas
                id="canvas_ref"
                ref={(node) => {
                    if (canvas_ref.el) return
                    canvas_ref.el = node
                }}
                onClick={onClick}
                style={{ ...background_style, overflow: 'clip', overflowClipMargin: '0px' }}
                position="relative"
            >
                {children}
            </Canvas>
        </CanvasWrapper>
    )
}
