import { memo } from 'react'
import { Box } from '../UI'
import { ViewOnlyTextMarker } from '../Marker/text-marker/text-marker'
import { ImageMarker } from '../Marker/image-marker'
import { BoxMarker } from '../Marker/box-marker'
import { QRCodeMarker } from '../Marker/qr-code-marker'
import { LottieMarker } from '../Marker/lottie-marker'
import { VideoMarker } from '../Marker/video-marker'
import { AudioMarker } from '../Marker/audio-marker'

export const MarkerBox = ({ position, size, layer_position, children, id, box_measure_ref }) => {
    const width = `${size[0]}px`
    const height = `${size[1]}px`
    const left = `${position[0]}px`
    const top = `${position[1]}px`
    const transform_px = `translate(${size[2]}px, ${size[3]}px) rotate(${size[4] ? size[4] : 0}deg)`
    const z_index = layer_position

    return (
        <Box
            ref={box_measure_ref}
            id={id}
            cursor="default"
            position="absolute"
            zIndex={z_index}
            transform={transform_px}
            width={width}
            height={height}
            left={left}
            top={top}
        >
            {children}
        </Box>
    )
}

export const ViewImageMarker = memo(({ item, layer_position, timer, video_duration_ms }) => {
    return (
        <MarkerBox layer_position={layer_position} position={item.position} size={item.size}>
            <ImageMarker item={item} timer={timer} video_duration_ms={video_duration_ms} />
        </MarkerBox>
    )
})

export const ViewTextMarker = memo(({ item, layer_position, timer, video_duration_ms, is_video }) => {
    return (
        <MarkerBox layer_position={layer_position} position={item.position} size={item.size}>
            <ViewOnlyTextMarker item={item} timer={timer} video_duration_ms={video_duration_ms} />
        </MarkerBox>
    )
})

export const ViewBoxMarker = memo(({ item, layer_position, timer, video_duration_ms }) => {
    return (
        <MarkerBox layer_position={layer_position} position={item.position} size={item.size}>
            <BoxMarker item={item} timer={timer} video_duration_ms={video_duration_ms} />
        </MarkerBox>
    )
})

export const ViewQRCodeMarker = memo(({ item, layer_position, timer, video_duration_ms }) => {
    return (
        <MarkerBox layer_position={layer_position} position={item.position} size={item.size}>
            <QRCodeMarker item={item} timer={timer} video_duration_ms={video_duration_ms} />
        </MarkerBox>
    )
})

export const ViewLottieMarker = memo(({ item, layer_position, timer, video_duration_ms }) => {
    return (
        <MarkerBox layer_position={layer_position} position={item.position} size={item.size}>
            <LottieMarker item={item} timer={timer} video_duration_ms={video_duration_ms} />
        </MarkerBox>
    )
})

export const ViewVideoMarker = memo(({ item, layer_position, timer, video_duration_ms }) => {
    return (
        <MarkerBox layer_position={layer_position} position={item.position} size={item.size}>
            <VideoMarker item={item} timer={timer} video_duration_ms={video_duration_ms} />
        </MarkerBox>
    )
})

export const ViewAudioMarker = memo(({ item, layer_position, timer, video_duration_ms }) => {
    return <AudioMarker item={item} timer={timer} video_duration_ms={video_duration_ms} />
})
