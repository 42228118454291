import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { FullWidthSpinner } from '../UI/spinner'
import { ErrorFallback } from './error-fallback'
import { ProtectedRoute } from '../Navigation/protected-route'
import * as ROUTES from '../../constants/routes'
import { AuthProvider } from '../Session'
import { Layout } from './layout'
import { TrackPageView } from '../Track/track'
import { isMobile } from '../../shared-fns/shared-fns'
import { MobileInProgressBanner } from './mobile-in-progress'
import View from '../View/view'
import { EditSkeleton } from '../Edit/edit-skeleton'

const Dashboard = lazy(() =>
    import(
        /* webpackChunkName: "Dashboard" */
        /* webpackPrefetch: true */
        '../Dashboard/dashboard'
    )
)

const Edit = lazy(() =>
    import(
        /* webpackChunkName: "Edit" */
        /* webpackPrefetch: true */
        '../Edit/edit'
    )
)
const AutomatedContent = lazy(() =>
    import(
        /* webpackChunkName: "Preview" */
        /* webpackPrefetch: true */
        '../Preview/preview.js'
    )
)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
    },
})

const App = ({ hideLoader }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(hideLoader, [])
    useEffect(() => {
        if (!window.vimeo_video_cache_map) window.vimeo_video_cache_map = {}
    }, [])

    if (isMobile()) return <MobileInProgressBanner />

    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <Router>
                    <TrackPageView />
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <Layout>
                            <SuspenseLoader>
                                <Switch>
                                    <ProtectedRoute exact path={`${ROUTES.EDIT}/:id`} component={Edit} />
                                    <ProtectedRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
                                    <ProtectedRoute exact path={`${ROUTES.PREVIEW}/:id`} component={AutomatedContent} />
                                    {/* <ProtectedRoute exact path={`${ROUTES.FORM}/:id`} component={Form} /> */}
                                    <Route path={ROUTES.VIEW} exact component={View} />
                                    <Route path={ROUTES.HOME} component={() => <Redirect to={ROUTES.DASHBOARD} />} />
                                </Switch>
                            </SuspenseLoader>
                        </Layout>
                    </ErrorBoundary>
                </Router>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

const SuspenseLoader = ({ children }) => {
    const location = useLocation()
    return (
        <Suspense fallback={location.pathname.includes('/edit') ? <EditSkeleton /> : <FullWidthSpinner />}>
            {children}
        </Suspense>
    )
}

export default App
