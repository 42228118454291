import { lazy, Suspense, useEffect } from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/core'
import { Box } from '../stack'
import { BaseModal, selected_style, IconTab } from './modal-select-components'
import { Spinner } from '../spinner'
import { useLocalStorage } from '../../../hooks/use-localstorage'

import { ReactComponent as GiphyLogo } from '../../../assets/icons/giphy-logo-1.svg'
import { ReactComponent as PexelLogo } from '../../../assets/icons/pexel-logo.svg'

const LottieLogo = () => (
    <Box height="18px" width="18px">
        <img
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            src="https://airbnb.io/lottie/images/logo.webp"
            alt="lottie logo"
        />
    </Box>
)

const LottieSelectLazy = lazy(() => import('./lottie-select.js'))
const GiphyGridLazy = lazy(() => import('./gif-select.js'))
const PexelsVideoGridLazy = lazy(() => import('./pexels-video-select.js'))

export const VideoSelectModal = ({ closeModal, isOpen, config }) => (
    <BaseModal closeModal={closeModal} isOpen={isOpen}>
        {isOpen && <VideoSelect config={config} closeModal={closeModal} />}
    </BaseModal>
)

// GIFS + lottie
const VideoSelect = ({ config, closeModal }) => {
    const [tab_idx, setTabIdx] = useLocalStorage('video-select-modal-idx', 0)
    const { selection, onClickLottie, onClickGif, onNewVideo } = config

    useEffect(() => {
        if (tab_idx + 1 > selection.length) {
            setTabIdx(0)
        }
    }, [selection, tab_idx, setTabIdx])

    return (
        <Tabs index={tab_idx} variant="unstyled" display="flex" overFlow="scroll" onChange={(idx) => setTabIdx(idx)}>
            <TabList mt="6px" minHeight="600px" bg="var(--grey-4)" flexDirection="column" width="20%" borderRadius="md">
                {selection.map((item, idx) => {
                    return (
                        <Tab
                            borderLeft="4px solid transparent"
                            justifyContent="flex-start"
                            height="43px"
                            width="100%"
                            _selected={selected_style}
                            _hover={{ bg: 'var(--main-hover)' }}
                            key={idx}
                        >
                            {item === 'lottie' && <IconTab name="Lottie" logo={<LottieLogo />} />}
                            {item === 'giphy' && <IconTab name="Giphy" logo={<GiphyLogo />} />}
                            {item === 'pexels' && <IconTab name="Pexels" logo={<PexelLogo />} />}
                        </Tab>
                    )
                })}
            </TabList>
            <TabPanels width="80%">
                {selection.map((item, idx) => (
                    <TabPanel key={idx}>
                        <Suspense fallback={<Spinner mt="250px" />}>
                            <Box p="0.5rem">
                                {item === 'lottie' && tab_idx === idx && (
                                    <LottieSelectLazy onClickLottie={onClickLottie} closeModal={closeModal} />
                                )}
                                {item === 'giphy' && tab_idx === idx && (
                                    <GiphyGridLazy idx={idx} active_idx={tab_idx} onNewGif={onClickGif} />
                                )}
                                {item === 'pexels' && tab_idx === idx && (
                                    <PexelsVideoGridLazy
                                        idx={idx}
                                        active_idx={tab_idx}
                                        closeModal={closeModal}
                                        onClickVideo={onNewVideo}
                                    />
                                )}
                            </Box>
                        </Suspense>
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    )
}
