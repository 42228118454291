import { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { get } from 'axios'
import { SearchForm, ImageGridLayout, NoResultsFound, Loader, Attribution } from './modal-select-components'
import { Flex, Box } from '../stack'
import { Spinner } from '../spinner'
import { ImageLoader } from '../image-loader'
import { VERCEL_FUNCTION_URL } from '../../../constants/api'
import { useToast } from '../toast'
import { useStateWithCallback } from '../../../hooks/use-stateWithCallback'
import { tracker, FROM } from '../../Track/track'

const COUNT = 15
let in_memory_cache = []
let in_memory_query = ''
let in_memory_page = 1
let in_memory_scroll_position = 0

export const UnsplashImageGrid = ({ onClickUnsplashImage, idx, active_idx }) => {
    const [images, setImages] = useStateWithCallback(in_memory_cache, (cb_images) => {
        in_memory_cache = cb_images
        in_memory_query = query
    })
    const [query, setQuery] = useState(in_memory_query)
    const [page, setPage] = useStateWithCallback(in_memory_page, (page) => {
        if (page === 1) in_memory_scroll_position = 0
        in_memory_page = page
    })
    const [status, setStatus] = useState('')

    const toast = useToast()

    useEffect(() => {
        if (!in_memory_cache.length) fetchImages(COUNT, in_memory_query || 'nature')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onRes = (new_images) => {
        const all_images = [...images, ...new_images]
        setImages(all_images)
        if (all_images.length === 0) setStatus('no_results_found')
        else if (new_images.length === 0 || new_images.length < COUNT) setStatus('no_more')
        else setStatus('')
    }

    const onError = (e) => {
        setStatus('')
        toast({
            description: `Please try again or contact support`,
            status: 'error',
        })
    }

    const fetchImages = (count, init_term) => {
        setStatus('loading')

        const s_query = init_term || query
        if (s_query) {
            get(`${VERCEL_FUNCTION_URL}/unsplash/unsplash-search?&query=${s_query}&per_page=${count}&page=${page}`)
                .then((res) => {
                    onRes(res.data)
                    setPage(page + 1)
                })
                .catch(onError)
        } else {
            get(`${VERCEL_FUNCTION_URL}/unsplash/unsplash-random?&count=${count}`)
                .then((res) => onRes(res?.data))
                .catch(onError)
        }
    }

    const triggerDownload = (download_location) => {
        get(`${VERCEL_FUNCTION_URL}/unsplash/unsplash-trigger-download?download_location=${download_location}`)
            .then(() => {})
            .catch(onError)
    }

    const onSearch = (e) => {
        e.preventDefault()
        if (!query) return

        setPage(1)
        setImages([])
        setStatus('new_search')
    }

    useEffect(() => {
        // else old images won't be cleared from the state
        if (status === 'new_search' && query) fetchImages(COUNT)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    return (
        <Box>
            <SearchForm
                active_idx={active_idx}
                idx={idx}
                placeholder="Search Unsplash"
                query={query}
                onSearch={onSearch}
                setQuery={setQuery}
            />
            {!!!images.length && status === 'loading' && <Loader />}
            {!!images.length && (
                <InfiniteScroll
                    dataLength={images?.length}
                    next={() => {
                        if (status === 'no_more' || status === 'loading') return
                        fetchImages(COUNT)
                    }}
                    hasMore={status !== 'no_more' || images?.length < COUNT}
                    loader={status !== 'loading' ? null : <Spinner minHeight="200px" />}
                    height={500}
                    initialScrollY={in_memory_scroll_position}
                    onScroll={(e) => {
                        in_memory_scroll_position = e.target.scrollTop
                    }}
                >
                    <ImageGridLayout>
                        {images.map((img, idx) => (
                            <UnsplashImage
                                triggerDownload={triggerDownload}
                                onClickUnsplashImage={onClickUnsplashImage}
                                img={img}
                                key={idx}
                            />
                        ))}
                    </ImageGridLayout>
                </InfiniteScroll>
            )}
            {status === 'no_results_found' && <NoResultsFound />}
        </Box>
    )
}
export default UnsplashImageGrid

const UnsplashImage = ({ img, onClickUnsplashImage, triggerDownload }) => {
    const { small, regular, thumb } = img.urls
    const { alt_description } = img
    const { name } = img.user
    const { html } = img.user.links
    const { download_location } = img.links

    return (
        <div className="image-item">
            <Flex height="100%" flexDirection="column" style={{ contentVisibiliy: 'auto' }}>
                <ImageLoader
                    style={{ display: 'flex', width: '100%', height: '100%', objectFit: 'cover' }}
                    height="90%"
                    width="100%"
                    url={small}
                    alt={alt_description}
                    onClick={(e) => {
                        if (download_location) triggerDownload(download_location)
                        tracker.addImage({ from: FROM.UNSPLASH })
                        onClickUnsplashImage({ e, url: regular, alt_description, thumbnail_url: thumb })
                    }}
                />
                <Attribution name={name} link={html} />
            </Flex>
        </div>
    )
}
