import { Tooltip as BaseTooltip } from '@chakra-ui/core'
import { Flex } from './stack'
import { Text, KeyboardShortcut } from './text'
import { isMobile } from '../../shared-fns/shared-fns'

export const Tooltip = ({ children, keycode, text, is_open, ...props }) => {
    if (isMobile()) return <>{children}</>

    return (
        <BaseTooltip
            label={
                text && (
                    <Flex alignItems="center" justifyContent="center">
                        {text && (
                            <Text color="var(--grey-2)" pr={keycode && '6px'}>
                                {text}
                            </Text>
                        )}
                        {keycode &&
                            keycode.map((k, i) => (
                                <KeyboardShortcut key={k || i} mr="6px">
                                    {k}
                                </KeyboardShortcut>
                            ))}
                    </Flex>
                )
            }
            p="2"
            borderRadius="4px"
            backgroundColor="#2b2b35"
            placement="right"
            padding="6px 15px"
            fontSize="14px"
            hasArrow
            isOpen={is_open}
            {...props}
        >
            {children}
        </BaseTooltip>
    )
}
