import { createContext, useState, useContext } from 'react'
import { useDisclosure } from '@chakra-ui/core'

export const ModalStore = ({ children }) => {
    const [replace_item, setReplaceItem] = useState()

    const { isOpen: is_link_upload_open, onOpen: openLinkUpload, onClose: closeLinkUploadModal } = useDisclosure()
    const {
        isOpen: is_image_select_open,
        onOpen: openImageSelectModal,
        onClose: closeImageSelectModal,
    } = useDisclosure()
    const {
        isOpen: is_video_select_open,
        onOpen: openVideoSelectModal,
        onClose: closeVideoSelectModal,
    } = useDisclosure()

    const {
        isOpen: is_audio_select_open,
        onOpen: openAudioSelectModal,
        onClose: closeAudioSelectModal,
    } = useDisclosure()

    const replaceImage = (active_item) => {
        setReplaceItem(active_item)
        openImageSelectModal()
    }

    const replaceVideo = (active_item) => {
        setReplaceItem(active_item)
        openVideoSelectModal()
    }

    return (
        <ModalContext.Provider
            value={{
                is_image_select_open,
                openImageSelectModal,
                replace_item,
                replaceImage,
                replaceVideo,
                is_video_select_open,
                openVideoSelectModal,
                is_audio_select_open,
                openAudioSelectModal,
                closeAudioSelectModal,
                closeVideoSelectModal: () => {
                    closeVideoSelectModal()
                    setReplaceItem(null)
                },
                closeImageSelectModal: () => {
                    closeImageSelectModal()
                    setReplaceItem(null)
                },
                is_link_upload_open,
                openLinkUpload: () => {
                    if (is_image_select_open) closeImageSelectModal()
                    openLinkUpload()
                },
                closeLinkUploadModal: () => {
                    setReplaceItem(null)
                    closeLinkUploadModal()
                },
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}

export const ModalContext = createContext(null)

export const useModalStore = () => {
    const modalstore = useContext(ModalContext)

    return modalstore
}
