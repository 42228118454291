import { useQuery } from 'react-query'
import { get } from 'axios'
import { useAuth } from '../Session'
import { VERCEL_FUNCTION_URL } from '../../constants/api'
import { intercom } from '../Track/intercom'
import { chameleon } from '../Track/chameleon'

export const USER = 'user'

export function useUser() {
    const { token, signOut } = useAuth()

    const getUser = async () => {
        if (!token) return

        const config = { headers: { Authorization: token } }
        const res = await get(`${VERCEL_FUNCTION_URL}/user`, config)

        const { email = '', id: user_id = '', use_intercom = false } = res.data
        if (use_intercom) {
            intercom.boot(email, user_id)
            chameleon.boot(email, user_id)
        }

        return res.data
    }

    return useQuery(USER, getUser, {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnMount: false,
        suspense: true,
        onError: () => signOut(),
    })
}
