// Assuming user wants the slider to go all the way to the end
// if the drag distance is this close
// (to prevent blinking at end of video)
const getEndOffset = (duration) => {
    if (duration > 25) return 0.4
    if (duration > 20) return 0.3
    if (duration > 10) return 0.2
    if (duration > 3) return 0.06
    else return 0.04
}

export function getStartEnd({ x, slider_width, timeline_width, video_duration }) {
    const start = getTimeFromX({ x, timeline_width, video_duration })

    const end_ratio = (x + slider_width) / timeline_width
    let end = Number(video_duration * end_ratio)
    if (video_duration - end < getEndOffset(video_duration)) end = video_duration
    return [start, end]
}

export const getTimeFromX = ({ x, timeline_width, video_duration }) => {
    const time_ratio = x / timeline_width
    let time = Number(video_duration * time_ratio)

    if (time < 0) return 0
    if (time < getEndOffset(video_duration)) return 0
    return time
}

export function secondsToPxLength(seconds, video_duration, timeline_width) {
    const one_s_in_px = +timeline_width / +video_duration

    return Number((seconds * one_s_in_px).toFixed(6))
}

const getWidth = (second_duration, video_duration, timeline_width) => {
    return secondsToPxLength(second_duration, video_duration, timeline_width)
}

//  ------- this distance
// <-------Time--->
export const getXFromTime = (time, video_duration, timeline_width) => {
    return secondsToPxLength(time, video_duration, timeline_width)
}

export const getSliderPosition =
    ({ timeline_width, video_duration }) =>
    ({ start, end }) => {
        const second_duration = end - start
        const width = getWidth(second_duration, video_duration, timeline_width)
        const x = getXFromTime(start, video_duration, timeline_width)

        return [x, width]
    }

export const displaySeconds = (video_duration_ms, current_time) => {
    return getCurrentSeconds(video_duration_ms, current_time).toFixed(1)
}

export const getCurrentSeconds = (video_duration_ms, current_time) => {
    const seconds_display = (video_duration_ms - current_time) / 1000
    return Math.max(seconds_display, 0)
}

export const formatDuration = (video_duration) => {
    if (video_duration < 10) return `0:0${video_duration}`
    return `0:${video_duration}`
}
