import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
export const FadeIn = ({ duration = 300, delay = 0, children, ...delegated }) => (
    <Wrapper
        {...delegated}
        style={{
            ...(delegated.style || {}),
            animationDuration: duration + 'ms',
            animationDelay: delay + 'ms',
        }}
    >
        {children}
    </Wrapper>
)

const Wrapper = styled.div`
    @media (prefers-reduced-motion: no-preference) {
        animation-name: ${fadeIn};
        animation-fill-mode: backwards;
    }
`
