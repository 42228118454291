import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Renderer } from '../Renderer/renderer'
import { LoadFonts } from '../../hooks/use-loadFonts'
import { makeOneSceneProject } from '../Preview/one-scene-project'

const getTimeForFrame = (duration_s, frame) => {
    const total_frames = duration_s * 30
    const frame_times = []
    const frame_interval = 1000 / 30

    for (let i = 0; i < total_frames; i++) {
        frame_times.push(Math.floor(i * frame_interval))
    }
    window.frame_times = frame_times
    return frame_times[frame - 1]
}

const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
}

const View = () => {
    const { state } = useLocation()
    const [project, setProject] = useState()
    const [is_loading, setIsLoading] = useState(true)
    const [active_frame, setActiveFrame] = useState(+getQueryParam('frame') || 1)

    useEffect(() => {
        const prepareProject = async (new_project) => {
            setProject(new_project)
            setIsLoading(false)
        }

        window.prepareProject = prepareProject
        window.setActiveFrame = setActiveFrame
        window.active_frame = active_frame

        const project_from_router = state?.project
        if (project_from_router) prepareProject(makeOneSceneProject(project_from_router))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!project || is_loading) return null

    const { duration } = project.scenes[0]
    const time = getTimeForFrame(duration, active_frame)

    return (
        <>
            <Renderer time={time} project={project} active_scene={0} has_audio={false} />
            <LoadFonts project={project} />
        </>
    )
}
export default View
