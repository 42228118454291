export const FADE_OUT_OFFSET = 0.01
export const IN_RESET_OFFSET = 0.01
export const IN_VISIBLE_OFFSET = 0.05

export const ANIMATION_RESET = {
    autoAlpha: 0,
    immediateRender: false,
    x: 0,
    y: 0,
    scale: 1,
    transformOrigin: 'center',
    marginLeft: 0,
}
