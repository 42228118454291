import { lazy, Suspense } from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/core'

import { BaseModal, selected_style, IconTab } from './modal-select-components'
import { Box } from '../stack'
import { Spinner } from '../spinner'
import { useLocalStorage } from '../../../hooks/use-localstorage'
import { ReactComponent as UploadIcon } from '../../../assets/icons/music.svg'

const AudioSelectLazy = lazy(() => import('./audio-select.js'))

export const AudioSelectModal = ({ closeModal, isOpen, config }) => (
    <BaseModal closeModal={closeModal} isOpen={isOpen}>
        {isOpen && <AudioSelect config={config} closeModal={closeModal} />}
    </BaseModal>
)

const AudioSelect = ({ config, closeModal }) => {
    const [tab_idx, setTabIdx] = useLocalStorage('audio-select-modal-idx', 0)
    const { selection, onNewAudio } = config

    return (
        <Tabs index={tab_idx} variant="unstyled" display="flex" overFlow="scroll" onChange={(idx) => setTabIdx(idx)}>
            <TabList mt="6px" minHeight="600px" bg="var(--grey-4)" flexDirection="column" width="20%" borderRadius="md">
                {selection.map((item, idx) => {
                    return (
                        <Tab
                            borderLeft="4px solid transparent"
                            justifyContent="flex-start"
                            height="43px"
                            width="100%"
                            _selected={selected_style}
                            _hover={{ bg: 'var(--main-hover)' }}
                            key={idx}
                        >
                            {item === 'audio' && <IconTab name="Audio" logo={<UploadIcon />} />}
                        </Tab>
                    )
                })}
            </TabList>
            <TabPanels width="80%">
                {selection.map((item, idx) => (
                    <TabPanel key={idx}>
                        <Suspense fallback={<Spinner mt="250px" />}>
                            <Box p="0.5rem">
                                {item === 'audio' && tab_idx === idx && (
                                    <AudioSelectLazy onNewAudio={onNewAudio} closeModal={closeModal} />
                                )}
                            </Box>
                        </Suspense>
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    )
}
