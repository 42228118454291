import { Suspense } from 'react'
import styled from '@emotion/styled'
import { useLocation, useHistory } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'
import { Box, Flex } from '../UI'
import { useAuth } from '../Session'
import { useUser } from '../Query/use-user'
import { INFOGRAPH_URL } from '../../constants/auth'

import { HelpLink } from './help-link'
import { UserAvatar } from './user-avatar'
import { UpgradeButton } from './upgrade-button'
import { BetaBadge } from './beta-badge'
import { FROM } from '../Track/track'

import { ReactComponent as NavbarArrow } from '../../assets/icons/arrow-navbar.svg'
import { ReactComponent as LogoIcon } from '../../assets/icons/venngage_logo.svg'

const Navigation = () => {
    const location = useLocation()
    const { is_logged_in } = useAuth()

    if (location.pathname.includes(ROUTES.VIEW)) return null
    if (location.pathname.includes(ROUTES.EDIT)) return null
    if (!is_logged_in) return null

    return <NavbarBaseDesktop />
}

const NavWrapper = styled.nav`
    background-color: var(--dark-blue);
    color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    box-shadow: 0 0 6px rgb(0 0 0 / 18%);
`

const LinkBack = styled.a`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.12s;

    margin-left: 37px;

    svg {
        margin-right: 8px;
        path {
            stroke: #fff;
        }
    }

    &:hover {
        cursor: pointer;
        color: var(--hover-grey);
        svg {
            path {
                stroke: var(--hover-grey);
            }
        }
    }
`

const logo_size = { width: '34px', height: '34px', pointerEvents: 'none' }

const NavbarBaseDesktop = () => {
    const location = useLocation()
    const history = useHistory()
    const is_preview = location.pathname.includes(ROUTES.PREVIEW)

    return (
        <NavWrapper>
            <Box mr="auto">
                <Flex align="center">
                    <LogoIcon style={logo_size} />
                    <BetaBadge />
                    {is_preview ? (
                        <LinkBack
                            as="div"
                            onClick={(e) => {
                                e.stopPropagation()
                                history.push(ROUTES.DASHBOARD)
                            }}
                        >
                            <NavbarArrow style={{ pointerEvents: 'none' }} />
                            Back to Dashboard
                        </LinkBack>
                    ) : (
                        <LinkBack href={`${INFOGRAPH_URL}/templates`}>
                            <NavbarArrow style={{ pointerEvents: 'none' }} />
                            Back to Venngage
                        </LinkBack>
                    )}
                </Flex>
            </Box>
            <Suspense fallback={<div />}>
                <UserMenu />
            </Suspense>
        </NavWrapper>
    )
}

const UserMenu = () => {
    const { data: db_user, isLoading } = useUser()
    if (isLoading || !db_user) return null

    return (
        <>
            <Box mr="20px">
                <UpgradeButton from={FROM.DASHBOARD} />
            </Box>
            <Box mr="15px" width="24px">
                <HelpLink />
            </Box>
            <Box width="30px">
                <UserAvatar />
            </Box>
        </>
    )
}

export default Navigation
