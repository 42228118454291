const is_production = process.env.NODE_ENV === 'production'

class Intercom {
    _app_id = window.INTERCOM_APP_ID
    _intercom = (...args) => {
        if (is_production) {
            try {
                window.Intercom(...args)
            } catch (e) {
                console.warn(e)
            }
        }
    }

    update = () => this._intercom('update')
    boot = (email, user_id) =>
        this._intercom('boot', {
            app_id: this._app_id,
            email,
            user_id,
            using_video: true,
        })
    show = () => this._intercom('show')
}

export const intercom = new Intercom()
