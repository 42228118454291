import styled from '@emotion/styled'

const StyledBetaBadge = styled.div`
    background-color: var(--brand-green);
    color: white;
    border-radius: 3px;
    font-size: 11px;
    padding: 0 5px;
    font-weight: 500;
    margin-left: 12px;
    user-select: none;
`

export const BetaBadge = () => <StyledBetaBadge>BETA</StyledBetaBadge>
