import { MAX_SCENE_DURATION } from '../../../constants/api'

export const SCENE_WIDTH = 146
export const SCENE_GAP = 10

export const MAX_SCENE_DURATION_TOAST = {
    title: 'Duration Limit',
    description: `A project cannot be more than ${MAX_SCENE_DURATION} seconds`,
    status: 'error',
}

export const getNewSceneDuration = (project_total_duration, default_duration = 5) => {
    if (project_total_duration + default_duration <= MAX_SCENE_DURATION) return default_duration

    return MAX_SCENE_DURATION - project_total_duration
}

export const getNewBlankScene = (project_total_duration, aspect_ratio, background) => {
    const duration = getNewSceneDuration(project_total_duration)
    const new_scene = {
        background,
        aspect_ratio,
        duration,
        size_name: Date.now(),
        timeline: [],
    }
    return new_scene
}

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}
