import { useState } from 'react'
import styled from '@emotion/styled'
import { Skeleton } from '@chakra-ui/core'

const Image = styled.img`
    border-radius: 6px;
    border: ${(props) => (props.is_active ? '2px solid #3182ce' : '')};

    &:hover {
        cursor: ${(props) => (props.has_hover ? 'pointer' : '')};
        opacity: ${(props) => (props.has_hover ? '0.8' : '')};
    }
`

export const ImageLoader = ({
    url,
    height,
    width,
    is_active,
    onClick,
    style,
    maxHeight,
    alt = 'uploaded asset',
    skeleton_style,
    ...props
}) => {
    const [is_loading, setIsLoading] = useState(true)
    const has_hover = !!onClick

    return (
        <Skeleton
            overflow="hidden"
            maxHeight={maxHeight}
            height={height}
            width={width}
            isLoaded={!is_loading}
            onClick={onClick}
            style={skeleton_style}
            {...props}
        >
            <Image
                decoding="async"
                has_hover={has_hover}
                is_active={is_active}
                onLoad={() => setIsLoading(false)}
                width={width}
                height={height}
                src={url}
                alt={alt}
                style={{ display: is_loading ? 'none' : '', ...style }}
                lazy="true"
            />
        </Skeleton>
    )
}
