import { useCallback, useRef, useEffect, useState } from 'react'
import { LottiePlayer, Box } from '../UI'
import { MarkerBase, getMarkerTimeMs } from './marker-base'
import { getShadowFilter } from '../Marker/canvas-base'
import { videoCurrentTime, useVideoTimeline } from '../Marker/use-video-timeline'

export const LottieMarker = ({ item, timer, video_duration_ms, is_display_none }) => {
    const lottie_ref = useRef()

    const { url, animation, opacity, id, shadow, is_loop } = item
    const [start_ms, end_ms] = getMarkerTimeMs(item.time)

    const pauseVideo = () => {
        lottie_ref.current?.pause()
    }

    const playVideo = () => {
        lottie_ref.current.play()
    }

    const setVideoTime = useCallback(
        async (t) => {
            if (lottie_ref && lottie_ref.current) {
                const { _lottie } = lottie_ref.current
                if (_lottie) {
                    const { totalFrames, frameRate } = _lottie
                    const lottie_duration_in_s = totalFrames / frameRate
                    const start = videoCurrentTime(t, item.time[0], lottie_duration_in_s, is_loop)

                    const new_start_in_frames = start * frameRate
                    if (new_start_in_frames >= totalFrames) return _lottie.goToAndStop(totalFrames, true)
                    _lottie.goToAndStop(new_start_in_frames, true)
                }
            }
        },
        [item.time, is_loop]
    )

    const seekLottie = () => {
        const ms = timer.getMs()
        const t = video_duration_ms - ms
        setVideoTime(t)
    }

    useVideoTimeline({ timer, video_duration_ms, item, setVideoTime, playVideo, pauseVideo })

    return (
        <MarkerBase
            item={item}
            is_display_none={is_display_none}
            video_duration_ms={video_duration_ms}
            timer={timer}
            animation={animation}
            markerAnimationIn={(t) => {
                setVideoTime(t)
                if (timer.isPlaying()) playVideo()
            }}
            markerAnimationOut={(t) => {
                pauseVideo()
            }}
            start_ms={start_ms}
            end_ms={end_ms}
        >
            <Lottie
                timer={timer}
                shadow={shadow}
                opacity={opacity}
                id={id}
                url={url}
                lottie_ref={lottie_ref}
                is_loop={is_loop}
                seekLottie={seekLottie}
                playVideo={playVideo}
            />
        </MarkerBase>
    )
}

const Lottie = ({ url, lottie_ref, id, opacity, shadow, is_loop, seekLottie, playVideo, timer }) => {
    const [lottie_id, setId] = useState('')

    useEffect(() => {
        const { current } = lottie_ref
        const finishedLoading = () => {
            seekLottie()
            setId('id' + id)
            if (timer.isPlaying()) playVideo()
        }
        current.addEventListener('ready', finishedLoading)

        return () => current.removeEventListener('ready', finishedLoading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    return (
        <Box
            id={lottie_id}
            width="100%"
            height="100%"
            style={{
                filter: getShadowFilter(shadow),
                opacity,
            }}
        >
            <LottiePlayer key={url} loop={is_loop ? is_loop : null} ref={lottie_ref} src={url} />
        </Box>
    )
}

export const LottieLayout = ({ url, id, opacity, shadow }) => {
    const lottie_ref = useRef()

    useEffect(() => {
        const { current } = lottie_ref
        const seekToMiddle = () => {
            if (lottie_ref && lottie_ref.current) {
                const { _lottie } = lottie_ref.current
                if (_lottie) {
                    const { totalFrames } = _lottie
                    _lottie.goToAndStop(totalFrames / 2, true)
                }
            }
        }
        current.addEventListener('ready', seekToMiddle)

        return () => current.removeEventListener('ready', seekToMiddle)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box
            width="100%"
            height="100%"
            style={{
                filter: getShadowFilter(shadow),
                opacity,
            }}
        >
            <LottiePlayer key={url} renderer="canvas" ref={lottie_ref} loop={null} src={url} />
        </Box>
    )
}
