import { Box } from '../UI'
import { getBorderRadius, getShadowFilter } from '../Marker/canvas-base'
import { MarkerBase, getMarkerTimeMs } from './marker-base'

import { ReactComponent as Check } from '../../assets/icons/check.svg'
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg'

export const BoxMarker = ({ item, timer, is_display_none, video_duration_ms }) => {
    const { background, border_radius, opacity, border, border_color, shadow, svg, time, animation } = item
    const [start_ms, end_ms] = getMarkerTimeMs(time)

    return (
        <MarkerBase
            item={item}
            video_duration_ms={video_duration_ms}
            timer={timer}
            animation={animation}
            start_ms={start_ms}
            end_ms={end_ms}
        >
            <BoxLayout
                svg={svg}
                shadow={shadow}
                opacity={opacity}
                border_radius={border_radius}
                background={background}
                border={border}
                border_color={border_color}
            />
        </MarkerBase>
    )
}

export const BoxLayout = ({ svg, background, border_radius, opacity, border, border_color, shadow }) => {
    return (
        <Box width="100%" height="100%">
            {!svg && (
                <Box
                    width="auto"
                    borderRadius={getBorderRadius(border_radius)}
                    height="100%"
                    transition="border 0.1s"
                    style={{
                        background,
                        opacity,
                        border: `${border}px solid ${border_color}`,
                        filter: getShadowFilter(shadow),
                    }}
                />
            )}
            {svg && <SVGBox svg={svg} background={background} opacity={opacity} shadow={shadow} />}
        </Box>
    )
}

const SVGBox = ({ svg, background, opacity, shadow }) => {
    const is_arrow =
        svg === 'https://res.cloudinary.com/careersifus-com/image/upload/v1619711052/stickers/arrow_nipoch.svg'
    const is_check =
        svg === 'https://res.cloudinary.com/careersifus-com/image/upload/v1618747575/stickers/check_zxoixr.svg'

    return (
        <>
            {svg && is_check && (
                <Check
                    style={{
                        height: '100%',
                        width: '100%',
                        stroke: background,
                        opacity,
                        filter: getShadowFilter(shadow),
                        transform: 'translate3d(0, 0, 1px)',
                    }}
                />
            )}
            {svg && is_arrow && (
                <Arrow
                    style={{
                        height: '100%',
                        width: '100%',
                        stroke: background,
                        opacity,
                        filter: getShadowFilter(shadow),
                        transform: 'translate3d(0, 0, 1px)',
                    }}
                />
            )}
        </>
    )
}
