import { lazy, Suspense, useEffect } from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/core'
import { useLocalStorage } from '../../../hooks/use-localstorage'
import { Box } from '../stack'
import { BaseModal, selected_style, IconTab } from './modal-select-components'
import { Spinner } from '../spinner'

import { ReactComponent as UnsplashLogo } from '../../../assets/icons/unsplash_logo.svg'
import { ReactComponent as PexelLogo } from '../../../assets/icons/pexel-logo.svg'
import { ReactComponent as GiphyLogo } from '../../../assets/icons/giphy-logo-1.svg'

const UnsplashImageGridLazy = lazy(() => import('./unsplash-select.js'))
const PexelsImageGridLazy = lazy(() => import('./pexels-select.js'))
const ThreeDSelectLazy = lazy(() => import('./three-d-select.js'))
const StickerLazy = lazy(() => import('./sticker-select.js'))

export const ImageSelectModal = ({ closeModal, isOpen, config }) => (
    <BaseModal closeModal={closeModal} isOpen={isOpen}>
        {isOpen && <ImageSelect config={config} closeModal={closeModal} />}
    </BaseModal>
)

const ImageSelect = ({ config, closeModal }) => {
    const [tab_idx, setTabIdx] = useLocalStorage('ImageSelectModalIdx', 0)
    const { selection } = config
    const { onClickUnsplashImage, onClick3D, onClickIcon } = config

    useEffect(() => {
        if (tab_idx + 1 > selection.length) {
            setTabIdx(0)
        }
    }, [selection, tab_idx, setTabIdx])

    return (
        <Tabs index={tab_idx} variant="unstyled" display="flex" overFlow="scroll" onChange={(idx) => setTabIdx(idx)}>
            <TabList pt="6px" minHeight="600px" bg="var(--grey-4)" flexDirection="column" width="20%" borderRadius="md">
                {selection.map((item, idx) => (
                    <Tab
                        borderLeft="4px solid transparent"
                        justifyContent="flex-start"
                        height="43px"
                        width="100%"
                        _selected={selected_style}
                        _hover={{ bg: 'var(--main-hover)' }}
                        key={idx}
                    >
                        {item === 'unsplash' && <IconTab name="Unsplash" logo={<UnsplashLogo />} />}
                        {item === 'pexels' && <IconTab name="Pexels" logo={<PexelLogo />} />}
                        {item === 'giphy' && <IconTab name="Giphy" logo={<GiphyLogo />} />}
                        {item === '3d_stickers' && (
                            <IconTab
                                name="3D Stickers"
                                p="2px"
                                logo={
                                    <img
                                        lazy="true"
                                        width="22px"
                                        height="22px"
                                        src="https://res.cloudinary.com/oskarahl/image/upload/w_80,h_80,c_thumb/3d_hands/peace_2_azbrfl.png"
                                        alt="3D hand"
                                    />
                                }
                            />
                        )}
                        {item === 'stickers' && (
                            <IconTab
                                name="Stickers"
                                p="2px"
                                logo={
                                    <img
                                        lazy="true"
                                        width="22px"
                                        height="22px"
                                        src="https://res.cloudinary.com/careersifus-com/image/upload/w_80,h_80,c_thumb/v1611724342/stickers/happy_birthday_3575903_drliwt.png"
                                        alt="Sticker"
                                    />
                                }
                            />
                        )}
                    </Tab>
                ))}
            </TabList>
            <TabPanels width="80%">
                {selection.map((item, idx) => (
                    <TabPanel key={idx}>
                        <Suspense fallback={<Spinner mt="250px" />}>
                            <Box p="0.5rem">
                                {item === 'unsplash' && tab_idx === idx && (
                                    <UnsplashImageGridLazy
                                        idx={idx}
                                        active_idx={tab_idx}
                                        onClickUnsplashImage={onClickUnsplashImage}
                                    />
                                )}
                                {item === 'pexels' && tab_idx === idx && (
                                    <PexelsImageGridLazy
                                        idx={idx}
                                        active_idx={tab_idx}
                                        onClickUnsplashImage={onClickUnsplashImage}
                                    />
                                )}
                                {item === '3d_stickers' && tab_idx === idx && (
                                    <ThreeDSelectLazy idx={idx} active_idx={tab_idx} onClick3D={onClick3D} />
                                )}
                                {item === 'stickers' && tab_idx === idx && (
                                    <StickerLazy idx={idx} active_idx={tab_idx} onClickIcon={onClickIcon} />
                                )}
                            </Box>
                        </Suspense>
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    )
}
