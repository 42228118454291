export const is_development = process.env.NODE_ENV === 'development'

export const RENDER_SERVER_URL = is_development ? 'http://localhost:8888' : 'https://video-renderer.venngage.beer'
export const VERCEL_FUNCTION_URL = '/api'

export const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/oskarahl'
export const CLOUDINARY_UPLOAD_PRESET = 'ml_default'
export const CLOUDINARY_STORAGE = 'http://res.cloudinary.com/oskarahl'
export const CLOUDINARY_API_KEY = '294665774677116'

export const GENERAL_ERR_MSG = 'Something went wrong. Please try again or contact help if the error persists.'
export const GENERAL_ERR_TOAST = { description: GENERAL_ERR_MSG, status: 'error' }

export const MAX_SCENE_DURATION = 30
export const MIN_SCENE_DURATION = 1
export const MAX_VIDEOS_PER_PROJECT = 5
export const MAX_LOTTIE_PER_SCENE = 5
