import { useDispatch, useSelector } from 'react-redux'
import { AudioSelectModal } from '../../UI'
import { useModalStore } from '../modal-context'
import { createAudioObject } from '../stores/item-factory.js'
import { addToAudioTimeline, getTimeline, hasAudio } from '../stores/project-reducer'
import { getItemCount } from '../../../shared-fns/shared-fns'
import { AUDIO_CONFIRM } from '../../../constants/audio'
import { tracker } from '../../Track/track'

const selection = ['audio']

export const AudioSelectModalWrapper = () => {
    const timeline = useSelector(getTimeline)
    const has_audio = useSelector(hasAudio)
    const { is_audio_select_open, closeAudioSelectModal } = useModalStore()
    const dispatch = useDispatch()

    const onNewAudio = (url, audio_duration = 30, name) => {
        if (has_audio && !window.confirm(AUDIO_CONFIRM)) return

        tracker.addAudio()
        const layer_name = 'audio_' + getItemCount(timeline, 'audio')
        const { new_audio } = createAudioObject({
            layer_name,
            time: [0, audio_duration],
            url,
            text: name,
        })

        dispatch(addToAudioTimeline(new_audio))

        closeAudioSelectModal()
    }

    return (
        <AudioSelectModal
            closeModal={closeAudioSelectModal}
            isOpen={is_audio_select_open}
            config={{
                selection,
                onNewAudio,
            }}
        />
    )
}
