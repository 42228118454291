import { useState, useRef } from 'react'
import { Select } from './select'
import { Flex } from './stack'
import { Label } from './text'
import { Input } from './input'
import { VIDEO_SIZES } from '../../constants/resolutions'
import { Button } from './button'

const INITIAL_RESOLUTION = [1920, 1080]
const INITIAL_PROJECT_NAME = 'My Template'

export const ResolutionSelect = ({
    onNewResolution,
    btn_text,
    size_label = 'Size Name:',
    has_project_name,
    isSubmitting,
    initial_size_option_value,
}) => {
    const is_name_touched = useRef(false)

    const [project_name, setProjectName] = useState(INITIAL_PROJECT_NAME)
    const [resolution, setResolution] = useState(initial_size_option_value || INITIAL_RESOLUTION)

    const onSetSize = (size, new_name) => setResolution(size)

    return (
        <Flex mt="0.5" flexDirection="column" minWidth="400px" minHeight="150px">
            {has_project_name && (
                <>
                    <Label htmlFor="size" mb="4px">
                        Template name:
                    </Label>
                    <Input
                        has_small_text={false}
                        maxlength="2000"
                        isInvalid={!project_name}
                        size="sm"
                        name="name"
                        id="name"
                        value={project_name}
                        onChange={({ target }) => {
                            setProjectName(target.value)
                            is_name_touched.current = true
                        }}
                        errorBorderColor="red.300"
                    />
                </>
            )}
            <Label htmlFor="size" mb="4px">
                Size:
            </Label>
            <SizeSelect option_value={resolution} onSetSize={onSetSize} />
            <Flex justifyContent="flex-end" marginTop="1rem">
                <Button
                    isLoading={isSubmitting}
                    isDisabled={!project_name?.trim().length || isSubmitting}
                    variantColor="main"
                    onClick={() => {
                        const new_size = [+resolution[0], +resolution[1]]
                        onNewResolution(new_size, project_name)
                    }}
                    data-vg-test-label="resolution-select-button"
                >
                    {btn_text}
                </Button>
            </Flex>
        </Flex>
    )
}

const SizeSelect = ({ onSetSize, option_value }) => {
    return (
        <Select
            mb="1rem"
            has_small_text={false}
            backgroundColor="var(--input-bg)"
            size="sm"
            id="size"
            value={JSON.stringify(option_value)}
            placeholder="Select size"
            onChange={(e) => {
                const { value } = e.target
                onSetSize(JSON.parse(value))
            }}
        >
            <optgroup label="Video">
                {VIDEO_SIZES.map((option) => (
                    <option key={option.name} value={JSON.stringify(option.resolution)}>
                        {option.name} {`(${option.resolution[0]}x${option.resolution[1]})`}
                    </option>
                ))}
            </optgroup>
        </Select>
    )
}
