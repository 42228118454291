import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    addToTimeline,
    getTimeline,
    getSceneDuration,
    getProjectSize,
    updateTimelineItem,
    updateMultipleTimelineItem,
} from '../stores/project-reducer'
import { ImageSelectModal } from '../../UI'
import { useEdit } from '../edit-context'
import { createImageObject } from '../stores/item-factory'
import { useModalStore } from '../modal-context'
import { getItemCount, snakeCase } from '../../../shared-fns/shared-fns'

const getSelection = (is_replace) => {
    let selection = ['unsplash', 'pexels', '3d_stickers', 'stickers']
    if (is_replace) selection.push('my_images')

    return selection
}

export const ImageSelectModalWrapper = () => {
    const { setActiveItemId } = useEdit()
    const [replace_block_offset, setReplaceBlockOffset] = useState(false)
    const { is_image_select_open, closeImageSelectModal, replace_item } = useModalStore()

    const is_replace = replace_item && replace_item.id
    const selection = getSelection(is_replace)

    const timeline = useSelector(getTimeline)
    const canvas_dimensions = useSelector(getProjectSize)
    const duration = useSelector(getSceneDuration)
    const dispatch = useDispatch()

    const replaceImage = ({ url, text, thumbnail_url }) => {
        if (replace_block_offset) return

        const { id } = replace_item
        if (thumbnail_url) {
            dispatch(
                updateMultipleTimelineItem([
                    { id, updated_value: url, key: 'url' },
                    { id, updated_value: thumbnail_url, key: 'thumbnail_url' },
                ])
            )
        } else {
            dispatch(updateTimelineItem({ id, updated_value: url, key: 'url' }))
        }
        setActiveItemId(id)
        closeImageSelectModal()
    }

    const onNewImage = ({ e, url, alt_description, thumbnail_url = '' }) => {
        if (replace_item && replace_item.type) {
            return replaceImage({ url, text: alt_description, thumbnail_url })
        }
        const { naturalWidth, naturalHeight } = e.target
        const { id, new_image } = createImageObject({
            layer_name:
                (alt_description ? snakeCase(alt_description).toLowerCase() + '_' : 'image_') +
                getItemCount(timeline, 'image'),
            naturalWidth,
            naturalHeight,
            url,
            text: alt_description,
            canvas_dimensions,
            duration,
            thumbnail_url,
        })
        dispatch(addToTimeline(new_image))
        setActiveItemId(id)
        closeImageSelectModal()
    }

    const onNewImageScaled = ({ url, height, width, text }) => {
        if (replace_item && replace_item.type) {
            return replaceImage({ url, text })
        }
        const { id, new_image } = createImageObject({
            layer_name: (text ? snakeCase(text).toLowerCase() + '_' : 'image_') + getItemCount(timeline, 'image'),
            naturalWidth: height,
            naturalHeight: width,
            url,
            text,
            canvas_dimensions,
            duration,
        })
        dispatch(addToTimeline(new_image))
        setActiveItemId(id)
        closeImageSelectModal()
    }

    useEffect(() => {
        if (is_image_select_open && replace_item && replace_item.type) {
            setReplaceBlockOffset(true)
            setTimeout(() => setReplaceBlockOffset(false), 500)
        } else {
            setReplaceBlockOffset(false)
        }
    }, [is_image_select_open, replace_item])

    return (
        <ImageSelectModal
            isOpen={is_image_select_open}
            closeModal={closeImageSelectModal}
            config={{
                selection,
                onClickUnsplashImage: onNewImage,
                onClick3D: onNewImageScaled,
                onClickIcon: onNewImage,
            }}
        />
    )
}
