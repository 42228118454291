import { useEffect } from 'react'

export function useOnPaste(cb) {
    useEffect(() => {
        window.addEventListener('paste', cb)

        return () => {
            window.removeEventListener('paste', cb)
        }
    }, [cb])
}
