import { useEffect } from 'react'
import { Flex, Button, Text } from '../UI'
import { useQueryClient } from 'react-query'

export const FullPageError = () => {
    const queryClient = useQueryClient()

    useEffect(() => {
        queryClient?.clear()
        queryClient?.invalidateQueries()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Flex flexDirection="column" align="center" justify="center" width="100%" height="100vh">
            <Text fontSize="xl" fontWeight="bold">
                Sorry, an error occured
            </Text>
            <Text mb="1rem">Please refresh this page to continue. If the issue persists please contact support.</Text>
            <Button
                variantColor="main"
                onClick={() => {
                    queryClient?.clear()
                    queryClient?.invalidateQueries()
                    window.location.reload()
                }}
            >
                Refresh
            </Button>
        </Flex>
    )
}
