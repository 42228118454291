import { post } from 'axios'
import { CLOUDINARY_URL, CLOUDINARY_API_KEY } from '../constants/api'

export const fileUpload = ({ file, setProgress, type = 'image', timestamp, signature }) => {
    // in cloudinary audios are videos
    const upload_type = type === 'audio' ? 'video' : type
    const url = `${CLOUDINARY_URL}/${upload_type}/upload?api_key=${CLOUDINARY_API_KEY}&timestamp=${timestamp}&signature=${signature}`
    const formData = new FormData()

    formData.append('file', file)

    const config = {
        onUploadProgress: (progressEvent) => {
            const percent_completed = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setProgress(percent_completed)
        },
    }

    return post(url, formData, config)
}

export const removeFileExtension = (file_name) => file_name.replace(/\.[^/.]+$/, '')

export const MAX_FILE_SIZE = 10
export const MAX_AT_A_TIME = 5

export const IMAGE_UPLOAD_ERROR = 'Upload image error. Please try again or contact help if the error persists.'
const MAX_FILE_SIZE_ERROR = `Max file size is ${MAX_FILE_SIZE}mb`
const MAX_FILES_ERROR = `You can upload max ${MAX_AT_A_TIME} images at a time`
const ERROR_TITLE = '🤷‍♂️ Uh oh'

export const SIZE_ERROR_TOAST = { title: ERROR_TITLE, description: MAX_FILE_SIZE_ERROR, status: 'error' }
export const UPLOAD_ERROR_TOAST = { description: IMAGE_UPLOAD_ERROR, status: 'error' }
export const MAX_FILES_TOAST = { description: MAX_FILES_ERROR, status: 'error' }
