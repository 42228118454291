import { useRef, useEffect } from 'react'

export const useTimerOnDone = (fn, timer) => {
    const cb_ref = useRef()

    useEffect(() => {
        if (cb_ref && cb_ref.current) cb_ref.current()

        const removeFn = timer.onDone(fn)
        cb_ref.current = removeFn

        return () => {
            const { current } = cb_ref
            if (current) current()
        }
    }, [fn, timer])
}

export const useTimerOnPlay = (fn, timer) => {
    const on_play_ref = useRef()

    useEffect(() => {
        if (on_play_ref && on_play_ref.current) on_play_ref.current()

        const onStartRemoveFn = timer.onStart((time) => {
            fn(time.ms)
        })
        on_play_ref.current = onStartRemoveFn

        return () => {
            const { current } = on_play_ref
            if (current) current()
        }
    }, [fn, timer])
}

export const useTimerOnStop = (fn, timer) => {
    const cb_ref = useRef()

    useEffect(() => {
        if (cb_ref && cb_ref.current) cb_ref.current()

        const removeFn = timer.onStop(fn)
        cb_ref.current = removeFn

        return () => {
            const { current } = cb_ref
            if (current) current()
        }
    }, [fn, timer])
}

export const useTimerOnReset = (fn, timer) => {
    const cb_ref = useRef()

    useEffect(() => {
        if (cb_ref && cb_ref.current) cb_ref.current()

        const removeFn = timer.onReset((time) => {
            fn(time.ms)
        })
        cb_ref.current = removeFn

        return () => {
            const { current } = cb_ref
            if (current) current()
        }
    }, [fn, timer])
}

export const useTimerOnTime = (fn, timer, shouldAddListener) => {
    const cb_ref = useRef()

    useEffect(() => {
        if (cb_ref && cb_ref.current) cb_ref.current()
        if (shouldAddListener && !shouldAddListener()) return

        const removeFn = timer.onTime((time) => {
            fn(time.ms)
        })
        cb_ref.current = removeFn

        return () => {
            const { current } = cb_ref
            if (current) current()
        }
    }, [fn, timer, shouldAddListener])
}
