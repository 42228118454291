import uuid from 'react-uuid'
import { createSlice } from '@reduxjs/toolkit'
import { MAX_VIDEOS_PER_PROJECT_TOAST } from '../edit-config'
import { markerUtil } from '../../Marker/marker-fns'
import { getScenesVideoCount } from '../../../shared-fns/shared-fns'
import { MAX_VIDEOS_PER_PROJECT, MAX_LOTTIE_PER_SCENE } from '../../../constants/api'

const updateTimelineItemShared = (state, action) => {
    const { key, id, updated_value } = action.payload

    for (let i = 0; i < state.scenes.length; i++) {
        const scene = state.scenes[i]
        const layer = scene.timeline.find((item) => item.id === id)

        if (layer) {
            layer[key] = updated_value
            return
        }
    }
}

export const projectSlice = createSlice({
    name: 'project',
    reducers: {
        setEntireProject: (state, action) => {
            const { project } = action.payload
            return project
        },
        addAssetUploading: (state, action) => {
            const { id } = action.payload

            if (!state.asset_uploading) {
                state.asset_uploading = [id]
                return
            }
            state.asset_uploading.push(id)
        },
        removeAssetUploading: (state, action) => {
            const { id } = action.payload
            state.asset_uploading = state.asset_uploading.filter((asset_id) => asset_id !== id)
        },
        updateProject: (state, action) => {
            const { key, updated_value } = action.payload
            state[key] = updated_value
        },
        addToTimeline: (state, action) => {
            const { payload } = action
            const is_video = markerUtil.isVideo(payload)
            const is_lottie = markerUtil.isLottie(payload)

            if (is_video) {
                const video_count = getScenesVideoCount(state.scenes)
                if (video_count + 1 > MAX_VIDEOS_PER_PROJECT) return window.toast(MAX_VIDEOS_PER_PROJECT_TOAST)
            }

            if (is_lottie) {
                const lottie_count = state.scenes[state.active].timeline.filter(markerUtil.isLottie)?.length
                if (lottie_count + 1 > MAX_LOTTIE_PER_SCENE)
                    return window.toast({
                        description: `You can only add up to ${MAX_LOTTIE_PER_SCENE} animations per scene`,
                        status: 'error',
                        title: 'Animation layer limit',
                    })
            }

            state.scenes[state.active].timeline.push(payload)
        },
        addToTimelineNoHistory: (state, action) => {
            const { payload } = action
            state.scenes[state.active].timeline.push(payload)
        },
        addToAudioTimeline: (state, action) => {
            const { payload } = action
            state.audio_timeline = [payload]
        },
        deleteAudio: (state) => {
            state.audio_timeline = []
        },
        updateAudioLayerNoHistory: (state, action) => {
            const { key, updated_value } = action.payload
            state.audio_timeline[0][key] = updated_value
        },
        addMultiple: (state, action) => {
            state.scenes[state.active].timeline = state.scenes[state.active].timeline.concat(action.payload)
        },
        removeFromTimeline: (state, action) => {
            state.scenes[state.active].timeline = state.scenes[state.active].timeline.filter(
                (item) => item.id !== action.payload
            )
        },
        removeMultipleFromTimeline: (state, action) => {
            const { ids } = action.payload
            state.scenes[state.active].timeline = state.scenes[state.active].timeline.filter(
                (item) => !ids.includes(item.id)
            )
        },
        updateMultipleTimelineItem: (state, action) => {
            const updates = action.payload
            updates.forEach((item) => updateTimelineItemShared(state, { payload: item }))
        },
        updateTimelineMultipleItemNoHistory: (state, action) => {
            const updates = action.payload
            updates.forEach((item) => updateTimelineItemShared(state, { payload: item }))
        },
        updateTimelineItem: updateTimelineItemShared,
        // OBS NAME LINKED TO EDIT-STORE
        updateTimelineItemNoHistory: updateTimelineItemShared,
        updateScene: (state, action) => {
            const { key, updated_value } = action.payload
            state.scenes[state.active][key] = updated_value
        },
        updateMultipleInScene: (state, action) => {
            const { changes, scene_idx = state.active } = action.payload

            changes.forEach((action) => {
                const { key, updated_value } = action
                state.scenes[scene_idx][key] = updated_value
            })
        },
        addNewScene: (state, action) => {
            const { new_scene, insert_after_idx } = action.payload

            if (insert_after_idx) state.scenes.splice(insert_after_idx, 0, new_scene)
            else state.scenes.push(new_scene)
        },
        setActiveScene: (state, action) => {
            const { active_scene } = action.payload
            if (!!state.scenes[active_scene]) {
                state.active = active_scene
            } else {
                state.active = state.scenes.length - 1
            }
        },
        setNextScene: (state, action) => {
            const active_scene = state.active
            const next_scene = active_scene + 1
            if (!!state.scenes[next_scene]) {
                state.active = next_scene
            }
        },
        setLastActiveScene: (state) => {
            state.active = state.scenes.length - 1
        },
        removeScene: (state, action) => {
            if (window.is_dragging_scene) return state

            const { scene_idx } = action.payload

            let new_scene_idx = scene_idx
            const is_last = scene_idx === state.scenes.length - 1
            if (is_last) new_scene_idx = scene_idx - 1
            if (scene_idx === 0) new_scene_idx = 0

            state.active = new_scene_idx
            state.scenes = state.scenes.filter((_, idx) => idx !== scene_idx)
        },
        updateAllScenes: (state, action) => {
            const { scenes } = action.payload
            state.scenes = scenes
        },
    },
})

export const {
    setEntireProject,
    updateProject,
    addToTimeline,
    addToTimelineNoHistory,
    addMultiple,
    removeFromTimeline,
    removeMultipleFromTimeline,
    updateTimelineItem,
    updateMultipleTimelineItem,
    updateTimelineMultipleItemNoHistory,
    updateTimelineItemNoHistory,
    updateScene,
    updateMultipleInScene,
    addNewScene,
    setActiveScene,
    setLastActiveScene,
    removeScene,
    setNextScene,
    updateAllScenes,
    addToAudioTimeline,
    updateAudioLayerNoHistory,
    deleteAudio,
    addAssetUploading,
    removeAssetUploading,
} = projectSlice.actions

const reverse = (arr) => {
    return [...arr].reverse()
}

export const getTimeline = (state) => state.project.present.scenes[state.project.present.active].timeline

export const getTimelineInOrder = (state) =>
    reverse(state.project.present.scenes[state.project.present.active].timeline)

export const getSceneBackground = (state) => {
    return state.project.present.scenes[state.project.present.active].background
}

export const getSizeName = (state) => {
    return state.project.present.scenes[state.project.present.active].size_name
}

export const hasFuture = (state) => {
    return !!state.project.future.length
}

export const hasPast = (state) => {
    return !!state.project.past.length
}

export const getActiveSceneIdx = (state) => {
    return state.project.present.active
}

export const getScenes = (state) => {
    return state.project.present.scenes
}

export const getProjectId = (state) => {
    return state.project.present.id
}

export const getTimelineItem = (id) => (state) => {
    return state.project.present.scenes[state.project.present.active].timeline.filter((item) => item.id === id)
}

export const getState = (state) => {
    return state.project.present
}

export const getProjectName = (state) => {
    return state.project.present.name
}

export const getAspectRatio = (state) => {
    return state.project.present.scenes[state.project.present.active].aspect_ratio
}

export const getProjectSize = (state) => {
    return state.project.present.scenes[state.project.present.active].aspect_ratio
}
export const getSceneDuration = (state) => {
    return state.project.present.scenes[state.project.present.active].duration
}
export const getTotalProjectDuration = (state) => {
    let combined_duration = 0
    state.project.present.scenes.forEach((s) => (combined_duration += s.duration))
    return combined_duration
}

export const getAccumulatedTime = (state) => {
    const active_scene = state.project.present.active
    let accumulated_time = 0

    for (let i = 0; i < active_scene; i++) {
        accumulated_time += state.project.present.scenes[i].duration
    }

    return accumulated_time
}

export const getTemplateCreatedFrom = (state) => state.project.present.created_from_template

const SECOND_IN_MS = 1000
export const getSceneDurationMs = (state) => getSceneDuration(state) * SECOND_IN_MS

export const getAudioLayers = (state) => state.project.present.audio_timeline || []
export const hasAudio = (state) =>
    !!(state.project.present.audio_timeline && state.project.present.audio_timeline.length)

export const getId = () => uuid()

export default projectSlice.reducer
