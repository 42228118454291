import { useModalStore } from '../modal-context'
import { ImageSelectModalWrapper } from './image-select-modal'
import { VideoSelectModalWrapper } from './video-select-modal'
import { AudioSelectModalWrapper } from './audio-select-modal'
import { LinkUploadModal } from './link-upload-modal'

export const Modals = () => {
    const { is_image_select_open, is_video_select_open, is_audio_select_open, is_link_upload_open } = useModalStore()

    return (
        <>
            {is_image_select_open && <ImageSelectModalWrapper />}
            {is_video_select_open && <VideoSelectModalWrapper />}
            {is_audio_select_open && <AudioSelectModalWrapper />}
            {is_link_upload_open && <LinkUploadModal />}
        </>
    )
}
