export function isValidImage(url, timeoutT) {
    return new Promise(function (resolve) {
        const timeout = timeoutT || 5000
        var timer
        var img = new Image()

        img.onerror = img.onabort = function () {
            clearTimeout(timer)
            resolve({ is_valid_image: false })
        }
        img.onload = function (e) {
            const { naturalWidth, naturalHeight } = e.target
            clearTimeout(timer)
            resolve({ is_valid_image: true, naturalWidth, naturalHeight })
        }
        timer = setTimeout(function () {
            // reset .src to invalid URL so it stops previous
            // loading, but doesn't trigger new load
            img.src = '//!!!!/test.jpg'
            resolve({ is_valid_image: false })
        }, timeout)
        img.src = url
    })
}

export const blobToUrl = (blob, type) => {
    const URLObj = window.URL || window.webkitURL

    if (type === 'image') {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = function () {
                const { height, width, src } = this
                resolve({ height, width, url: src, original_filename: 'uploaded_image' })
            }
            img.src = URLObj.createObjectURL(blob)
        })
    }
    if (type === 'video') {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video')
            video.oncanplay = function () {
                const { videoWidth, videoHeight, src, duration } = this
                resolve({
                    height: videoHeight,
                    width: videoWidth,
                    url: src,
                    original_filename: 'uploaded_video',
                    duration,
                })
            }
            video.src = URLObj.createObjectURL(blob)
        })
    }
    if (type === 'audio') {
        return new Promise((resolve, reject) => {
            const audio = document.createElement('audio')
            audio.oncanplay = function () {
                const { src, duration } = this
                resolve({ url: src, original_filename: 'uploaded_audio', duration })
            }
            audio.src = URLObj.createObjectURL(blob)
        })
    }
}

export const getVideoDuration = (src) => {
    return new Promise((resolve) => {
        const video_el = document.createElement('video')
        video_el.src = src
        video_el.addEventListener('loadedmetadata', (event) => {
            resolve(video_el.duration)
        })
    })
}

export function isValidVideo(url, timeoutT) {
    return new Promise(function (resolve) {
        const { is_valid_audio } = isValidAudio(url)
        if (is_valid_audio) return resolve({ is_valid_video: false })

        const timeout = timeoutT || 10000
        var timer
        var video = document.createElement('video')
        video.src = url

        video.onloadedmetadata = (e) => {
            clearTimeout(timer)
            const { videoWidth, videoHeight, duration } = e.target
            resolve({ is_valid_video: true, videoWidth, videoHeight, duration })
        }
        video.onerror = (e) => {
            clearTimeout(timer)
            resolve({ is_valid_video: false })
        }
        timer = setTimeout(function () {
            console.log('timeout')
            // reset .src to invalid URL so it stops previous
            // loading, but doesn't trigger new load
            video.src = '//!!!!/test.jpg'
            resolve({ is_valid_video: false })
        }, timeout)
    })
}

export const isValidLottie = (url) => {
    if (url && url.endsWith('.json')) {
        return true
    }
    return false
}

export function isValidAudio(url) {
    const is_valid_audio = /\.(mp3|wav)$/i.test(url)
    return { is_valid_audio }
}

export const load = (e, current_time = 0) => {
    e.target.currentTime = current_time
    e.target.pause()
}
export const play = (e) => e.target.play()

// pexel videos re-downloading between scenes because request from Pexels is redirected
// cache videos in browser with URL.createObjectURL
export const downloadVimeoVideo = (url) => {
    return new Promise((resolve, reject) => {
        fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
                const objectURL = URL.createObjectURL(blob)
                resolve(objectURL)
            })
            .catch((e) => {
                reject(e)
            })
    })
}
