import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Skeleton } from '@chakra-ui/core'
import { Box } from '../UI'
import { getBorderRadius, getShadowFilter } from '../Marker/canvas-base'
import { MarkerBase, getMarkerTimeMs } from './marker-base'

export const ImageMarker = ({ item, timer, video_duration_ms }) => {
    const {
        animation,
        time,
        url,
        border_radius,
        opacity,
        filter,
        shadow,
        background_size,
        overlay,
        border,
        border_color,
        is_processing,
    } = item

    const [start_ms, end_ms] = getMarkerTimeMs(time)
    return (
        <MarkerBase
            item={item}
            video_duration_ms={video_duration_ms}
            timer={timer}
            animation={animation}
            start_ms={start_ms}
            end_ms={end_ms}
        >
            <ImageLayout
                is_processing={is_processing}
                background_size={background_size}
                shadow={shadow}
                opacity={opacity}
                border_radius={border_radius}
                url={url}
                id={item.id}
                filter={filter}
                overlay={overlay}
                border={border}
                border_color={border_color}
            />
        </MarkerBase>
    )
}

export const ImageBox = styled(Box)`
    background-position: 50%;
    background-repeat: no-repeat;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
`

export const ImageLayout = ({
    url,
    id,
    border_radius = 'sm',
    opacity,
    filter,
    shadow,
    background_size,
    overlay,
    border,
    border_color,
    is_processing,
}) => {
    const [is_loading, setIsLoading] = useState(false)
    const [image_id, setId] = useState('')

    useEffect(() => {
        if (!url) setIsLoading(false)

        const img = new Image()
        img.src = url
        img.onload = function () {
            setId('id' + id)
            setIsLoading(false)
        }
        return () => {
            if (img) img.onload = () => {}
        }
        // only on url change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    const shadow_filter = getShadowFilter(shadow)

    return (
        <Skeleton
            style={{
                filter: shadow_filter,
                position: 'relative',
                overflow: 'hidden',
            }}
            width="100%"
            height="100%"
            isLoaded={!is_loading && !is_processing}
            fadeInDuration={0}
        >
            {overlay && (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        background: overlay,
                        zIndex: 1,
                        borderRadius: getBorderRadius(border_radius),
                    }}
                />
            )}
            <ImageBox
                border={`${border}px solid ${border_color}`}
                backgroundSize={background_size ? background_size : 'cover'}
                borderRadius={getBorderRadius(border_radius)}
                id={image_id}
                style={{ backgroundImage: `url(${url})`, opacity, filter }}
                width="100%"
                height="100%"
                filter={filter}
            />
        </Skeleton>
    )
}

export const ImageBoxLayout = ({ border, border_color, background_size, border_radius, opacity, url }) => {
    return (
        <ImageBox
            border={`${border}px solid ${border_color}`}
            backgroundSize={background_size ? background_size : 'cover'}
            borderRadius={getBorderRadius(border_radius)}
            style={{ backgroundImage: `url(${url})`, opacity }}
            width="100%"
            height="100%"
        />
    )
}
