import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { DEVICE } from '../../constants/device-breakpoints'

import { Stack as BaseStack, Flex as BaseFlex, Box as BaseBox } from '@chakra-ui/core'

export const Stack = forwardRef(({ children, ...props }, ref) => (
    <BaseStack ref={ref} {...props}>
        {children}
    </BaseStack>
))

const StyledFlex = styled(BaseFlex)`
    @media ${DEVICE.tabletL} {
        flex-direction: ${(props) => (props.mobile_column ? 'column' : '')};
    }
`

export const Flex = forwardRef(({ children, ...props }, ref) => (
    <StyledFlex ref={ref} {...props}>
        {children}
    </StyledFlex>
))

export const Box = forwardRef(({ children, ...props }, ref) => (
    <BaseBox ref={ref} {...props}>
        {children}
    </BaseBox>
))
