export const KEY = {
    1: 49,
    2: 50,
    3: 51,
    BACK_SPACE: 8,
    DELETE: 46,
    RETURN: 13,
    F2: 113,
    SPACE_BAR: 32,
    LEFT: 37,
    RIGHT: 39,
    UP: 38,
    DOWN: 40,
    ESC: 27,
    PLUS: 187,
    MINUS: 189,
    TAB: 9,
    A: 65,
    C: 67,
    V: 86,
    Z: 90,
    I: 73,
    L: 76,
    U: 85,
    T: 84,
    B: 66,
    E: 69,
    R: 82,
    P: 80,
    D: 68,
    S: 83,
    Y: 89,
    OPEN_BRACKET: 219,
    CLOSE_BRACKET: 221,
    OPTION: 18,
}
