import { useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import Timer from '../Timer/timer'
import { PlayPauseIcon } from '../Timer/play-pause'
import { useTimerOnTime, useTimerOnDone } from '../Timer/timer-hooks'
import { getTimeFromX, displaySeconds } from '../Timer/timer-helpers'
import { Box, Flex, Text } from '../UI'
import { markerUtil } from '../Marker/marker-fns'
import { createCanvasBg, getInitialScale, getAspectRatio, isWithinTime } from '../../shared-fns/shared-fns'
import {
    ViewImageMarker,
    ViewTextMarker,
    ViewBoxMarker,
    ViewQRCodeMarker,
    ViewLottieMarker,
    ViewVideoMarker,
    ViewAudioMarker,
} from '../View/view-markers.js'
import { getCanvasSize } from '../../constants/resolutions'
import { useOnDownKeyPress } from '../../hooks/use-onKeyDown'
import { KEY } from '../../constants/keyboard'

const TIMER_OPTIONS = { refreshRateMS: 20 }

export const Renderer = ({
    project,
    onTextSizeChange,
    active_scene,
    max_width,
    max_height,
    is_view,
    is_view_renderer,
    time,
    has_audio = false,
}) => {
    const { scenes } = project
    const aspect_ratio = getAspectRatio(project, active_scene)
    const [scale, setScale] = useState(0.5)
    const render_box_ref = useRef()

    const { duration } = scenes[active_scene]
    const video_duration_ms = duration * 1000
    const timerBase = useRef(new Timer(video_duration_ms, TIMER_OPTIONS))
    const timer = timerBase.current

    const { background, timeline } = scenes[active_scene]
    const background_style = createCanvasBg(background)

    const [real_width, real_height] = getCanvasSize(aspect_ratio)

    const mx = (real_width * scale - real_width) / 2
    const my = (real_height * scale - real_height) / 2

    useLayoutEffect(() => {
        if (is_view) return setScale(1)

        const aspect_ratio = getAspectRatio(project, active_scene)
        const scale = getInitialScale(aspect_ratio, max_width, max_height, true)
        setScale(scale)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active_scene, max_width, max_height, project])

    useEffect(() => {
        window.timer = timer
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const shared_marker_props = { timer, video_duration_ms }

    const playVideo = () => {
        if (timer.ms === 0) timer.reset()
        timer.startstop()
    }

    const keyPressHandler = (key, e) => {
        if (e.target.nodeName === 'TEXTAREA') return
        if (e.target.nodeName === 'INPUT') return

        if (key === KEY.SPACE_BAR) {
            e.preventDefault()
            playVideo()
        }
    }

    useOnDownKeyPress(keyPressHandler)

    return (
        <>
            <Box ref={render_box_ref}>
                <Flex justify="center" overflow="hidden">
                    <Flex justify="center" onClick={playVideo}>
                        <Box
                            overflow="hidden"
                            width={`${real_width}px`}
                            height={`${real_height}px`}
                            transform={`scale(${scale})`}
                            mx={mx + 'px'}
                            my={my + 'px'}
                        >
                            <Box width="100%" height="100%" id="canvas_ref" style={background_style}>
                                {timeline.map((item, idx_of_marker) => {
                                    if (item.is_hidden) return null
                                    if (is_view_renderer && !isWithinTime(item.time, time / 1000)) return null

                                    if (markerUtil.isText(item)) {
                                        return (
                                            <ViewTextMarker
                                                {...shared_marker_props}
                                                item={item}
                                                key={item.id}
                                                layer_postition={idx_of_marker}
                                                onTextSizeChange={onTextSizeChange}
                                            />
                                        )
                                    }
                                    if (markerUtil.isImage(item)) {
                                        return (
                                            <ViewImageMarker
                                                {...shared_marker_props}
                                                item={item}
                                                key={item.id}
                                                layer_postition={idx_of_marker}
                                            />
                                        )
                                    }
                                    if (markerUtil.isBox(item)) {
                                        return (
                                            <ViewBoxMarker
                                                {...shared_marker_props}
                                                item={item}
                                                key={item.id}
                                                layer_postition={idx_of_marker}
                                            />
                                        )
                                    }
                                    if (markerUtil.isQRCode(item)) {
                                        return (
                                            <ViewQRCodeMarker
                                                {...shared_marker_props}
                                                item={item}
                                                key={item.id}
                                                layer_postition={idx_of_marker}
                                            />
                                        )
                                    }
                                    if (markerUtil.isLottie(item)) {
                                        return (
                                            <ViewLottieMarker
                                                {...shared_marker_props}
                                                item={item}
                                                key={item.id}
                                                layer_postition={idx_of_marker}
                                            />
                                        )
                                    }
                                    if (markerUtil.isVideo(item)) {
                                        return (
                                            <ViewVideoMarker
                                                {...shared_marker_props}
                                                item={item}
                                                key={item.id}
                                                layer_postition={idx_of_marker}
                                            />
                                        )
                                    }
                                    if (markerUtil.isAudio(item) && has_audio) {
                                        return (
                                            <ViewAudioMarker
                                                {...shared_marker_props}
                                                item={item}
                                                key={item.id}
                                                layer_postition={idx_of_marker}
                                            />
                                        )
                                    }
                                    return null
                                })}
                            </Box>
                        </Box>
                    </Flex>
                </Flex>
                <VideoControl
                    playVideo={playVideo}
                    video_duration={duration}
                    timer={timer}
                    video_duration_ms={video_duration_ms}
                    time={time}
                />
            </Box>
        </>
    )
}

const PLAY_BOX_WIDTH = 40
export const VideoControl = ({ video_duration, timer, video_duration_ms, playVideo, time }) => {
    const progress_ref = useRef(null)
    const [current_time, setCurrentTime] = useState(video_duration_ms)

    const resetTimer = useCallback(() => {
        timer.reset(video_duration_ms)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video_duration_ms, timer])

    useTimerOnTime(setCurrentTime, timer)
    useTimerOnDone(resetTimer, timer)

    const getWidth = () => {
        if (!progress_ref.current) return 0
        return progress_ref.current.clientWidth
    }

    const seekVideo = (e) => {
        const timeline_width = getWidth()
        const x = e.clientX - e.target.offsetLeft

        const time = getTimeFromX({ x, timeline_width, video_duration })
        const time_for_countdown = video_duration_ms - time * 1000
        timer.reset(time_for_countdown)
    }

    useEffect(() => {
        const time_for_countdown = video_duration_ms - time
        timer.reset(time_for_countdown)
    }, [time, timer, video_duration_ms])

    return (
        <Flex width="100%" align="center" p="6px" backgroundColor="#1e252c">
            <Box width={`${PLAY_BOX_WIDTH}px`} minWidth={`${PLAY_BOX_WIDTH}px`} maxWidth={`${PLAY_BOX_WIDTH}px`}>
                <PlayPauseIcon playVideo={playVideo} timer={timer} />
            </Box>
            <ProgressBar
                ref={progress_ref}
                onClick={seekVideo}
                max={video_duration_ms}
                value={video_duration_ms - current_time}
            />
            <Text mr={0.5} minWidth={'60px'} fontSize={'12px'} color="var(--grey-2)">
                {displaySeconds(video_duration_ms, current_time)} / {video_duration}s
            </Text>
        </Flex>
    )
}

const ProgressBar = styled.progress`
    width: 100%;
    height: 8px;
    border-radius: 8px;
    margin-right: 16px;
    &:hover {
        cursor: pointer;
    }
    &::-webkit-progress-bar {
        background-color: var(--grey-5);
        border-radius: 7px;
        height: 6px;
    }
    &::-webkit-progress-value {
        background-color: var(--grey-2);
        border-radius: 4px;
    }
`
