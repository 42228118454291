import { useToast as ChakraUseToast } from '@chakra-ui/core'
import { Box, Flex } from './stack'
import { Text } from './text'

export const CustomToastError = ({ onClose, children, title }) => (
    <Box p="16px" m={3} backgroundColor="red.500" borderRadius="4px">
        <Flex>
            <Text color="white" fontWeight="bold">
                {title}
            </Text>
            <Box color="white" fontWeight="bold" ml="auto" role="button" onClick={onClose}>
                &#x2715;
            </Box>
        </Flex>
        <Text color="white">{children}</Text>
    </Box>
)

export const useToast = () => {
    const toast = ChakraUseToast()

    const setToast = ({ description, status, title, duration = 6000, component }) => {
        if (!title && status === 'error') {
            title = 'Something went wrong'
        }

        const toast_obj = {
            title,
            description,
            position: 'top-right',
            status,
            duration,
            isClosable: true,
        }
        if (component) {
            return toast({ ...toast_obj, render: component })
        }
        toast(toast_obj)
    }

    return setToast
}
