import { useQuery, useMutation, useQueryClient } from 'react-query'
import { get, post, put } from 'axios'
import { useHistory } from 'react-router-dom'
import * as ROUTES from '../../constants/routes'
import { VERCEL_FUNCTION_URL } from '../../constants/api'
import { useToast, CustomToastError } from '../UI'
import { INFOGRAPH_URL } from '../../constants/auth'
import { tracker, FROM } from '../Track/track'

export const PROJECTS = 'projects'
const DELETE_ERROR = "Couldn't delete your project. Please try again or contact support if the error persists."
const UPDATE_ERROR = "Couldn't create your project. Please try again or contact support if the error persists."

export function useProjects(api_key) {
    const getProjects = async () => {
        const config = { headers: { 'x-api-key': api_key } }
        const res = await get(`${VERCEL_FUNCTION_URL}/project`, config)

        return res.data
    }

    return useQuery(PROJECTS, getProjects, {
        staleTime: Infinity,
        cacheTime: Infinity,
        suspense: true,
        refetchOnMount: false,
    })
}

const addNewProject = async ({ api_key, new_project }) => {
    const config = { headers: { 'x-api-key': api_key } }
    const res = await post(`${VERCEL_FUNCTION_URL}/project`, { new_project }, config)
    return res.data
}

const PROJECT_CREATE_ERROR = "Couldn't create your project. Please try again or contact support if the error persists."

export const useAddProject = (onSettled) => {
    const toast = useToast()
    const queryClient = useQueryClient()
    const history = useHistory()

    return useMutation(addNewProject, {
        onMutate: async (variables) => {
            const previous_projects = queryClient.getQueryData(PROJECTS)

            await queryClient.cancelQueries(PROJECTS)
            queryClient.setQueryData(PROJECTS, (old) => [variables.new_project, ...old])

            return { previous_projects }
        },
        onSuccess: (data, variables) => {
            if (variables.is_duplicate) return
            return history.push(`${ROUTES.EDIT}/${data.id}`, { project: variables.new_project })
        },
        onError: (error, variables, context) => {
            console.log('onError', error)
            const data = error.response?.data
            if (!data || !data.status) {
                toast({
                    description: PROJECT_CREATE_ERROR,
                    status: 'error',
                })
            } else {
                const { has_reached_project_creation_limit, project_quota } = data
                if (has_reached_project_creation_limit) {
                    toast({
                        component: ({ onClose }) => (
                            <CustomToastError title="Upgrade Account" onClose={onClose}>
                                You have reached the limit of {project_quota} projects.{' '}
                                <a
                                    onClick={() => tracker.clickUpgrade(FROM.PROJECT)}
                                    style={{ textDecoration: 'underline' }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${INFOGRAPH_URL}/account/subscription`}
                                >
                                    Upgrade your account
                                </a>{' '}
                                for unlimited projects.
                            </CustomToastError>
                        ),
                    })
                }
            }
            queryClient.setQueryData(PROJECTS, context.previous_projects)
        },
        onSettled: () => {
            queryClient.invalidateQueries(PROJECTS)
            if (onSettled) onSettled()
        },
    })
}

const deleteProject = async ({ api_key, id }) => {
    const config = { headers: { 'x-api-key': api_key } }
    const res = await put(`${VERCEL_FUNCTION_URL}/project/${id}`, {}, config)

    return res.data
}

export const useDeleteProject = () => {
    const toast = useToast()
    const queryClient = useQueryClient()

    return useMutation(deleteProject, {
        onMutate: async (variables) => {
            await queryClient.cancelQueries(PROJECTS)
            const previous_projects = queryClient.getQueryData(PROJECTS)
            queryClient.setQueryData(PROJECTS, (old) => old.filter((e) => e.id !== variables.id))

            return { previous_projects }
        },
        onError: (error, variables, context) => {
            console.log('onError', error)
            const e = error.response?.data?.status || DELETE_ERROR

            toast({
                description: e,
                status: 'error',
            })

            queryClient.setQueryData(PROJECTS, context.previous_projects)
        },
        onSettled: () => {
            queryClient.invalidateQueries(PROJECTS)
        },
    })
}

const updateProject = async ({ api_key, id, req }) => {
    const config = { headers: { 'x-api-key': api_key } }
    const res = await post(`${VERCEL_FUNCTION_URL}/project/${id}`, req, config)

    return res.data
}

export const useUpdateProject = () => {
    const toast = useToast()
    const queryClient = useQueryClient()

    return useMutation(updateProject, {
        onMutate: async (variables) => {
            await queryClient.cancelQueries(PROJECTS)
            const previous_projects = queryClient.getQueryData(PROJECTS)

            if (variables.req?.name) {
                queryClient.setQueryData(PROJECTS, (old) => {
                    return old.map((e) => {
                        if (e.id === variables.id) {
                            if (variables.req.name) {
                                e.name = variables.req.name
                            }
                        }
                        return e
                    })
                })
            }

            return { previous_projects }
        },
        onError: (error, variables, context) => {
            console.log('onError', error)
            const e = error.response?.data?.status || UPDATE_ERROR

            toast({
                description: e,
                status: 'error',
            })
            queryClient.setQueryData(PROJECTS, context.previous_projects)
        },
        onSettled: () => queryClient.invalidateQueries(PROJECTS),
    })
}
