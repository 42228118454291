import { useState, useImperativeHandle, forwardRef } from 'react'
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/core'
import { useDebouncedEffect } from '../../hooks/use-debouncedEffect'

export const Progress = forwardRef(
    ({ onChange, default_value, max = 100, min = 0, step, debounceTime = 50, is_disabled, size }, ref) => {
        const [slider_value, setSliderValue] = useState(default_value || default_value === 0 ? default_value : 100)

        useDebouncedEffect(() => onChange(slider_value), debounceTime, [slider_value])

        useImperativeHandle(ref, () => ({
            incrementSlider(increment) {
                const new_value = increment + slider_value
                if (new_value > max) return
                if (new_value < min) return

                setSliderValue(new_value)
            },
            updateSliderValue(new_value) {
                setSliderValue(new_value)
            },
        }))

        return (
            <Slider
                cursor={is_disabled ? 'not-allowed' : 'pointer'}
                style={{ boxShadow: 'none', outline: 'none' }}
                isDisabled={is_disabled}
                step={step}
                flex="1"
                color={is_disabled ? 'var(--grey-1)' : 'main'}
                max={max}
                min={min}
                value={slider_value === 'full' ? max : slider_value}
                onChange={setSliderValue}
                size={size}
            >
                <SliderTrack />
                <SliderFilledTrack />
                <SliderThumb border="1px" borderColor="gray.400" fontSize="sm" width="16px" height="16px" />
            </Slider>
        )
    }
)
