import { getId } from '../stores/project-reducer'
import { calculateAspectRatioFit } from '../../../shared-fns/shared-fns'

const DEFAULT_TRANSFORM_X = 0
const DEFAULT_TRANSFORM_Y = 0
const DEFAULT_ROTATE = 0

export const createImageObject = ({
    text = 'Image',
    overlay = 'transparent',
    border_color = '#000000',
    border = 0,
    naturalWidth,
    naturalHeight,
    url,
    thumbnail_url = '',
    size,
    position = [0, 0],
    border_radius = 'none',
    shadow = 0,
    opacity = 1,
    filter = '',
    is_aspect_ratio_locked = false,
    is_half = true,
    is_locked = false,
    is_hidden = false,
    layer_name = '',
    canvas_dimensions,
    animation = 'none',
    animation_speed = 1,
    animation_state = 'enter',
    duration,
    current_time = 0,
    time,
}) => {
    const id = getId()

    let new_size
    if (!size) {
        const { width, height } = calculateAspectRatioFit(
            naturalWidth,
            naturalHeight,
            canvas_dimensions[0],
            canvas_dimensions[1]
        )
        if (is_half) new_size = [width / 2, height / 2, DEFAULT_TRANSFORM_X, DEFAULT_TRANSFORM_Y, DEFAULT_ROTATE]
        else new_size = [width, height, DEFAULT_TRANSFORM_X, DEFAULT_TRANSFORM_Y, DEFAULT_ROTATE]
    } else {
        new_size = size
    }

    const new_image = {
        id,
        url,
        thumbnail_url,
        text: text || 'Image',
        type: 'image',
        overlay,
        position,
        opacity,
        border,
        border_radius,
        shadow,
        size: new_size,
        is_aspect_ratio_locked,
        is_locked,
        is_hidden,
        filter,
        layer_name,
        border_color,
        time: time ? time : [current_time, duration],
        animation,
        animation_speed,
        animation_state,
    }
    return { new_image, id }
}

export const createVideoObject = ({
    text = 'Video',
    videoWidth,
    videoHeight,
    duration,
    video_duration,
    url,
    image_url = '',
    size,
    time,
    position,
    animation = 'none',
    animation_speed = 1,
    opacity = 1,
    shadow = 0,
    border_radius = 'none',
    is_aspect_ratio_locked = false,
    is_locked = false,
    filter = '',
    has_max = true,
    animation_state = 'enter',
    layer_name = '',
    canvas_dimensions,
}) => {
    const id = getId()
    let new_size
    if (!size) {
        const [clientWidth, clientHeight] = canvas_dimensions
        const { width, height } = calculateAspectRatioFit(videoWidth, videoHeight, clientWidth, clientHeight)
        new_size = [width / 2, height / 2, DEFAULT_TRANSFORM_X, DEFAULT_TRANSFORM_Y, DEFAULT_ROTATE]
    } else {
        new_size = size
    }
    const new_video = {
        id,
        url,
        image_url,
        animation,
        animation_speed,
        animation_state,
        text,
        type: 'video',
        shadow,
        border_radius,
        opacity,
        time: time ? time : [0, Math.min(video_duration, duration)],
        total_video_duration_s: video_duration,
        position: position ? position : [0, 0],
        size: new_size,
        is_aspect_ratio_locked,
        is_locked,
        filter,
        layer_name,
    }
    if (has_max) {
        new_video.max = duration
    }
    return { new_video, id }
}

export const createLottieObject = ({
    text = 'Video',
    canvas_dimensions,
    videoWidth,
    videoHeight,
    duration,
    current_time = 0,
    url,
    size,
    time,
    position,
    animation = 'none',
    animation_speed = 1,
    opacity = 1,
    shadow = 0,
    is_aspect_ratio_locked = false,
    is_locked = false,
    has_max = false,
    animation_state = 'enter',
    layer_name = '',
    is_loop = true,
}) => {
    const id = getId()
    let new_size
    if (!size) {
        const [clientWidth, clientHeight] = canvas_dimensions
        const { width, height } = calculateAspectRatioFit(videoWidth, videoHeight, clientWidth, clientHeight)
        new_size = [width / 2, height / 2, DEFAULT_TRANSFORM_X, DEFAULT_TRANSFORM_Y, DEFAULT_ROTATE]
    } else {
        new_size = size
    }

    const new_lottie = {
        id,
        url,
        animation,
        animation_speed,
        animation_state,
        text,
        type: 'lottie',
        shadow,
        opacity,
        time: time ? time : [current_time, duration],
        position: position ? position : [0, 0],
        size: new_size,
        is_aspect_ratio_locked,
        is_locked,
        layer_name,
        is_loop,
    }
    if (has_max) {
        new_lottie.max = duration
    }
    return { new_lottie, id }
}

export const createBoxObject = ({
    background = '#0082ff',
    border = 0,
    border_color = '#000000',
    text = 'Square',
    border_radius = 'none',
    is_aspect_ratio_locked = false,
    animation = 'none',
    animation_speed = 1,
    animation_state = 'enter',
    shadow = 0,
    layer_name = `element_${Date.now()}`,
    filter = '',
    svg = '',
    is_locked = false,
    is_hidden = false,
    time,
    duration,
    current_time = 0,
    size,
}) => {
    const id = getId()
    const new_box = {
        id,
        text,
        type: 'box',
        position: [0, 0],
        background,
        shadow,
        border_radius,
        opacity: 1,
        size: size ? size : [300, 300, DEFAULT_TRANSFORM_X, DEFAULT_TRANSFORM_Y, DEFAULT_ROTATE],
        is_aspect_ratio_locked,
        border,
        border_color,
        filter,
        layer_name,
        svg,
        is_locked,
        is_hidden,
        animation_speed,
        animation_state,
        animation,
        time: time ? time : [current_time, duration],
    }

    return { id, new_box }
}

export const createQrCodeObject = ({
    layer_name = `qr_code_${Date.now()}`,
    url = '',
    time,
    animation = 'none',
    animation_speed = 1,
    animation_state = 'enter',
    duration,
    current_time = 0,
}) => {
    const id = getId()
    const new_qr_code = {
        layer_name,
        id,
        type: 'qr_code',
        text: 'QR Code',
        position: [0, 0],
        url,
        size: [400, 400, DEFAULT_TRANSFORM_X, DEFAULT_TRANSFORM_Y, DEFAULT_ROTATE],
        is_aspect_ratio_locked: true,
        animation,
        animation_speed,
        animation_state,
        time: time ? time : [current_time, duration],
    }
    return { new_qr_code, id }
}

export const createAudioObject = ({
    layer_name = `audio_${Date.now()}`,
    url = '',
    time,
    duration,
    current_time = 0,
    text = 'Audio',
}) => {
    const id = getId()
    const new_audio = {
        layer_name,
        id,
        type: 'audio',
        text,
        url,
        time: time ? time : [current_time, duration],
    }
    return { new_audio, id }
}

export const createTextObject = ({
    effect = '',
    gradient = 'linear-gradient(to right, rgb(215, 210, 204) 0%, rgb(48, 67, 82) 100%)',
    hollow_color = 'transparent',
    effect_color = '#F2A063',
    thickness = 4,
    intensity = 0.2,
    text = '',
    font_size = '300',
    font_color = '#000000',
    font_highlight = 'transparent',
    background = '',
    font_style = "'Inter'",
    font_align = 'left',
    position = [0, 0],
    opacity = 1,
    line_height = 1.2,
    font_weight = '',
    border_radius = 'none',
    height,
    width,
    layer_name = '',
    font_spacing = 0,
    is_locked = false,
    is_hidden = false,
    time,
    animation = 'none',
    animation_speed = 1,
    animation_state = 'enter',
    duration,
    current_time = 0,
}) => {
    const id = getId()
    const new_text = {
        id,
        text,
        effect,
        effect_color,
        hollow_color,
        thickness,
        intensity,
        background,
        font_style,
        font_size,
        font_color,
        font_spacing,
        type: 'text',
        font_weight,
        font_italic: '',
        font_align,
        font_highlight,
        position,
        border_radius,
        opacity,
        line_height,
        size: [width, height, DEFAULT_TRANSFORM_X, DEFAULT_TRANSFORM_Y, DEFAULT_ROTATE],
        layer_name,
        is_locked,
        is_hidden,
        animation,
        animation_speed,
        animation_state,
        time: time ? time : [current_time, duration],
    }

    return { new_text, id }
}
