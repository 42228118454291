import { useState, useRef, memo } from 'react'
import styled from '@emotion/styled'
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow } from '@chakra-ui/core'

import { intercom } from '../Track/intercom'
import { Tooltip, Flex } from '../UI'
import { useOnClickOutside } from '../../hooks/use-onClickOutside'
import { PopoverMenuButton } from './popover-menu-button'

const StyledHelp = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 14px;
    width: 24px;
    height: 24px;
    transition: all 0.12s;
    user-select: none;

    &:hover {
        color: var(--hover-grey);
        border: 1px solid var(--hover-grey);
    }
`

export const HelpLink = memo(() => {
    const popover_content_ref = useRef()

    const [is_tooltip_open, setIsTooltipOpen] = useState(false)
    const [is_popover_open, setIsPopoverOpen] = useState(false)

    const help_button_id = 'help'

    useOnClickOutside(popover_content_ref, (e) => {
        if (e.target.id === help_button_id) return
        setIsPopoverOpen(false)
    })

    return (
        <Tooltip is_open={is_tooltip_open && !is_popover_open} placement="bottom" text="Help" zIndex="13">
            <div onMouseEnter={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
                <Popover
                    returnFocusOnClose={false}
                    isOpen={is_popover_open}
                    onClose={() => setIsPopoverOpen(false)}
                    placement="bottom"
                    closeOnBlur={false}
                >
                    <PopoverTrigger>
                        <StyledHelp
                            id={help_button_id}
                            onClick={() => setIsPopoverOpen(!is_popover_open)}
                            aria-label="Help"
                        >
                            ?
                        </StyledHelp>
                    </PopoverTrigger>
                    <PopoverContent
                        gutter={6}
                        zIndex={4}
                        width="162px"
                        style={{ outline: 'none', boxShadow: '0 0 6px rgb(0 0 0 / 18%)', padding: 0 }}
                    >
                        <PopoverArrow />
                        <Flex
                            onClick={() => {
                                setIsPopoverOpen(false)
                                setIsTooltipOpen(false)
                            }}
                            direction="column"
                            ref={popover_content_ref}
                        >
                            <PopoverMenuButton href="https://help.venngage.com/en" target="_blank">
                                Help Center
                            </PopoverMenuButton>
                            <PopoverMenuButton href="https://infograph.venngage.com/help/onboarding" target="_blank">
                                Onboarding Guide
                            </PopoverMenuButton>
                            <PopoverMenuButton href="https://infograph.venngage.com/help/editor" target="_blank">
                                Editor Functions
                            </PopoverMenuButton>
                            <PopoverMenuButton href="https://infograph.venngage.com/blog" target="_blank">
                                Design Blog
                            </PopoverMenuButton>
                            <PopoverMenuButton onClick={intercom.show}>Chat with Us</PopoverMenuButton>
                            <PopoverMenuButton href="mailto:support@venngage.com">Email Us</PopoverMenuButton>
                        </Flex>
                    </PopoverContent>
                </Popover>
            </div>
        </Tooltip>
    )
})
