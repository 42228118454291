export const shared_animations = [
    { value: 'none', display: 'None' },
    { value: 'fade', display: 'Fade' },
    { value: 'pop', display: 'Pop' },
    { value: 'slide', display: 'Slide' },
    { value: 'fadeUp', display: 'Rise' },
    { value: 'fadeDown', display: 'Fall' },
    { value: 'big_in', display: 'Big in' },
    { value: 'smallIn', display: 'Small in' },
    { value: 'slow_grow', display: 'Grow' },
]

export const media_animations = [
    // { value: 'expand', display: 'Center reveal' },
    { value: 'kenBurns', display: 'Pan center' },
    { value: 'kenBurnsTopLeft', display: 'Pan top left' },
    { value: 'kenBurnsTopRight', display: 'Pan top right' },
    { value: 'panOut', display: 'Pan out' },
]

const all_display_names = [...shared_animations, ...media_animations]

export const MAX_SPEED = 2
export const getScaleWithSpeed = (speed) => {
    return MAX_SPEED / speed
}

export const isMediaAnimation = (animation) => {
    if (!animation) return false
    return !!media_animations.find((a) => a.value === animation)
}

export const getAnimationDisplayName = (animation_value) => {
    if (!animation_value || animation_value === 'none') return 'Animate'
    return all_display_names.find((a) => a.value === animation_value)?.display
}
