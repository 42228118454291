export const audio_list = [
    {
        name: 'The intro',
        label: 'Happy',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1628219106/music/intro_yqei2l.mp3',
    },
    {
        name: 'Blue Sky',
        label: 'Chill',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1628219342/music/chill_mw3mzz.mp3',
    },
    {
        name: 'Mahzen',
        label: 'Sad',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955077/music/Mahzen_by_NESKA_wtnczc.mp3',
    },
    {
        name: 'Berlin Dream',
        label: 'Happy chill',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955072/music/Berlin_Dream_ngub8j.mp3',
    },
    {
        name: 'Naya',
        label: 'Tropical Chill',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955073/music/Naya_lkxfnt.mp3',
    },
    {
        name: 'They Say',
        label: 'Chill',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955072/music/They_Say_lyhkkh.mp3',
    },
    {
        name: 'Hangtime',
        label: 'Happy energy',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955071/music/Hangtime_mm7p7c.mp3',
    },
    {
        name: 'Tech House Vibes',
        label: 'Deep house',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955069/music/tech-house-vibes_pmq2of.mp3',
    },
    {
        name: 'Autumn Allure',
        label: 'Classical calmn',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955068/music/autumn_allure_trimmed_zf0rsb.mp3',
    },
    {
        name: 'Neuron Parking',
        label: 'Cozy',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955067/music/Neuron_Parking_jkdlqr.mp3',
    },
    {
        name: 'Good God',
        label: 'Energy',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955062/music/Good_God_qweapb.mp3',
    },
    {
        name: 'Midpoint',
        label: 'Trap',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955061/music/Midpoint_mty8ik.mp3',
    },
    {
        name: 'Waveform',
        label: 'LoFi',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955057/music/waveform_u0yyyz.mp3',
    },
    {
        name: 'Deadfro5h',
        label: 'Happy Electro',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955056/music/Deadfro5h_ragx9h.mp3',
    },
    {
        name: 'Hustle Muscle',
        label: 'energy',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955055/music/Hustle_Muscle_la5dda.mp3',
    },
    {
        name: 'Tinsel and Mistletoe',
        label: 'Holiday',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955050/music/tinsel-and-mistletoe_aldpj4.mp3',
    },
    {
        name: 'Sports Highlights',
        label: 'Rock',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955050/music/sports-highlights_kokzn3.mp3',
    },
    {
        name: 'Spirit in the Woods',
        label: 'Ambient',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955049/music/spirit-in-the-woods_fgcing.mp3',
    },
    {
        name: 'Summer Fun',
        label: 'Cinematic',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955048/music/summer-fun_eahm9q.mp3',
    },
    {
        name: 'Sleepy Cat',
        label: 'Electric house',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955046/music/sleepy-cat_n7nu4v.mp3',
    },
    {
        name: 'Playground fun',
        label: 'Mellow',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955046/music/playground-fun_dbac03.mp3',
    },
    {
        name: 'Raising me Higher',
        label: 'Corporate',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955044/music/raising-me-higher_om1c9r.mp3',
    },
    {
        name: 'Sad Circus',
        label: 'Sad',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955044/music/Sad_Circus_uedqgy.mp3',
    },
    {
        name: 'Feeling Happy',
        label: 'Happy',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955044/music/feeling-happy_elhjkq.mp3',
    },
    {
        name: 'Deep Urban',
        label: 'Upbeat electric',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955044/music/deep-urban_cjpdtl.mp3',
    },
    {
        name: 'Runs',
        label: 'Energy',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955044/music/Runs_xe7vkd.mp3',
    },
    {
        name: 'Holidays',
        label: 'Classical',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955043/music/hollidays_hnuoq5.mp3',
    },
    {
        name: 'Genres House',
        label: 'Electric house',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955041/music/Genres_House_House_ifasr9.mp3',
    },
    {
        name: 'Moon Landing Countdown',
        label: 'Chill',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955040/music/Moon_Landing_Countdown_r8xrav.mp3',
    },
    {
        name: 'Majesty',
        label: 'Electro',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955038/music/_Majesty____Electro-House_Music_for_Your_Projects_kioauv.mp3',
    },
    {
        name: 'Hip hop',
        label: 'Experimental',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955038/music/hip-hop_pcyi6k.mp3',
    },
    {
        name: 'Dreaming Big',
        label: 'Corporate',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955038/music/dreaming-big_mgsytb.mp3',
    },
    {
        name: 'Delightful',
        label: 'Mellow',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955037/music/delightful_cofadh.mp3',
    },
    {
        name: 'Bridge',
        label: 'Corporate',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955037/music/bridge_yflyb4.mp3',
    },
    {
        name: 'Ambisax',
        label: 'Happy',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955036/music/Ambisax_-_Unminus_lvfwaq.mp3',
    },
    {
        name: 'A very happy christmas',
        label: 'Holiday',
        url: 'https://res.cloudinary.com/careersifus-com/video/upload/v1634955035/music/a-very-happy-christmas_oarwmr.mp3',
    },
]

export const AUDIO_CONFIRM = 'Your existing audio will be replaced. Do you want to proceed?'
