import { useEffect } from 'react'

export function useOnDownKeyPress(cb, targ) {
    useEffect(() => {
        function downHandler(e) {
            cb(e.keyCode, e)
        }
        window.addEventListener('keydown', downHandler)

        return () => {
            window.removeEventListener('keydown', downHandler)
        }
    }, [cb])
}

export function useOnUpKey(cb) {
    useEffect(() => {
        function upHandler(e) {
            cb(e.keyCode, e)
        }
        window.addEventListener('keyup', upHandler)

        return () => {
            window.removeEventListener('keyup', upHandler)
        }
    }, [cb])
}
