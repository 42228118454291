import { useSelector, useDispatch } from 'react-redux'
import { useEdit } from './edit-context'
import { useOnPaste } from '../../hooks/use-onPaste'
import {
    getScenes,
    getTimeline,
    updateTimelineItemNoHistory,
    addAssetUploading,
    removeAssetUploading,
    updateTimelineMultipleItemNoHistory,
    getProjectSize,
    getSceneDuration,
    getTotalProjectDuration,
    getActiveSceneIdx,
    addToTimeline,
    addNewScene,
    setActiveScene,
    addMultiple,
    removeFromTimeline,
    getId,
} from './stores/project-reducer'
import { createImageObject, createVideoObject } from './stores/item-factory'
import { getItemCount, getScenesVideoCount, copyScene } from '../../shared-fns/shared-fns'
import {
    fileUpload,
    MAX_FILE_SIZE,
    SIZE_ERROR_TOAST,
    UPLOAD_ERROR_TOAST,
    MAX_FILES_TOAST,
    MAX_AT_A_TIME,
    removeFileExtension,
} from '../../shared-fns/upload-fns'
import { blobToUrl } from '../../shared-fns/media-fns'
import { VERCEL_FUNCTION_URL, MAX_SCENE_DURATION, MAX_VIDEOS_PER_PROJECT } from '../../constants/api'
import { useToast } from '../UI'
import { CLIPBOARD_KEY, SCENE_CLIPBOARD_KEY, MAX_VIDEOS_PER_PROJECT_TOAST } from './edit-config'
import { useTimer } from './edit-context'
import { MAX_SCENE_DURATION_TOAST } from './scenes/scene-helpers'
import { markerUtil } from '../Marker/marker-fns'
import { tracker } from '../Track/track'

function retrieveImageFromClipboardAsBlob(pasteEvent, cb) {
    if (pasteEvent.clipboardData === false) cb(undefined)

    let items = pasteEvent?.clipboardData?.items
    if (items === undefined) cb(undefined)
    if (items.length > MAX_AT_A_TIME) return cb(null, true)

    for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        const { type } = items[i]
        if (type.indexOf('image') === -1 && type.indexOf('video') === -1 && type.indexOf('audio') === -1) continue
        // Retrieve image on clipboard as blob
        const blob = items[i].getAsFile()
        cb(blob)
    }
}

const isCopyItem = (clipboard_text) => {
    if (!clipboard_text) return false
    if (clipboard_text?.includes(CLIPBOARD_KEY)) return true
    return false
}

const isCopyScene = (clipboard_text) => {
    if (!clipboard_text) return false
    if (clipboard_text?.includes(SCENE_CLIPBOARD_KEY)) return true
    return false
}

const getClipboardItem = (id, scenes) => {
    for (let idx = 0; idx < scenes.length; idx++) {
        const scene = scenes[idx]
        const clipboard_item = scene.timeline.find((i) => i.id === id)

        if (clipboard_item) return { clipboard_item, scene_idx: idx }
    }
    return {}
}

const getClipboardId = (clipboard_text) => {
    const arr = clipboard_text.split(CLIPBOARD_KEY)
    if (arr && arr[1]) return arr[1]
    return ''
}

export const ClipboardEvents = () => {
    const canvas_dimensions = useSelector(getProjectSize)
    const timeline = useSelector(getTimeline)
    const duration = useSelector(getSceneDuration)

    const scenes = useSelector(getScenes)
    const active_scene_idx = useSelector(getActiveSceneIdx)
    const project_total_duration = useSelector(getTotalProjectDuration)

    const { timer } = useTimer()
    const toast = useToast()
    const { setActiveItemId } = useEdit()

    const dispatch = useDispatch()

    const onAddItems = (items) => dispatch(addMultiple(items))
    const onRemoveItem = (id) => dispatch(removeFromTimeline(id))

    const onDuplicateScene = (idx) => {
        const new_scene = copyScene(scenes[idx])
        if (project_total_duration + new_scene.duration > MAX_SCENE_DURATION) return toast(MAX_SCENE_DURATION_TOAST)

        const project_total_video_count = getScenesVideoCount(scenes)
        const new_scene_videos = getScenesVideoCount([new_scene])
        if (project_total_video_count + new_scene_videos > MAX_VIDEOS_PER_PROJECT) {
            return toast(MAX_VIDEOS_PER_PROJECT_TOAST)
        }

        const new_scene_idx = idx + 1

        timer.stopContinousPlay()
        timer.shouldReset()

        dispatch(addNewScene({ new_scene, insert_after_idx: new_scene_idx }))
        dispatch(setActiveScene({ active_scene: new_scene_idx }))
    }

    const blobHandler = (asset_obj, file_type) => {
        const layer_name = `${file_type}_` + getItemCount(timeline, file_type)
        const { height, width, url, original_filename, duration: video_duration } = asset_obj

        if (file_type === 'audio') return

        if (file_type === 'video') {
            tracker.uploadVideo()
            const { new_video, id } = createVideoObject({
                layer_name,
                canvas_dimensions,
                videoWidth: width,
                videoHeight: height,
                duration,
                video_duration,
                url,
                text: original_filename,
            })
            dispatch(addAssetUploading({ id }))
            dispatch(addToTimeline(new_video))
            return id
        }

        tracker.uploadImage()
        const { new_image, id } = createImageObject({
            layer_name,
            naturalWidth: width,
            naturalHeight: height,
            url,
            text: original_filename,
            canvas_dimensions,
            duration,
        })
        dispatch(addAssetUploading({ id }))
        dispatch(addToTimeline(new_image))
        return id
    }

    const uploadHandler = (url, id, file_type) => {
        if (file_type === 'video') {
            const image_url = removeFileExtension(url) + '.jpeg'

            dispatch(
                updateTimelineMultipleItemNoHistory([
                    { id, updated_value: url, key: 'url' },
                    { id, updated_value: image_url, key: 'image_url' },
                ])
            )
            return dispatch(removeAssetUploading({ id }))
        }
        dispatch(updateTimelineItemNoHistory({ id, updated_value: url, key: 'url' }))
        dispatch(removeAssetUploading({ id }))
    }

    useOnPaste((paste_event) => {
        const text = paste_event?.clipboardData?.getData('Text')

        if (isCopyItem(text)) {
            const clipboard_items = text.split(',')
            let new_items = []
            let video_count = 0
            const project_video_count = getScenesVideoCount(scenes)

            for (const clipboard_item_text of clipboard_items) {
                const id = getClipboardId(clipboard_item_text)
                if (!id) continue

                const { clipboard_item, scene_idx } = getClipboardItem(id, scenes)
                if (!clipboard_item || !clipboard_item.type) continue

                if (markerUtil.isVideo(clipboard_item)) {
                    video_count++
                    if (project_video_count + video_count > MAX_VIDEOS_PER_PROJECT) {
                        return window.toast(MAX_VIDEOS_PER_PROJECT_TOAST)
                    }
                }

                const layer_name = clipboard_item.layer_name + '_copy'

                const is_same_scene = scene_idx === active_scene_idx
                let time = clipboard_item.time
                if (!is_same_scene) time = [0, scenes[active_scene_idx].duration]

                let position = clipboard_item.position ? clipboard_item.position : null
                if (is_same_scene && clipboard_item.position) {
                    position = [clipboard_item.position[0] + 25, clipboard_item.position[1] + 25]
                }

                new_items.push({
                    ...clipboard_item,
                    is_locked: false,
                    layer_name,
                    time,
                    id: getId(),
                    position,
                })
            }
            if (new_items.length) onAddItems(new_items)
            return
        }

        if (isCopyScene(text)) {
            const scene_text_split = text.split('_')
            const scene_idx = scene_text_split[scene_text_split.length - 1]
            return onDuplicateScene(+scene_idx)
        }

        retrieveImageFromClipboardAsBlob(paste_event, async function (blob, is_error) {
            if (is_error) return toast(MAX_FILES_TOAST)
            if (!blob) return

            const file_type = blob.type?.split('/')[0]
            const fileSize = blob.size / 1024 / 1024 // in MB
            if (fileSize > MAX_FILE_SIZE) return toast(SIZE_ERROR_TOAST)

            const is_video = file_type === 'video'
            if (is_video) {
                const video_count = getScenesVideoCount(scenes)
                if (video_count + 1 > MAX_VIDEOS_PER_PROJECT) return window.toast(MAX_VIDEOS_PER_PROJECT_TOAST)
            }

            let id
            try {
                const { height, width, url, duration } = await blobToUrl(blob, file_type)
                id = blobHandler({ height, width, url, original_filename: blob.name, duration }, file_type)
            } catch (error) {
                console.log('retrieveImageFromClipboardAsBlob error ', error)
                return
            }

            try {
                const res = await fetch(`${VERCEL_FUNCTION_URL}/cloudinary/sign-cloudinary-upload`)
                const { signature, timestamp } = await res.json()
                const file_upload_res = await fileUpload({
                    file: blob,
                    setProgress: () => {},
                    signature,
                    timestamp,
                    type: file_type,
                })
                const { secure_url } = file_upload_res.data
                uploadHandler(secure_url, id, file_type)
            } catch (error) {
                console.log('error uploading paste image: ', error)
                onRemoveItem(id)
                dispatch(removeAssetUploading({ id }))
                toast(UPLOAD_ERROR_TOAST)
                setActiveItemId(null)
            }
        })
    })

    return <div />
}
