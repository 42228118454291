import { gsap } from 'gsap'
import { ExpoScaleEase } from 'gsap/EasePack'
import { IN_RESET_OFFSET, ANIMATION_RESET, IN_VISIBLE_OFFSET, FADE_OUT_OFFSET } from './config'
import { getScaleWithSpeed } from '../../../constants/animations'

gsap.registerPlugin(ExpoScaleEase)

export const fade = ({ target_ref, duration, animation_speed = 1, out }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })

    const out_time_temp = out || typeof out === 'number' ? out : 0.2
    const in_time = 0.5 * animation_speed
    const out_time = out_time_temp * animation_speed
    const idle_time = duration - in_time - out_time - IN_VISIBLE_OFFSET

    tl.to(target, 0, ANIMATION_RESET)
        .to(target, {
            autoAlpha: 0,
            duration: 0,
        })
        .fromTo(
            target,
            { autoAlpha: 0 },
            {
                autoAlpha: 1,
                duration: in_time,
                ease: 'sine.inOut',
                rotation: 0.01,
            }
        )
        .to(target, { duration: idle_time })
        .to(target, { duration: out_time, autoAlpha: 0, force3D: true })
        .to(target, { autoAlpha: 0, duration: 0, x: 0 })

    return [tl, null]
}

export const pop = ({ target_ref, duration, animation_speed = 1, out, is_full_length }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })

    const in_time = 0.4 * animation_speed
    const out_time_temp = out || typeof out === 'number' ? out : 0.3
    const out_time = out_time_temp * animation_speed
    const idle_time = duration - in_time - out_time - IN_RESET_OFFSET - IN_VISIBLE_OFFSET

    tl.to(target, 0.01, { ...ANIMATION_RESET, scale: 0 })
        .fromTo(target, { autoAlpha: 0 }, { autoAlpha: 1, duration: IN_VISIBLE_OFFSET, scale: 0 })
        .fromTo(
            target,
            { scale: 0.5 },
            {
                scale: 1,
                autoAlpha: 1,
                duration: in_time,
                ease: ExpoScaleEase.config(0.5, 1, 'back.out(1)'),
                force3D: true,
                rotation: 0.01,
            }
        )
        .to(target, { duration: idle_time })
        .to(target, { duration: out_time, scale: 0, force3D: true, autoAlpha: 0 })
        .to(target, { autoAlpha: 0, duration: FADE_OUT_OFFSET })

    return [tl, null]
}

export const bigIn = ({ target_ref, duration, animation_speed = 1, out }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })

    const in_time = 0.5 * animation_speed
    const out_time_temp = out || typeof out === 'number' ? out : 0.2
    const out_time = out_time_temp * animation_speed
    const idle_time = duration - in_time - out_time - IN_RESET_OFFSET

    tl.to(target, 0, { ...ANIMATION_RESET, scale: 0 })
        .fromTo(
            target,
            { scale: 2, autoAlpha: 0 },
            {
                scale: 1,
                autoAlpha: 1,
                duration: in_time,
                ease: ExpoScaleEase.config(2, 1, 'power4.in'),
                force3D: true,
                rotation: 0.01,
            }
        )
        .to(target, { duration: idle_time })
        .to(target, { duration: out_time, scale: 0, force3D: true })
        .to(target, { autoAlpha: 0, duration: FADE_OUT_OFFSET })
        .to(target, { autoAlpha: 0, duration: 0, x: 0 })

    return [tl, null]
}

export const smallIn = ({ target_ref, duration, animation_speed, out, is_full_length }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })

    const in_time = 0.4 * animation_speed
    const out_time_temp = out || typeof out === 'number' ? out : 0.2
    const out_time = out_time_temp * animation_speed
    const full_time_offset = is_full_length && !out_time ? 0.05 : 0
    const idle_time = duration - in_time - out_time + full_time_offset

    tl.to(target, 0, { ...ANIMATION_RESET, scale: 0 })
        .fromTo(
            target,
            { scale: 0, autoAlpha: 0 },
            {
                scale: 1,
                autoAlpha: 1,
                duration: in_time,
                ease: ExpoScaleEase.config(2, 1, 'power4.in'),
                force3D: true,
                rotation: 0.01,
            }
        )
        .to(target, { duration: idle_time })
        .to(target, { duration: out_time, scale: 0, force3D: true })
        .to(target, { autoAlpha: 0, duration: FADE_OUT_OFFSET })
        .to(target, { autoAlpha: 0, duration: 0, x: 0 })

    return [tl, null]
}

export const grow = ({ target_ref, duration, animation_speed = 1, out }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })

    const in_time = 0.2 * animation_speed
    const out_time_temp = out || typeof out === 'number' ? out : 0.2
    const out_time = out_time_temp * animation_speed
    const in_visible_scale = 0.2
    // -=0.2
    const early_start_time = 0.2
    const idle_time = duration - in_time - out_time - IN_RESET_OFFSET - in_visible_scale + early_start_time

    tl.to(target, 0.05, ANIMATION_RESET)
        .fromTo(target, { autoAlpha: 0, scale: 1 }, { autoAlpha: 1, scale: 1, duration: in_visible_scale })
        .fromTo(
            target,
            { scale: 1 },
            {
                scale: getScaleWithSpeed(animation_speed),
                duration: in_time + idle_time,
                ease: ExpoScaleEase.config(1.1, 1.5),
                force3D: true,
                rotation: 0.01,
            },
            '-=0.2'
        )
        .to(target, { duration: out_time, autoAlpha: 0, force3D: true })
        .to(target, { autoAlpha: 0, duration: FADE_OUT_OFFSET })
        .to(target, { autoAlpha: 0, duration: 0, x: 0 })

    return [tl, null]
}

export const show = (target_ref) => {
    const target = target_ref.current
    gsap.fromTo(target, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0, display: 'block' })
}

export const hide = (target_ref) => {
    const target = target_ref.current

    gsap.fromTo(target, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0, display: 'block' })
}

export const resetMarker = (target) => {
    gsap.to(target, { autoAlpha: 1, scale: 1, x: 0, y: 0, duration: 0.001 })
}
export const fadeUp = ({ target_ref, duration, animation_speed = 1, out, is_view_start, is_full_length }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })

    const slide_in_time = 0.6 * animation_speed
    const fade_in_time = slide_in_time / 2
    const out_time_temp = out || typeof out === 'number' ? out : 0.3
    const slide_out_time = out_time_temp * animation_speed
    const end_offset = is_full_length && is_view_start ? 0.1 : 0
    const idle_time = duration - slide_in_time - slide_out_time - IN_RESET_OFFSET - IN_VISIBLE_OFFSET - end_offset

    const fade_in_early_start = 0.25 * animation_speed
    tl.to(target, is_view_start ? 0.05 : 0, ANIMATION_RESET)
        .to(target, {
            autoAlpha: 0,
            duration: 0,
        })
        .fromTo(
            target,
            { y: '50%', autoAlpha: 0 },
            {
                y: 0,
                autoAlpha: 0.8,
                duration: slide_in_time,
                ease: 'expo.out',
                force3D: true,
                rotation: 0.01,
            }
        )
        .to(target, { duration: fade_in_time, autoAlpha: 1, ease: 'sine.inOut' }, `-=${fade_in_early_start}`)
        .to(target, { duration: idle_time })
        .to(target, { duration: slide_out_time, y: '50%', autoAlpha: 0, ease: 'sine.out', force3D: true })
        .to(target, { autoAlpha: 0, duration: 0, y: 0 })

    return [tl, null]
}

export const fadeDown = ({ target_ref, duration, animation_speed = 1, out, start, is_view_start, is_full_length }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })

    const slide_in_time = 0.6 * animation_speed
    const fade_in_time = slide_in_time / 2
    const out_time_temp = out || typeof out === 'number' ? out : 0.3
    const slide_out_time = out_time_temp * animation_speed
    const end_offset = is_full_length && is_view_start ? 0.1 : 0
    const idle_time = duration - slide_in_time - slide_out_time - IN_VISIBLE_OFFSET - end_offset

    const fade_in_early_start = 0.25 * animation_speed

    tl.to(target, is_view_start ? 0.05 : 0, ANIMATION_RESET).to(target, {
        autoAlpha: 0,
        duration: 0,
    })
    tl.fromTo(
        target,
        { y: '-50%', autoAlpha: 0 },
        {
            y: 0,
            autoAlpha: 0.7,
            duration: slide_in_time,
            ease: 'expo.out',
            force3D: true,
            rotation: 0.01,
        }
    )
        .to(target, { duration: fade_in_time, autoAlpha: 1, ease: 'sine.inOut' }, `-=${fade_in_early_start}`)
        .to(target, { duration: idle_time })
        .to(target, { duration: slide_out_time, y: '-50%', autoAlpha: 0, ease: 'sine.out', force3D: true })
        .to(target, { autoAlpha: 0, duration: 0, y: 0 })

    return [tl, null]
}

export const slide = ({ target_ref, duration, animation_speed = 1, out }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })

    const slide_in_time = 0.5 * animation_speed
    const out_time_temp = out || typeof out === 'number' ? out : 0.5
    const slide_out_time = out_time_temp * animation_speed
    const safety_offset = out || typeof out === 'number' ? out : 0.1
    const idle_time = duration - slide_in_time - slide_out_time - IN_RESET_OFFSET - IN_VISIBLE_OFFSET - safety_offset

    tl.to(target, 0, { ...ANIMATION_RESET, x: '-100%' })
        .fromTo(target, { autoAlpha: 0 }, { autoAlpha: 1, duration: IN_VISIBLE_OFFSET })
        .fromTo(
            target,
            { x: '-100%' },
            {
                x: 0,
                duration: slide_in_time,
                ease: 'sine.in',
                force3D: true,
                rotation: 0.01,
            }
        )
        .to(target, { duration: idle_time })
        .to(target, { duration: slide_out_time, x: '100%', ease: 'sine.in', force3D: true })
        .to(target, { autoAlpha: 0, duration: FADE_OUT_OFFSET })
        .to(target, { autoAlpha: 0, duration: 0, x: '-100%' })

    return [tl, null]
}
