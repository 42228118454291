export const SUBSCRIPTION_TYPES = {
    FREE: 'Free',
    PREMIUM: 'Premium',
    BUSINESS: 'Business',
}

export const VENNGAGE_SUBSCRIPTION_PLAN_MAPPING = {
    FREE: 'FREE',
    PREMIUM: 'PREMIUM',
    EDUCATION: 'EDUCATION',
    EDUCATION_MEMBER: 'EDUCATION_MEMBER',
    BUSINESS: 'BUSINESS',
    BUSINESS_MEMBER: 'BUSINESS_MEMBER',
    ENTERPRISE: 'ENTERPRISE',
}
