import { forwardRef, memo } from 'react'
// eslint-disable-next-line no-unused-vars
import LottiePlayerBase from '@lottiefiles/lottie-player'

export const LottiePlayer = memo(
    forwardRef(({ src, loop = true, autoplay = null, renderer = 'svg' }, ref) => {
        return (
            <lottie-player
                ref={ref}
                autoplay={autoplay}
                loop={loop}
                mode="normal"
                src={src}
                background="transparent"
                style={{ width: '100%', height: '100%' }}
                renderer={renderer}
            ></lottie-player>
        )
    })
)
