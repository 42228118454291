import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Icon } from '@chakra-ui/core'
import { Modal } from '../modal'
import { Flex, Box } from '../stack'
import { Input } from '../input'
import { Button } from '../button'
import { Text, Label, TextLink } from '../text'
import { ImageLoader } from '../image-loader'
import { Spinner } from '../spinner'

import { ReactComponent as NoResultsFoundIcon } from '../../../assets/icons/no_results_found.svg'

export const selected_style = {
    color: 'var(--main)',
    fontWeight: 'bold',
    bg: 'var(--main-light)',
    borderLeft: '4px solid var(--main)',
    boxShadow: 'none',
}

export const IconTab = ({ logo, name, p = '4px' }) => {
    return (
        <>
            <Box mr="12px" p={p} borderRadius="4px" bg="white">
                {logo}
            </Box>
            {name}
        </>
    )
}

export const ImageGridLayout = styled.div`
    margin-top: 8px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: minmax(50px, 250px);

    .image-item {
        content-visibility: auto;
    }
    .image-item:nth-of-type(5n) {
        grid-column-end: span 2;
    }
`

export const BaseModal = ({ closeModal, isOpen, children }) => {
    return (
        <Modal
            classNames={{
                modal: 'no-padding',
            }}
            animationDuration={1}
            open={isOpen}
            onClose={closeModal}
            center
        >
            <Box width="840px" borderRadius="md">
                <Box height="100%" width="100%">
                    {children}
                </Box>
            </Box>
        </Modal>
    )
}

export const SearchForm = ({ query, onSearch, setQuery, placeholder, idx, active_idx }) => {
    const input_ref = useRef()

    useEffect(() => {
        input_ref.current.focus()
    }, [])

    return (
        <Flex
            as="form"
            onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onSearch(e)
            }}
            mb={3}
            mt="0.3rem"
        >
            <Input
                has_small_text={false}
                id={`image_input_${idx}`}
                ref={input_ref}
                className="no-global-key"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={placeholder}
                borderRadius="md"
                m={2}
                width="80%"
            />
            <Button type="submit" mt={2} variantColor="main" aria-label="Search">
                <Icon name="search-2" />
            </Button>
        </Flex>
    )
}

export const NoResultsFound = ({ header = 'No results found', text = 'Try another search term' }) => (
    <Flex width="100%" height="500px" alignItems="center" justifyContent="center">
        <Box mb="6rem">
            <NoResultsFoundIcon style={{ height: '130px', width: '280px', marginBottom: '2rem' }} />
            <Text textAlign="center" fontWeight="bold">
                {header}
            </Text>
            <Text textAlign="center">{text}</Text>
        </Box>
    </Flex>
)

export const ImageLayout = ({
    gap = '12px',
    label,
    list,
    onClick,
    has_thumbnail = true,
    real_height = 1920,
    real_width = 1920,
    img_height = '60px',
    img_width = '60px',
    filter,
    mb = 4,
    onRemove,
}) => {
    return (
        <>
            {label && <Label>{label}</Label>}
            <Box mb={mb} width="fit-content" position="relative">
                <Flex style={{ gap }} borderWidth="1px" rounded="lg" p={2} justifyContent="center" flexWrap="wrap">
                    {list.map((i, idx) => {
                        return (
                            <Box
                                key={i.url}
                                cursor="pointer"
                                onClick={(e) => {
                                    onClick({
                                        item: i,
                                        url: i.url,
                                        height: real_height,
                                        width: real_width,
                                        text: label,
                                        e,
                                        original_filename: i.original_filename,
                                    })
                                }}
                            >
                                <ImageLoader
                                    onClick={() => {}}
                                    width={img_width}
                                    height={img_height}
                                    url={has_thumbnail ? i.thumbnail : i.url}
                                    style={{ filter, border: '1px solid var(--grey-2)' }}
                                />
                            </Box>
                        )
                    })}
                </Flex>
            </Box>
        </>
    )
}

export const Loader = () => (
    <Flex width="100%" height="500px" alignItems="center" justifyContent="center">
        <Spinner />
    </Flex>
)

export const Attribution = ({ link, name }) => (
    <Flex height="10%" width="100%" pt="2px">
        <TextLink
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="12px"
            mr="4px"
            color="var(--grey-1)"
            href={link}
            isExternal
        >
            By {name}
        </TextLink>
    </Flex>
)
