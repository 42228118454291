import { useState, useRef, useCallback, useEffect } from 'react'
import { ImageLoader } from './image-loader'
import { debounce } from '../../shared-fns/shared-fns'
import { Box, Flex } from './stack'
import { Spinner } from './spinner'

const STATUS = {
    SHOW_IMAGE: 'show_image',
    SHOW_VIDEO: 'show_video',
}

const VIDEO_STATUS = {
    LOADING: 'loading',
    FINISHED_LOADING: 'video_loaded',
    INITIAL_HIDDEN: 'hidden',
}

const image_style = {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    borderRadius: '4px',
}
export const TemplateLoader = ({
    onClickVideo = () => {},
    video_url,
    image_url,
    hover_wait_to_load_video_ms = 100,
    vid_el_style = {},
    is_hover_trigger_from_outside,
}) => {
    const [status, setStatus] = useState(STATUS.SHOW_IMAGE)
    const [video_status, setVideoStatus] = useState(VIDEO_STATUS.INITIAL_HIDDEN)

    const hover_status = useRef('')
    const video_ref = useRef('')

    const onLoadVideo = () => {
        if (hover_status.current) {
            setStatus(STATUS.SHOW_VIDEO)
            if (video_status === VIDEO_STATUS.INITIAL_HIDDEN) {
                setVideoStatus(VIDEO_STATUS.LOADING)
            }
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedLoadVideo = useCallback(debounce(onLoadVideo, hover_wait_to_load_video_ms), [video_status])

    useEffect(() => {
        if (status === STATUS.SHOW_VIDEO && video_status !== VIDEO_STATUS.LOADING) {
            if (video_ref && video_ref.current) video_ref.current.play()
        }
    }, [status, video_status])

    const image_z_index = status === STATUS.SHOW_IMAGE || video_status === VIDEO_STATUS.LOADING ? 2 : 0

    const loadVideo = () => {
        hover_status.current = 'true'
        debouncedLoadVideo()
    }

    const resetToImage = () => {
        if (video_ref && video_ref.current) {
            video_ref.current.currentTime = 0
            video_ref.current.pause()
        }
        hover_status.current = ''
        setStatus(STATUS.SHOW_IMAGE)
    }

    useEffect(() => {
        if (typeof is_hover_trigger_from_outside !== 'boolean') return

        if (is_hover_trigger_from_outside) loadVideo()
        else resetToImage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_hover_trigger_from_outside])

    return (
        <Flex
            position="relative"
            height="100%"
            width="100%"
            justify="center"
            align="center"
            onMouseEnter={loadVideo}
            onMouseLeave={resetToImage}
        >
            {video_status === VIDEO_STATUS.LOADING && status === STATUS.SHOW_VIDEO && (
                <Spinner zIndex={3} position="absolute" />
            )}
            <Box width="100%" height="100%" position="absolute" zIndex={image_z_index} background="var(--grey-2)">
                <ImageLoader
                    cursor="pointer"
                    alt="Hollow"
                    width="100%"
                    height="100%"
                    style={image_style}
                    url={image_url}
                />
            </Box>
            <Box width="100%" height="100%" position="absolute" zIndex={1}>
                {video_status !== VIDEO_STATUS.INITIAL_HIDDEN && (
                    <Flex background="var(--grey-2)" height="100%" width="100%" justify="center" align="center">
                        <video
                            muted
                            ref={video_ref}
                            onClick={onClickVideo}
                            autoPlay={false}
                            controls={false}
                            onCanPlay={() => setVideoStatus(VIDEO_STATUS.FINISHED_LOADING)}
                            style={vid_el_style}
                            crossOrigin="anonymous"
                            playsInline
                            key={video_url}
                        >
                            <source src={video_url} type="video/mp4" />
                        </video>
                    </Flex>
                )}
            </Box>
        </Flex>
    )
}
