import { init, track, identify } from '@chamaeleonidae/chmln'

const is_production = process.env.NODE_ENV === 'production'
const CHAMELEON_KEY = 'SchyGsFJrdzWLLeuFI79t92yMGxdQpOv9yPQqEuI7rIWdP-1OY0fJ-E2Tcg1oNT8CuU7Gb'

class Chameleon {
    boot = (email, user_id) => {
        if (is_production) {
            init(CHAMELEON_KEY)
            track('Load Video')

            if (!!window.chmln) {
                identify(user_id, { email })
            }
        }
    }
}

export const chameleon = new Chameleon()
