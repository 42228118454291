import { useCallback, useEffect } from 'react'
import { useTimerOnPlay, useTimerOnStop, useTimerOnReset } from '../Timer/timer-hooks'
import { memo } from '../../shared-fns/shared-fns'
// <----t--> (time - 4)
//     <--> (video_start - 3)
// => 1
export const videoCurrentTime = memo((t, video_start, duration_s, is_loop = true) => {
    const pin_time = t / 1000
    if (!duration_s) return pin_time - +video_start

    const pin_to_start = pin_time - +video_start
    const factor = is_loop ? Math.floor(pin_to_start / duration_s) : 0
    const loop_start = video_start + duration_s * factor

    const new_start = pin_time - loop_start

    return new_start
})

// 1. normal timeline plays     --- animation + video play
// 2. pause and playing         --- pause video
// 3. normal timeline exits     --- animation + pause
// 4. click timeline            --- seek to that position
// 5. click timeline click play --- play without
//    or pause play
// 6. Slider is resized         --- seek to correct time
// 7. Scene time is changed
//    and pin is outside

export const useVideoTimeline = ({ timer, video_duration_ms, item, setVideoTime, playVideo, pauseVideo }) => {
    const onTimerStart = useCallback(
        (ms) => {
            const t = video_duration_ms - ms
            setVideoTime(t)
            const start = videoCurrentTime(t, item.time[0])
            if (start < 0) return
            playVideo()
        },
        [video_duration_ms, setVideoTime, item.time, playVideo]
    )

    const onTimerReset = useCallback(
        (ms) => {
            const t = video_duration_ms - ms
            setVideoTime(t)
            if (timer.isPlaying()) playVideo()
        },
        [video_duration_ms, setVideoTime, timer, playVideo]
    )

    useTimerOnPlay(onTimerStart, timer)
    useTimerOnStop(pauseVideo, timer)
    useTimerOnReset(onTimerReset, timer)
    useEffect(() => {
        const t = video_duration_ms - timer.getMs()
        setVideoTime(t)
    }, [setVideoTime, timer, video_duration_ms])
}
