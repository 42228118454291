import { useRef, useState, useCallback, useEffect } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { withHistory } from 'slate-history'
import { debounce } from '../../../shared-fns/shared-fns'
import { KEY } from '../../../constants/keyboard'
import { REDUX_HISTORY_UNDO_REDO } from '../../../constants/events'
import { SELECT_ALL_NEW_TEXT_KEY } from '../../../constants/project'

import { MarkerBase, getMarkerTimeMs } from '../marker-base'
import { getTextStyle, parseText, Leaf, TEXT_STATE, getSlateEl, selectAllText, setCursorAtEnd } from './text-helpers'

const SLATE_STYLE = { margin: '6px' }
export const TextMarker = ({
    item,
    video_duration_ms,
    timer,
    text_state,
    onDeselectText,
    onUpdateFontSize,
    onUpdateSizeFromTextLayerTyping,
    onUpdateText,
    is_active_item,
}) => {
    const [key, setKey] = useState(0)

    useEffect(() => {
        const resetText = () => setKey((key) => key + 1)
        window.addEventListener(REDUX_HISTORY_UNDO_REDO, resetText)
        return () => window.removeEventListener(REDUX_HISTORY_UNDO_REDO, resetText)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <TextEditor
            item={item}
            video_duration_ms={video_duration_ms}
            timer={timer}
            text_state={text_state}
            onDeselectText={onDeselectText}
            onUpdateFontSize={onUpdateFontSize}
            onUpdateSizeFromTextLayerTyping={onUpdateSizeFromTextLayerTyping}
            onUpdateText={onUpdateText}
            is_active_item={is_active_item}
            key={key}
        />
    )
}

const TextEditor = ({
    item,
    video_duration_ms,
    timer,
    text_state,
    onDeselectText,
    onUpdateFontSize,
    onUpdateSizeFromTextLayerTyping,
    onUpdateText,
    is_active_item,
}) => {
    const { time, animation } = item
    const [start_ms, end_ms] = getMarkerTimeMs(time)
    const [editor] = useState(() => withHistory(withReact(createEditor())))
    const [value, setValue] = useState(parseText(item.text))

    const resizeMarkerBox = () => {
        const slate_el = getSlateEl(item.id)
        if (!slate_el) return

        if (slate_el.clientHeight > item.size[1]) {
            const height = slate_el.clientHeight - item.size[1]
            onUpdateSizeFromTextLayerTyping({ height })
        }

        if (slate_el.clientHeight < item.size[1]) {
            const height = slate_el.clientHeight - item.size[1]
            onUpdateSizeFromTextLayerTyping({ height })
        }

        if (slate_el.clientWidth > item.size[0]) {
            const width = slate_el.clientWidth - item.size[0]
            onUpdateSizeFromTextLayerTyping({ width })
        }
    }
    const debouncedSaveText = debounce((content) => onUpdateText(item.id, content), 50)

    useEffect(() => {
        if (text_state !== TEXT_STATE.EDIT) {
            ReactEditor.deselect(editor)
            onDeselectText()
            const content = JSON.stringify(value)
            debouncedSaveText(content)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text_state])

    const debouncedResizeMarkerBox = debounce(resizeMarkerBox, 25)
    const onChangeText = (new_value) => {
        setValue(new_value)
        debouncedResizeMarkerBox()
    }

    return (
        <Slate editor={editor} value={value} onChange={onChangeText}>
            {text_state === TEXT_STATE.EDIT && (
                <SlateEditor item={item} text_state={text_state} editor={editor} value={value} />
            )}
            {text_state !== TEXT_STATE.EDIT && (
                <MarkerBase
                    item={item}
                    video_duration_ms={video_duration_ms}
                    timer={timer}
                    animation={animation}
                    start_ms={start_ms}
                    end_ms={end_ms}
                >
                    <TextView text={value} item={item} editor={editor} />
                </MarkerBase>
            )}
        </Slate>
    )
}

const SlateEditor = ({ item, text_state, editor, value }) => {
    const editor_box_ref = useRef()

    useEffect(() => {
        if (text_state === TEXT_STATE.EDIT) {
            ReactEditor.focus(editor)

            const new_text_id = window.localStorage.getItem(SELECT_ALL_NEW_TEXT_KEY)
            if (new_text_id === item.id) {
                localStorage.removeItem(SELECT_ALL_NEW_TEXT_KEY)
                return selectAllText(editor)
            }

            return setCursorAtEnd(editor)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text_state])

    const stopBubbling = (e) => e.stopPropagation()

    const { text_style, background } = getTextStyle(item)

    const onKeyDown = (e) => {
        const is_ctrl = e.metaKey || e.ctrlKey
        if (is_ctrl) {
            if (e.keyCode === KEY.Z) {
                editor.undo()
            }
            if (e.keyCode === KEY.Y) {
                editor.redo()
                e.preventDefault()
            }
        }
    }

    const renderLeaf = useCallback((props) => <Leaf {...props} background_color={background} />, [background])

    return (
        <div
            onKeyDown={stopBubbling}
            onClick={stopBubbling}
            onMouseDown={stopBubbling}
            id={'ed-' + item.id}
            ref={editor_box_ref}
            style={text_style}
        >
            <Editable
                key={value}
                id={'slate-' + item.id}
                onKeyDown={onKeyDown}
                onBlur={(e) => {
                    console.log('blur')
                    e.preventDefault()
                }}
                renderLeaf={renderLeaf}
                style={SLATE_STYLE}
                spellCheck="false"
            />
        </div>
    )
}

const TextView = ({ item, text, editor }) => {
    const { text_style, background } = getTextStyle(item)

    const renderLeaf = useCallback((props) => <Leaf {...props} background_color={background} />, [background])

    return (
        <div id={'ed-' + item.id} style={text_style}>
            <Editable id={'slate-' + item.id} readOnly renderLeaf={renderLeaf} style={SLATE_STYLE} />
        </div>
    )
}

export const ViewOnlyTextMarker = ({ item, video_duration_ms, timer }) => {
    const { time, animation } = item
    const [start_ms, end_ms] = getMarkerTimeMs(time)

    return (
        <MarkerBase
            item={item}
            video_duration_ms={video_duration_ms}
            timer={timer}
            animation={animation}
            start_ms={start_ms}
            end_ms={end_ms}
        >
            <TextLayout item={item} />
        </MarkerBase>
    )
}

export const TextLayout = ({ item, has_dynamic_text_update = true }) => {
    const [value, setValue] = useState(parseText(item.text))
    const [editor] = useState(() => withReact(createEditor()))

    const { text_style, background } = getTextStyle(item)

    useEffect(() => {
        if (has_dynamic_text_update) {
            const new_value = parseText(item.text)
            editor.children = new_value
            setValue(new_value)
        }
    }, [item.text, editor, has_dynamic_text_update])

    const renderLeaf = useCallback((props) => <Leaf {...props} background_color={background} />, [background])

    return (
        <div style={text_style}>
            <Slate editor={editor} value={value}>
                <Editable readOnly renderLeaf={renderLeaf} style={SLATE_STYLE} spellCheck="false" />
            </Slate>
        </div>
    )
}
