import { Editor, Transforms } from 'slate'
import { HOLLOW, GLOW, SHADOW, GRADIENT } from '../../../constants/effects'

export const Leaf = ({ attributes, children, leaf, background_color }) => {
    return (
        <span {...attributes} style={{ backgroundColor: background_color }}>
            {children}
        </span>
    )
}

export const getTextEffect = (item) => {
    const effect = {
        color: item.font_color,
        WebkitTextStroke: '',
        textShadow: '',
        background: item.background,
    }
    if (!item.effect) return effect

    if (item.effect === HOLLOW) {
        effect.WebkitTextStroke = item.thickness ? `${item.thickness}px ${item.font_color}` : `4px ${item.font_color}`
        effect.color = item.hollow_color || 'transparent'
    }

    if (item.effect === GLOW) {
        effect.color = 'white'
        const intensity = item.intensity || 0
        effect.textShadow = `
            0 0 ${7 * intensity}px #fff,
            0 0 ${10 * intensity}px #fff,
            0 0 ${21 * intensity}px #fff,
            0 0 ${42 * intensity}px ${item.font_color},
            0 0 ${82 * intensity}px ${item.font_color},
            0 0 ${92 * intensity}px ${item.font_color},
            0 0 ${102 * intensity}px ${item.font_color},
            0 0 ${151 * intensity}px ${item.font_color}
        `
    }

    if (item.effect === SHADOW) {
        const thickness = item.thickness || 0
        const effect_color = item.effect_color || '#F2A063'
        effect.textShadow = `
            ${0.8 * thickness}px ${0.8 * thickness}px  ${effect_color},
            ${1.1 * thickness}px ${1.1 * thickness}px  ${effect_color}
        `
    }

    if (item.effect === GRADIENT) {
        effect.background = item.gradient || 'linear-gradient(to right, rgb(215, 210, 204) 0%, rgb(48, 67, 82) 100%)'
        effect.WebkitBackgroundClip = 'text'
        effect.WebkitTextFillColor = 'transparent'
    }

    return effect
}

const align_map = {
    right: 'end',
    center: 'center',
    left: 'start',
}

export const parseText = (text) => {
    try {
        const json_text = JSON.parse(text)
        if (json_text && typeof json_text === `object`) {
            return json_text
        }
    } catch (error) {}

    return [
        {
            type: 'paragraph',
            children: [{ text: text }],
        },
    ]
}

export const getTextStyle = (item) => {
    const { color, WebkitTextStroke, textShadow, background, WebkitTextFillColor } = getTextEffect(item)

    const text_style = {
        lineHeight: item.line_height,
        fontSize: item.font_size + 'px',
        fontFamily: item.font_style,
        color,
        WebkitTextStroke,
        textShadow,
        WebkitTextFillColor,
        fontWeight: item.font_weight,
        letterSpacing: item.font_spacing,
        opacity: item.opacity,
        textAlign: align_map[item.font_align],
        caretColor: color,
        // offset drag control borders
        height: '100%',
        width: '100%',
    }

    return { text_style, background }
}

export const TEXT_STATE = {
    DEFAULT: 'DEFAULT',
    ACTIVE: 'ACTIVE',
    EDIT: 'EDIT',
}

export const getSlateEl = (id) => document.getElementById('slate-' + id)
export const getTextEditorBoxEl = (id) => document.getElementById('ed-' + id)

export const selectAllText = (editor) => {
    Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
    })
}

export const setCursorAtEnd = (editor) => {
    Transforms.select(editor, Editor.end(editor, []))
}

export const getScaledFontSize = (size, id) => {
    const editor_box = getTextEditorBoxEl(id)
    const slate_el = getSlateEl(id)

    if (!slate_el || !editor_box) return
    if (!size) return

    // 1. binary search close enough
    let max = 600
    let min = 5
    let mid

    while (min <= max) {
        mid = (min + max) >>> 1

        editor_box.style.fontSize = mid + 'px'
        if (size[1] === slate_el.clientHeight) break

        if (size[1] > slate_el.clientHeight) {
            min = mid + 1
        } else {
            max = mid - 1
        }
    }

    if (!mid) return

    // 2. Adjust for small overflow
    let scaled_font_size = mid
    const min_fs = 5

    while (slate_el.clientWidth > size[0] || slate_el.clientHeight > size[1]) {
        const fs_dec = scaled_font_size - 2
        editor_box.style.fontSize = fs_dec + 'px'
        scaled_font_size = fs_dec

        if (scaled_font_size <= min_fs) break
    }

    return scaled_font_size
}
