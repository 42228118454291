import { useState, memo, useCallback, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import { Flex, Box } from './stack'
import { Popover } from './popover'
import { Label } from './text'
import { Input } from './input'
import { debounce } from '../../shared-fns/shared-fns'
import { Button } from './button'

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as Forbidden } from '../../assets/icons/forbidden.svg'

const BaseColorBox = styled.div`
    width: 25px;
    height: 25px;
    margin-right: 6px;
    margin-bottom: 6px;
    border-radius: 6px;
    border: none;
    transition: all 0.25s ease 0s;
    background: ${(props) => `${props.bg_color}`};
    background-image: ${(props) => `${props.bg_image}`};
    border: ${(props) => (props.is_active ? '2px solid rgb(181, 183, 185)' : '')};
    padding: ${(props) => (props.is_active ? '0' : '4px')};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`

const ColorBoxStyle = styled(BaseColorBox)`
    &:hover {
        cursor: pointer;
        filter: brightness(85%);
    }
`

const ColorBox = memo(({ bg_color, bg_image, is_active, onClick, style }) => {
    return (
        <ColorBoxStyle style={style} bg_color={bg_color} bg_image={bg_image} onClick={onClick} is_active={is_active} />
    )
})

const isGradient = (color) => color && (color.includes('linear') || color.includes('gradient'))

export const ColorPicker = ({ onSelect, selected, has_transparent, is_disabled, has_label }) => {
    return (
        <Flex wrap="wrap">
            <ChromePickerBox
                selected={selected}
                onSelect={onSelect}
                has_transparent={has_transparent}
                is_disabled={is_disabled}
                has_label={has_label}
            />
        </Flex>
    )
}

const DEFAULT_COLORS = ['#0D0E0D', '#FFFFFF', '#FF5757', '#5271FF', '#FFDE59']
const CHECKERED_BACKGROUND = 'repeating-conic-gradient(var(--grey-7) 0% 25%, transparent 0% 50%)  50% / 15px 15px'

const ChromePickerBox = ({ selected, onSelect, has_transparent, is_disabled, has_label = true }) => {
    const [is_popover_open, setIsPopoverOpen] = useState(false)

    const onSetColor = (c) => {
        if (c === '#NaNNaNNaN') return
        onSelect(c)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetColor = useCallback(debounce(onSetColor, 50), [onSelect])

    return (
        <Box>
            <Popover
                isOpen={is_popover_open}
                positions={['top', 'right', 'bottom']}
                onClickOutside={() => setIsPopoverOpen(false)}
                content={
                    <div>
                        <Flex
                            shadow="md"
                            bg="white"
                            border="1px solid var(--grey-2)"
                            m="0.5rem"
                            p="6px"
                            borderRadius="6px"
                            onKeyDown={(e) => e.stopPropagation()}
                            align="center"
                            direction="column"
                        >
                            <HexColorPicker color={selected} onChange={debouncedSetColor} />
                            <Flex wrap="wrap" justify="center" pt="16px" width="210px">
                                {has_transparent && (
                                    <Box onClick={() => onSelect('transparent')}>
                                        <ColorBoxStyle
                                            style={{
                                                background:
                                                    'repeating-conic-gradient(var(--grey-7) 0% 25%, transparent 0% 50%)  50% / 10px 10px',
                                            }}
                                            key="transparent"
                                            is_active={selected === '' || selected === 'transparent'}
                                        />
                                    </Box>
                                )}
                                {DEFAULT_COLORS.map((color) => {
                                    const is_active = color === selected
                                    return (
                                        <Box key={color} onClick={() => onSelect(color)}>
                                            <ColorBox is_active={is_active} bg_color={color} />
                                        </Box>
                                    )
                                })}
                            </Flex>
                            <Box p="8px" position="relative">
                                <p style={{ position: 'absolute', top: '12px', left: '14px', color: 'var(--grey-6)' }}>
                                    #
                                </p>
                                <HexColorInput
                                    style={{
                                        border: '1px solid var(--grey-2)',
                                        padding: '4px 6px 4px 25px',
                                        fontSize: '14px',
                                        width: '100%',
                                        outline: 'blue',
                                    }}
                                    color={selected}
                                    onChange={(c) => {
                                        onSelect(c)
                                    }}
                                />
                            </Box>
                        </Flex>
                    </div>
                }
            >
                <Flex
                    as="button"
                    align="center"
                    border="1px solid var(--grey-2)"
                    cursor={is_disabled ? '' : 'pointer'}
                    borderRadius="6px"
                    backgroundColor={is_disabled ? 'var(--grey-2)' : ''}
                    onClick={() => {
                        if (is_disabled) return
                        setIsPopoverOpen(!is_popover_open)
                    }}
                >
                    {!is_disabled && (
                        <div
                            style={{
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                                background: selected && selected !== 'transparent' ? selected : CHECKERED_BACKGROUND,
                                borderRadius: '6px',
                                width: '30px',
                                height: '30px',
                            }}
                        />
                    )}
                    {is_disabled && (
                        <Flex align="center" justift="center" w="30px" h="30px">
                            <Forbidden />
                        </Flex>
                    )}
                    {has_label && (
                        <Label
                            mt="0"
                            style={{
                                color: 'var(--grey-6)',
                                paddingLeft: '6px',
                                paddingRight: '12px',
                                textTransform: 'uppercase',
                                minWidth: '75px',
                                maxWidth: '75px',
                                width: '75px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            {isGradient(selected) ? 'Gradient' : selected}
                        </Label>
                    )}
                </Flex>
            </Popover>
        </Box>
    )
}

const OVERLAYS = [
    'linear-gradient(to bottom, transparent 0%, black 100%)',
    'linear-gradient(0deg, #00000088 30%, #ffffff44 100%)',
    'linear-gradient( rgba(235, 135, 101, 0.70), rgba(156, 154, 254, 0.70), rgba(253, 173, 232, 0.70))',
]

const GRADIENTS = [
    'linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114))',
    'radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% )',
]

const ALL_GRADIENTS = [...GRADIENTS, ...OVERLAYS]

export const GradientPicker = ({
    onSelect,
    selected,
    isOverlay,
    custom_gradient_list = ALL_GRADIENTS,
    has_transparent,
    has_custom_gradient = true,
}) => {
    const gradient_list = isOverlay ? OVERLAYS : custom_gradient_list

    return (
        <Flex wrap="wrap">
            {has_transparent && (
                <ColorBoxStyle
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background:
                            'repeating-conic-gradient(var(--grey-7) 0% 25%, transparent 0% 50%)  50% / 10px 10px',
                    }}
                    key="transparent"
                    is_active={selected === '' || selected === 'transparent'}
                    onClick={() => onSelect('transparent')}
                />
            )}
            {gradient_list.map((color) => {
                const is_active = color === selected
                return (
                    <Box key={color} onClick={() => onSelect(color)}>
                        <ColorBox is_active={is_active} bg_color={color} />
                    </Box>
                )
            })}
            {has_custom_gradient && <CustomGradientPicker onSelect={onSelect} />}
        </Flex>
    )
}

const Link = styled.span`
    color: var(--main);

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`

const SAMPLE_GRADIENT = 'linear-gradient(rgba(60, 140, 231, 0.5) 10%, #00eaff 100%)'
const SAMPLE_RGBA = 'rgba(200, 0, 0, 0.5)'
const SEMI_COLON_REGEX = /[;]+/g
const removeSemicolon = (string) => string.replace(SEMI_COLON_REGEX, '')

export const CustomGradientPicker = ({ onSelect }) => {
    const [is_popover_open, setIsPopoverOpen] = useState(false)
    const [gradient, setGradient] = useState('')
    const input_ref = useRef()

    const onChangeGradient = (new_gradient) => {
        const format = new_gradient.replace('background-image:', '').trim()
        setGradient(removeSemicolon(format))
    }

    useEffect(() => {
        if (is_popover_open) input_ref.current.focus()
    }, [is_popover_open])

    return (
        <Box>
            <Popover
                isOpen={is_popover_open}
                positions={['right']}
                onClickOutside={() => setIsPopoverOpen(false)}
                content={
                    <Box
                        shadow="md"
                        bg="white"
                        border="1px solid var(--grey-2)"
                        m="0.5rem"
                        p="12px"
                        borderRadius="6px"
                        onKeyDown={(e) => e.stopPropagation()}
                        width="fit-content"
                        height="210px"
                    >
                        <Label
                            mt="0"
                            mb="0"
                            style={{ textTransform: 'unset', color: 'black', fontWeight: 'bold', fontSize: '14px' }}
                        >
                            Custom gradient
                        </Label>
                        <Label
                            mt="0"
                            mb="0.7rem"
                            style={{ fontSize: '10px', fontWeight: 'unset', textTransform: 'unset' }}
                        >
                            (CSS <span>background</span> property)
                        </Label>
                        <Flex align="center" mb="6px">
                            <BaseColorBox
                                style={{ width: '40px', height: '35px', marginBottom: '0px' }}
                                bg_color={gradient}
                            />
                            <Input
                                maxlength="2000"
                                width="100%"
                                size="sm"
                                value={gradient}
                                onChange={(e) => onChangeGradient(e.target.value)}
                                ref={input_ref}
                            />
                        </Flex>
                        <Label style={{ fontSize: '12px', textTransform: 'unset' }} mt="8px">
                            E.g. <Link onClick={() => setGradient(SAMPLE_GRADIENT)}>{SAMPLE_GRADIENT}</Link>
                        </Label>
                        <Label style={{ fontSize: '12px', textTransform: 'unset' }} mt="0">
                            Or <Link onClick={() => setGradient(SAMPLE_RGBA)}>{SAMPLE_RGBA}</Link>
                        </Label>
                        <Label style={{ fontSize: '12px', textTransform: 'unset' }} mt="0">
                            Find more examples{' '}
                            <Link
                                as="a"
                                rel="noreferrer noopener"
                                target="_blank"
                                // href="https://hexcolor.co/gradient-colors"
                                href="https://gradienthunt.com/"
                            >
                                here
                            </Link>
                        </Label>
                        <Flex>
                            <Button
                                onClick={() => {
                                    let new_gradient = removeSemicolon(gradient)
                                    onSelect(new_gradient)
                                    setIsPopoverOpen(false)
                                }}
                                isDisabled={!gradient}
                                size="sm"
                                variantColor="main"
                                mt="0.7rem"
                                float="right"
                            >
                                Add
                            </Button>
                        </Flex>
                    </Box>
                }
            >
                <ColorBoxStyle
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    border="1px solid var(--grey-2)"
                    onClick={() => setIsPopoverOpen(!is_popover_open)}
                >
                    <EditIcon style={{ strokeWidth: '2', stroke: 'var(--grey-5)' }} />
                </ColorBoxStyle>
            </Popover>
        </Box>
    )
}
