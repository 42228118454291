import styled from '@emotion/styled'

import { useState, memo } from 'react'
import { useTimerOnPlay, useTimerOnStop } from './timer-hooks'
import { Button, Tooltip } from '../UI'

import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg'
import { ReactComponent as PauseIcon } from '../../assets/icons/pause.svg'

export const PlayPauseIcon = memo(({ timer, playVideo }) => {
    const [is_playing, setIsPlaying] = useState(false)

    useTimerOnPlay(() => setIsPlaying(true), timer)
    useTimerOnStop(() => setIsPlaying(false), timer)

    return (
        <Tooltip placement="top" text={is_playing ? 'Pause' : 'Play'} keycode={['space']}>
            <Button
                className="no-global-click"
                borderRadius="50%"
                width="2rem"
                height="2rem"
                paddingLeft="0"
                paddingRight="0"
                variantColor="main"
                onClick={playVideo}
                size="sm"
                id="play-btn"
                aria-label={is_playing ? 'Pause Video' : 'Play Video'}
                style={{ boxShadow: 'none !important' }}
            >
                {is_playing ? (
                    <PauseIcon onClick={playVideo} style={{ fill: '#fff', pointerEvents: 'none' }} />
                ) : (
                    <PlayIcon onClick={playVideo} style={{ fill: '#fff', pointerEvents: 'none' }} id="play-icon" />
                )}
            </Button>
        </Tooltip>
    )
})

const EditorPlayButton = styled(Button)`
    background-color: #5e5e66;
    transition: opacity 0.2s;

    &:hover {
        background-color: #5e5e66;
        opacity: 0.7;
    }
`

export const EditorPlayPauseIcon = memo(({ timer, playVideo }) => {
    const [is_playing, setIsPlaying] = useState(false)

    useTimerOnPlay(() => setIsPlaying(true), timer)
    useTimerOnStop(() => {
        if (timer.sceneState === 'continous_play') return
        setIsPlaying(false)
    }, timer)

    return (
        <Tooltip placement="top" text={is_playing ? 'Pause' : 'Play'} keycode={['space']}>
            <EditorPlayButton
                className="no-global-click"
                boxShadow="lg"
                borderRadius="50%"
                width="45px"
                height="45px"
                paddingLeft="0"
                paddingRight="0"
                onClick={playVideo}
                id="play-btn"
                aria-label={is_playing ? 'Pause Video' : 'Play Video'}
                style={{
                    outline: 'none',
                    boxShadow: 'none',
                    filter: 'drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25))',
                }}
            >
                {is_playing ? (
                    <PauseIcon
                        onClick={playVideo}
                        style={{
                            fill: '#B9B9B9',
                            stroke: '#B9B9B9',
                            width: '22px',
                            height: '18px',
                            pointerEvents: 'none',
                        }}
                    />
                ) : (
                    <PlayIcon
                        onClick={playVideo}
                        style={{
                            fill: '#B9B9B9',
                            stroke: '#B9B9B9',
                            width: '22px',
                            height: '18px',
                            pointerEvents: 'none',
                        }}
                        id="play-icon"
                    />
                )}
            </EditorPlayButton>
        </Tooltip>
    )
})
