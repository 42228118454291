import { useRef } from 'react'
import { getMediaAnimationIn, reset } from '../Marker/animations/previews'
import { Button } from './button'
import { Flex, Box } from './stack'
import { ImageLoader } from './image-loader'
import { Label } from './text'
import { shared_animations, media_animations } from '../../constants/animations.js'

const AnimatedButton = ({ is_active, animation, initialFocusRef, onUpdateAnimation }) => {
    return (
        <Button
            overflow={animation.value === 'slide' || animation.value === 'slidingUp' ? 'hidden' : ''}
            onClick={() => onUpdateAnimation(animation.value)}
            ref={initialFocusRef}
            size="sm"
            mr="8px"
            mt="8px"
            px={4}
            is_active={is_active}
            variant="outline"
            style={{ boxShadow: 'none' }}
        >
            <div>{animation.display}</div>
        </Button>
    )
}

export const AnimationSelect = ({ selected_animation, onUpdateAnimation, initialFocusRef, hide = [], p = 2 }) => {
    return (
        <Flex flexWrap="wrap" p={p}>
            {shared_animations
                .filter((a) => !hide.includes(a.value))
                .map((animation) => {
                    const is_active = animation.value === selected_animation
                    return (
                        <AnimatedButton
                            onUpdateAnimation={onUpdateAnimation}
                            initialFocusRef={is_active ? initialFocusRef : null}
                            animation={animation}
                            key={animation.value}
                            is_active={is_active}
                        />
                    )
                })}
        </Flex>
    )
}

const AnimatedImageButton = ({ onUpdateAnimation, animation, is_active }) => {
    const animationIn = getMediaAnimationIn(animation.value)
    const animation_ref = useRef()
    const is_playing = useRef(false)

    return (
        <Box mb="0.5rem" mr="0.5rem" overflow="hidden" width="fit-content" height="fit-content">
            <Label mt="0">{animation.display}</Label>
            <Box
                borderRadius="6px"
                role="button"
                onClick={(e) => onUpdateAnimation(animation.value)}
                width="auto"
                height="90px"
                border={is_active ? '2px solid var(--main)' : ''}
                overflow="hidden"
                bg="black"
                onMouseEnter={() => {
                    if (is_playing.current) return
                    is_playing.current = true
                    animationIn(animation_ref.current, () => {
                        is_playing.current = false
                        reset(animation_ref.current)
                    })
                }}
            >
                <Box ref={animation_ref} height="90px" width="auto">
                    <ImageLoader
                        height="90px"
                        style={{ height: '90px', filter: 'var(--grey-scale-filter)' }}
                        url="https://res.cloudinary.com/oskarahl/image/upload/v1603773766/unsplash_thumbnails/oskar_ahlroth_pglo5z.jpg"
                    />
                </Box>
            </Box>
        </Box>
    )
}

export const MediaAnimationSelect = ({ selected_animation, onUpdateAnimation, initialFocusRef, hide = [], p = 2 }) => {
    return (
        <Flex flexWrap="wrap" p={p}>
            {media_animations
                .filter((a) => !hide.includes(a.value))
                .map((animation) => {
                    const is_active = animation.value === selected_animation
                    return (
                        <AnimatedImageButton
                            onUpdateAnimation={onUpdateAnimation}
                            initialFocusRef={is_active ? initialFocusRef : null}
                            animation={animation}
                            key={animation.value}
                            is_active={is_active}
                        />
                    )
                })}
        </Flex>
    )
}
