import { useState } from 'react'
import { Skeleton } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { play, load } from '../../shared-fns/media-fns'

const Video = styled.video`
    border-radius: 6px;
    object-fit: cover;
    &:hover {
        cursor: pointer;
    }
`

export const VideoLoader = ({
    url,
    width,
    height,
    poster,
    skeleton_style,
    has_controls = true,
    muted = false,
    autoPlay,
    has_hover_play,
    onClick,
    vid_el_style = {},
}) => {
    const [is_loading, setIsLoading] = useState(true)

    return (
        <Skeleton
            style={skeleton_style}
            width={width + 'px'}
            height={height ? height + 'px' : 'auto'}
            isLoaded={!is_loading}
        >
            <Video
                muted={muted}
                onClick={onClick}
                onMouseEnter={(e) => {
                    if (has_hover_play) play(e)
                }}
                onMouseLeave={(e) => {
                    if (has_hover_play) load(e)
                }}
                autoPlay={autoPlay}
                controls={has_controls}
                onLoadedMetadata={() => {
                    setIsLoading(false)
                }}
                loop
                width={width}
                height={height}
                poster={poster}
                style={vid_el_style}
                crossOrigin="anonymous"
                playsInline
            >
                <source src={url} type="video/mp4" />
            </Video>
        </Skeleton>
    )
}
