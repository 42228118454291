const isText = (item) => item && item.type === 'text'
const isImage = (item) => item && item.type === 'image'
const isBox = (item) => item && item.type === 'box'
const isQRCode = (item) => item && item.type === 'qr_code'
const isLottie = (item) => item && item.type === 'lottie'
const isVideo = (item) => item && item.type === 'video'
const isAudio = (item) => item && item.type === 'audio'

export const markerUtil = {
    isText,
    isImage,
    isBox,
    isQRCode,
    isLottie,
    isVideo,
    isAudio,
}
