import { useCallback, useRef, useState, useEffect } from 'react'
import { Skeleton } from '@chakra-ui/core'
import { MarkerBase, getMarkerTimeMs } from './marker-base'
import { getBorderRadius, getShadowFilter } from '../Marker/canvas-base'
import { videoCurrentTime, useVideoTimeline } from '../Marker/use-video-timeline'
import { downloadVimeoVideo } from '../../shared-fns/media-fns'

export const VideoMarker = ({ item, timer, video_duration_ms, saveTotalDurationTemp, has_vimeo_cache = false }) => {
    const video_ref = useRef()
    const { url, animation, border_radius, opacity, filter, id, shadow, total_video_duration_s } = item
    const [start_ms, end_ms] = getMarkerTimeMs(item.time)

    const pauseVideo = () => video_ref.current?.pause()
    const playVideo = () => video_ref.current?.play()

    const setVideoTime = useCallback(
        async (t) => {
            if (!video_ref || !video_ref.current || !video_ref.current.duration) return

            const start = videoCurrentTime(t, item.time[0], video_ref?.current.duration)
            video_ref.current.currentTime = start + 0.001
        },
        [item.time]
    )
    useVideoTimeline({ timer, video_duration_ms, item, setVideoTime, playVideo, pauseVideo })

    return (
        <MarkerBase
            item={item}
            video_duration_ms={video_duration_ms}
            timer={timer}
            animation={animation}
            markerAnimationIn={(t) => {
                setVideoTime(t)
                if (timer.isPlaying()) playVideo()
            }}
            markerAnimationOut={(t) => {
                pauseVideo()
            }}
            start_ms={start_ms}
            end_ms={end_ms}
        >
            <VideoLayout
                shadow={shadow}
                filter={filter}
                opacity={opacity}
                id={id}
                border_radius={border_radius}
                url={url}
                video_ref={video_ref}
                saveTotalDurationTemp={saveTotalDurationTemp}
                total_video_duration_s={total_video_duration_s}
                has_vimeo_cache={has_vimeo_cache}
            />
        </MarkerBase>
    )
}

export const VideoLayout = ({
    url,
    video_ref,
    id,
    border_radius,
    opacity,
    filter,
    shadow,
    saveTotalDurationTemp,
    total_video_duration_s,
    has_vimeo_cache,
}) => {
    const [is_loading, setIsLoading] = useState(true)
    const [video_id, setId] = useState('')
    const [video_url, setVideoUrl] = useState(window.vimeo_video_cache_map[url] || url)

    useEffect(() => {
        const locallyDownloadPexelVideo = async () => {
            const b64_video = await downloadVimeoVideo(url)
            window.vimeo_video_cache_map[url] = b64_video
            setVideoUrl(b64_video)
        }

        if (window.vimeo_video_cache_map[url]) return setVideoUrl(window.vimeo_video_cache_map[url])
        if (!has_vimeo_cache || !url.includes('vimeo')) return setVideoUrl(url)

        locallyDownloadPexelVideo()
    }, [url, has_vimeo_cache])

    // DOM Ids can't start with numbers in puppeteer
    // Has to be the same as on server
    useEffect(() => {
        if (!video_url) setIsLoading(false)

        const { current } = video_ref
        const finishedLoading = () => setIsLoading(false)
        current.addEventListener('canplay', finishedLoading)

        return () => current.removeEventListener('canplay', finishedLoading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video_url])

    return (
        <Skeleton
            fadeInDuration={0}
            isLoaded={!is_loading}
            style={{ filter: getShadowFilter(shadow) }}
            height="100%"
            width="100%"
        >
            <video
                playsInline
                muted
                id={video_id}
                x-webkit-airplay="deny"
                disablePictureInPicture={true}
                loop={true}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    outline: border_radius !== 'full' && '1px solid transparent',
                    border: border_radius !== 'full' && '1px solid transparent',
                    borderRadius: getBorderRadius(border_radius),
                    opacity,
                    filter,
                }}
                ref={video_ref}
                key={url}
                onCanPlay={() => setId('id' + id)}
                onLoadedMetadata={(e) => {
                    if (e.target && !total_video_duration_s && saveTotalDurationTemp) {
                        saveTotalDurationTemp(id, e.target.duration)
                    }
                }}
            >
                <source src={video_url || url} />
            </video>
        </Skeleton>
    )
}
