import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addToTimeline, getSceneDuration, getProjectSize, updateMultipleTimelineItem } from '../stores/project-reducer'
import { VideoSelectModal } from '../../UI'
import { useModalStore } from '../modal-context'
import { createVideoObject, createLottieObject } from '../stores/item-factory'
import { useEdit } from '../edit-context'
import { markerUtil } from '../../Marker/marker-fns'

const getSelection = (replace_item) => {
    if (replace_item) {
        if (markerUtil.isVideo(replace_item)) return ['giphy', 'pexels']
        if (markerUtil.isLottie(replace_item)) return ['lottie']
    }
    return ['lottie', 'giphy', 'pexels']
}

export const VideoSelectModalWrapper = () => {
    const [replace_block_offset, setReplaceBlockOffset] = useState(false)
    const { is_video_select_open, closeVideoSelectModal, replace_item } = useModalStore()

    const duration = useSelector(getSceneDuration)
    const canvas_dimensions = useSelector(getProjectSize)
    const dispatch = useDispatch()
    const { setActiveItemId } = useEdit()

    const selection = getSelection(replace_item)

    const onNewVideo = ({
        url,
        videoWidth,
        videoHeight,
        duration: video_duration,
        text,
        image_url = '',
        is_full_duration,
    }) => {
        if (replace_item) {
            if (replace_block_offset) return
            const { id } = replace_item
            dispatch(
                updateMultipleTimelineItem([
                    { id, updated_value: url, key: 'url' },
                    { id, updated_value: video_duration, key: 'total_video_duration_s' },
                    { id, updated_value: image_url, key: 'image_url' },
                ])
            )
            setActiveItemId(id)
        } else {
            const { new_video } = createVideoObject({
                layer_name: 'video_' + Date.now(),
                canvas_dimensions,
                videoWidth,
                videoHeight,
                duration,
                video_duration,
                url,
                text,
                image_url,
                time: is_full_duration ? [0, duration] : null,
            })
            dispatch(addToTimeline(new_video))
        }

        closeVideoSelectModal()
    }

    const onClickLottie = ({ url }) => {
        if (replace_item) {
            if (replace_block_offset) return
            const { id } = replace_item
            dispatch(updateMultipleTimelineItem([{ id, updated_value: url, key: 'url' }]))
        } else {
            const { new_lottie } = createLottieObject({
                layer_name: 'lottie_' + Date.now(),
                canvas_dimensions,
                videoWidth: 600,
                videoHeight: 600,
                duration,
                url,
                text: 'My lottie',
            })
            dispatch(addToTimeline(new_lottie))
        }

        closeVideoSelectModal()
    }

    useEffect(() => {
        if (is_video_select_open && replace_item && replace_item.type) {
            setReplaceBlockOffset(true)
            setTimeout(() => setReplaceBlockOffset(false), 500)
        } else {
            setReplaceBlockOffset(false)
        }
    }, [is_video_select_open, replace_item])

    return (
        <VideoSelectModal
            isOpen={is_video_select_open}
            closeModal={closeVideoSelectModal}
            config={{
                is_replace: !!replace_item,
                selection,
                onNewVideo,
                onClickLottie,
                onClickGif: onNewVideo,
            }}
        />
    )
}
