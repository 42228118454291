import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    addToTimeline,
    updateTimelineItem,
    getProjectSize,
    getTimeline,
    getSceneDuration,
} from '../stores/project-reducer'
import {
    Button,
    useToast,
    Input,
    ImageLoader,
    Box,
    Text,
    ClickText,
    Modal,
    Flex,
    Tooltip,
    VideoLoader,
    LottiePlayer,
} from '../../UI'
import { Icon } from '@chakra-ui/core'
import { isValidImage, isValidVideo, isValidLottie } from '../../../shared-fns/media-fns'
import { getItemCount } from '../../../shared-fns/shared-fns'
import { useEdit } from '../edit-context'
import { createImageObject, createVideoObject, createLottieObject } from '../stores/item-factory'
import { useModalStore } from '../modal-context'

const SAMPLE_IMAGE = 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2'
const ERROR_LINK_TOAST = {
    title: 'Unable to load',
    description: 'Make sure it ends with a file extension such as .jpeg or .mp4',
    status: 'error',
}

export const LinkUploadModal = () => {
    const { is_link_upload_open } = useModalStore()
    if (is_link_upload_open) return <LinkUploadModalBase />
    return null
}

export const LinkUploadModalBase = () => {
    const input_ref = useRef()

    const [url, setUrl] = useState()
    const [link_state, setLinkState] = useState()
    const [image_metadata, setImageMetaData] = useState({})
    const [video_metadata, setVideoMetaData] = useState({})
    const [lottie_metadata, setLottieMetaData] = useState({})

    const { is_link_upload_open, closeLinkUploadModal, replace_item } = useModalStore()
    const { setActiveItemId } = useEdit()

    const canvas_dimensions = useSelector(getProjectSize)
    const timeline = useSelector(getTimeline)
    const duration = useSelector(getSceneDuration)

    const toast = useToast()
    const dispatch = useDispatch()

    useEffect(() => {
        if (is_link_upload_open) {
            setActiveItemId(null)
            setVideoMetaData({})
            setImageMetaData({})
            setLottieMetaData({})
            setLinkState('')
            setUrl('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_link_upload_open])

    const replaceVideoOrImage = ({ url, text }) => {
        const { id } = replace_item
        dispatch(updateTimelineItem({ id, updated_value: url, key: 'url' }))
        setActiveItemId(id)
    }

    const onPasteUrl = async (e, is_sample) => {
        // try image
        // show preview
        // click add to project
        const url = is_sample ? SAMPLE_IMAGE : e.clipboardData.getData('Text')
        setLinkState('loading')

        if (isValidLottie(url)) {
            setLottieMetaData({ url })
            return setLinkState('preview_lottie')
        }

        const [image_obj, video_obj] = await Promise.all([isValidImage(url), isValidVideo(url)])

        const { is_valid_image, naturalWidth, naturalHeight } = image_obj

        if (is_valid_image) {
            setImageMetaData({ naturalWidth, naturalHeight, url })
            return setLinkState('preview_image')
        }

        const { is_valid_video, videoWidth, videoHeight, duration } = video_obj
        if (is_valid_video) {
            setVideoMetaData({ videoWidth, videoHeight, duration, url })
            return setLinkState('preview_video')
        }

        toast(ERROR_LINK_TOAST)
        setLinkState('')
    }

    const onAddNewAsset = (e) => {
        e.preventDefault()

        if (link_state === 'preview_lottie') {
            const { new_lottie } = createLottieObject({
                layer_name: 'lottie_' + Date.now(),
                canvas_dimensions,
                videoWidth: 600,
                videoHeight: 600,
                duration,
                url,
                text: 'My lottie',
            })
            dispatch(addToTimeline(new_lottie))
            return closeLinkUploadModal()
        }

        if (link_state === 'preview_image') {
            const { naturalWidth, naturalHeight } = image_metadata

            if (replace_item && replace_item.type) {
                replaceVideoOrImage({ url, text: url })
                closeLinkUploadModal()
                return
            }
            const layer_name = 'url_image_' + getItemCount(timeline, 'image')
            const { new_image } = createImageObject({
                layer_name,
                naturalWidth,
                naturalHeight,
                url,
                text: url,
                canvas_dimensions,
                duration,
            })
            dispatch(addToTimeline(new_image))
            return closeLinkUploadModal()
        }

        if (link_state === 'preview_video') {
            const { videoWidth, videoHeight, duration: video_duration } = video_metadata

            if (replace_item && replace_item.type) {
                replaceVideoOrImage({ url, duration, text: url })
                closeLinkUploadModal()
                return
            }

            const { new_video } = createVideoObject({
                layer_name: 'video_' + Date.now(),
                canvas_dimensions,
                videoWidth,
                videoHeight,
                duration,
                video_duration,
                url,
                text: url,
            })
            dispatch(addToTimeline(new_video))
            return closeLinkUploadModal()
        }
    }

    return (
        <>
            <Modal animationDuration={1} open={is_link_upload_open} onClose={closeLinkUploadModal} center>
                <Box as="form" width="780px" borderRadius="md" onSubmit={onAddNewAsset}>
                    <Box mb={4}>
                        <Text fontWeight="bold">Paste a URL</Text>
                        <Text color="var(--grey-5)" fontWeight="normal" fontSize="sm">
                            From the internets{' '}
                            <span role="img" aria-label="Paste image from url">
                                🌎
                            </span>
                        </Text>
                    </Box>
                    <Box>
                        {link_state === 'preview_lottie' && <LottiePreview lottie_metadata={lottie_metadata} />}
                        {link_state === 'preview_image' && (
                            <ImageLoader mb={4} url={image_metadata.url} height="200px" width="300px" />
                        )}
                        {link_state === 'preview_video' && (
                            <Box mb={4} width="300px" height="fit-content">
                                <VideoLoader
                                    muted
                                    loop
                                    vid_el_style={{ width: '100%', height: 'auto', maxHeight: '400px' }}
                                    url={video_metadata.url}
                                />
                            </Box>
                        )}
                        <Input
                            ref={input_ref}
                            id="link-upload-input"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            onPaste={onPasteUrl}
                            placeholder="www.image.jpeg"
                            has_small_text={false}
                        />
                        <Text pt="0.5rem" fontSize="sm" color="var(--grey-5)">
                            Or try this:{' '}
                            <ClickText
                                style={{ wordWrap: 'break-word' }}
                                onClick={(e) => {
                                    setUrl(SAMPLE_IMAGE)
                                    onPasteUrl(e, true, SAMPLE_IMAGE)
                                }}
                            >
                                {SAMPLE_IMAGE}
                            </ClickText>
                        </Text>
                    </Box>
                    <Flex justify="flex-end" mt="2rem">
                        <Button variant="ghost" mr={3} onClick={closeLinkUploadModal}>
                            Close
                        </Button>
                        <Button
                            isDisabled={
                                link_state !== 'preview_image' &&
                                link_state !== 'preview_video' &&
                                link_state !== 'preview_lottie'
                            }
                            isLoading={link_state === 'loading'}
                            variantColor="main"
                            type="submit"
                        >
                            Add
                        </Button>
                    </Flex>
                </Box>
            </Modal>
        </>
    )
}

const LottiePreview = ({ lottie_metadata }) => {
    const [show_preview, setShowPreview] = useState('')

    useEffect(() => {
        setShowPreview(false)
        setTimeout(() => setShowPreview(true), 300)
    }, [lottie_metadata])

    return (
        <Box height="200px" width="300px">
            {show_preview && <LottiePlayer autoplay loop src={lottie_metadata.url} />}
        </Box>
    )
}

export const LinkModalButton = () => {
    const { openLinkUpload } = useModalStore()

    return (
        <Tooltip placement="bottom" text="Open" keycode={['L']}>
            <Button size="sm" mt={2} variant="outline" onClick={openLinkUpload}>
                <Icon style={{ pointerEvents: 'none' }} size="16px" name="link" />{' '}
                <span style={{ paddingLeft: '6px' }}>Paste URL</span>
            </Button>
        </Tooltip>
    )
}
