import React from 'react'
import { Input as BaseInput } from '@chakra-ui/core'

export const Input = React.forwardRef(({ children, has_small_text = true, ...props }, ref) => (
    <BaseInput
        autoComplete="off"
        data-lpignore="true"
        data-form-type="other"
        ref={ref}
        fontSize={has_small_text && '12px'}
        backgroundColor="var(--input-bg)"
        {...props}
    >
        {children}
    </BaseInput>
))
