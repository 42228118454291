import { memo, useState, useRef } from 'react'
import styled from '@emotion/styled'
import { Avatar } from '@chakra-ui/core'
import { Tooltip, Flex } from '../UI'
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow } from '@chakra-ui/core'

import { Box } from '../UI'
import { useUser } from '../Query/use-user'
import { INFOGRAPH_URL } from '../../constants/auth'
import { useAuth } from '../Session'
import { useOnClickOutside } from '../../hooks/use-onClickOutside'
import { PopoverMenuButton } from './popover-menu-button'

const AvatarStyled = styled(Avatar)`
    &:hover {
        cursor: pointer;
        border: 1px solid var(--main);
    }
`

export const UserAvatar = memo(() => {
    const popover_content_ref = useRef()
    const { signOut } = useAuth()

    const [is_tooltip_open, setIsTooltipOpen] = useState(false)
    const [is_popover_open, setIsPopoverOpen] = useState(false)

    const { data: db_user } = useUser()
    const { photoURL = '', email, first_name = '', last_name } = db_user
    const name = first_name && last_name ? `${first_name} ${last_name}` : email

    useOnClickOutside(popover_content_ref, (e) => {
        if (e.target.ariaLabel === name) return
        setIsPopoverOpen(false)
    })

    return (
        <Box>
            <Tooltip is_open={is_tooltip_open && !is_popover_open} placement="bottom" text="Account" zIndex="13">
                <Box onMouseEnter={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
                    <Popover
                        returnFocusOnClose={false}
                        isOpen={is_popover_open}
                        onClose={() => setIsPopoverOpen(false)}
                        placement="bottom-end"
                        closeOnBlur={false}
                    >
                        <PopoverTrigger>
                            <Box id="avatar-id">
                                <AvatarStyled
                                    onClick={() => setIsPopoverOpen(!is_popover_open)}
                                    as="button"
                                    size="sm"
                                    name={name}
                                    src={photoURL}
                                    aria-label="Account"
                                />
                            </Box>
                        </PopoverTrigger>
                        <PopoverContent
                            gutter={2}
                            zIndex={4}
                            width="121px"
                            style={{ outline: 'none', boxShadow: '0 0 6px rgb(0 0 0 / 18%)', padding: 0 }}
                        >
                            <PopoverArrow />
                            <Flex
                                onClick={() => {
                                    setIsPopoverOpen(false)
                                    setIsTooltipOpen(false)
                                }}
                                direction="column"
                                ref={popover_content_ref}
                            >
                                <PopoverMenuButton href={`${INFOGRAPH_URL}/account/profile`}>
                                    My Account
                                </PopoverMenuButton>
                                <PopoverMenuButton onClick={signOut} href={`${INFOGRAPH_URL}/logout`}>
                                    Sign Out
                                </PopoverMenuButton>
                            </Flex>
                        </PopoverContent>
                    </Popover>
                </Box>
            </Tooltip>
        </Box>
    )
})
