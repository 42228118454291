import { Box } from '../UI'
import QRCode from 'qrcode.react'
import { MarkerBase, getMarkerTimeMs } from './marker-base'

export const QRCodeMarker = ({ item, video_duration_ms, timer }) => {
    const { url, time, animation, opacity } = item

    const [start_ms, end_ms] = getMarkerTimeMs(time)
    return (
        <MarkerBase
            item={item}
            video_duration_ms={video_duration_ms}
            timer={timer}
            animation={animation}
            start_ms={start_ms}
            end_ms={end_ms}
        >
            <QRCodeLayout url={url} opacity={opacity} />
        </MarkerBase>
    )
}

export const QRCodeLayout = ({ url, opacity }) => {
    return (
        <Box style={{ opacity }} width="100%" height="100%">
            <QRCode value={url} width="100%" height="100%" renderAs="svg" />
        </Box>
    )
}
