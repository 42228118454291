import { gsap } from 'gsap'
import { ExpoScaleEase } from 'gsap/EasePack'
import { IN_RESET_OFFSET, ANIMATION_RESET, IN_VISIBLE_OFFSET } from './config'
import { getScaleWithSpeed } from '../../../constants/animations'

gsap.registerPlugin(ExpoScaleEase)

export const expand = ({ target_ref, duration }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })

    const in_time = 0.5
    const out_time = 0.3
    const idle_time = duration - in_time - out_time

    tl.to(target, IN_RESET_OFFSET, { ...ANIMATION_RESET, width: '0%' })
        .fromTo(target, { autoAlpha: 0, width: '0%' }, { autoAlpha: 1, width: '0%', duration: IN_VISIBLE_OFFSET })
        .fromTo(
            target,
            { width: '0%', marginLeft: '50%' },
            {
                width: '100%',
                force3D: true,
                rotation: 0.01,
                marginLeft: 0,
                duration: in_time,
                ease: 'sine.inOut',
            }
        )
        .to(target, { duration: idle_time })
        .fromTo(
            target,
            { marginLeft: 0, width: '100%' },
            { duration: out_time, width: 0, marginLeft: '50%', force3D: true, rotation: 0.01 }
        )
        .to(target, { autoAlpha: 0, duration: 0, width: 0, marginLeft: '0%' })

    return [tl, null]
}

export const kenBurns =
    (direction) =>
    ({ target_ref, duration, start, is_full_length, animation_speed = 1 }) => {
        const target = target_ref.current
        const tl = gsap.timeline({ paused: true })
        const scale_end = getScaleWithSpeed(animation_speed)

        if (start !== 0) {
            gsap.to(target, { autoAlpha: 0, scale: 1, x: 0, y: 0, rotation: 0, rotationY: 0, duration: 0 })
        }
        if (start === 0) {
            gsap.to(target, { autoAlpha: 1, scale: 1, x: 0, y: 0, rotation: 0, rotationY: 0, duration: 0 })
        }
        tl.to(target, IN_RESET_OFFSET, { ...ANIMATION_RESET, autoAlpha: 1 })
        tl.fromTo(
            target,
            { scale: 1 },
            {
                scale: scale_end,
                transformOrigin: direction,
                duration,
                ease: ExpoScaleEase.config(1, scale_end),
                force3D: true,
                rotation: 0.01,
            },
            '-=0.02'
        )

        if (!is_full_length) {
            tl.to(target, { duration: 0, autoAlpha: 0, force3D: true })
        }

        return [tl, null]
    }

export const panOut = ({ target_ref, duration, start, is_full_length, animation_speed = 1 }) => {
    const target = target_ref.current
    const tl = gsap.timeline({ paused: true })
    const scale_start = getScaleWithSpeed(animation_speed)

    if (start !== 0) {
        gsap.to(target, { autoAlpha: 0, scale: scale_start, x: 0, y: 0, rotation: 0, rotationY: 0, duration: 0 })
    }
    if (start === 0) {
        gsap.to(target, { autoAlpha: 1, scale: scale_start, x: 0, y: 0, rotation: 0, rotationY: 0, duration: 0 })
    }
    tl.to(target, IN_RESET_OFFSET, { ...ANIMATION_RESET, scale: scale_start, autoAlpha: 1 })
    tl.fromTo(
        target,
        { scale: scale_start },
        {
            scale: 1,
            transformOrigin: 'center',
            duration,
            ease: ExpoScaleEase.config(scale_start, 1),
            force3D: true,
            rotation: 0.01,
        },
        '-=0.02'
    )

    if (!is_full_length) {
        tl.to(target, { duration: 0, autoAlpha: 0, force3D: true })
    }

    return [tl, null]
}
