import clonedeep from 'lodash.clonedeep'
import uuid from 'react-uuid'
import { createBoxObject } from '../Edit/stores/item-factory'
import { markerUtil } from '../Marker/marker-fns'

export const makeOneSceneProject = (project) => {
    const cloned_project = clonedeep(project)
    const new_scene = {
        timeline: [],
        aspect_ratio: [...cloned_project.scenes[0].aspect_ratio],
    }

    let start = 0
    cloned_project.scenes.forEach((scene, idx) => {
        const { new_box } = createBoxObject({
            layer_name: Date.now(),
            background: scene.background,
            time: [start, scene.duration + start],
            size: [scene.aspect_ratio[0], scene.aspect_ratio[1], 0, 0, 0],
        })

        new_scene.timeline.push(new_box)

        scene.timeline.forEach((layer) => {
            if (!markerUtil.isAudio(layer)) {
                const original_time = [...layer.time]

                layer.time = [original_time[0] + start, original_time[1] + start]
                if (
                    layer.time[0] === start &&
                    layer.animation &&
                    (layer.animation.includes('kenBurns') || layer.animation.includes('panOut'))
                ) {
                    const adjust_start = layer.time[0] - 0.02
                    layer.time[0] = adjust_start
                }

                layer.id = uuid()
                if (layer.time[0] === 0 || layer.time[0] < 0) layer.is_view_start = true

                new_scene.timeline.push(layer)
            }
        })

        start += scene.duration
    })

    if (cloned_project.audio_timeline) {
        cloned_project.audio_timeline.forEach((audio_layer) => {
            new_scene.timeline.push(audio_layer)
        })
    }

    new_scene.duration = start
    cloned_project.scenes = [new_scene]

    return cloned_project
}
