import { useEffect, useState } from 'react'
import { getAllFonts, loadStyleSheets, isEmptyObject } from '../shared-fns/shared-fns'

// either project itself or array of fonts
export const useLoadFonts = ({ project, fonts_arr = [] }) => {
    const [font_id, setFontId] = useState('')
    const [status, setStatus] = useState('loading')

    useEffect(() => {
        if (!project || isEmptyObject(project)) return

        const all_fonts = getAllFonts(project)
        loadStyleSheets(all_fonts).then(() => {
            setFontId('all_fonts_loaded')
            setStatus('')
        })
    }, [project])

    useEffect(() => {
        if (!fonts_arr || !fonts_arr.length) return
        loadStyleSheets(fonts_arr).then(() => {
            setFontId('all_fonts_loaded')
            setStatus('')
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { font_id, is_fonts_loading: status === 'loading' }
}

export const LoadFonts = ({ project }) => {
    const { font_id } = useLoadFonts({ project })
    return <span style={{ position: 'absolute', visibility: 'hidden' }} id={font_id} />
}

export const loadFonts = (project) => {
    if (!project || isEmptyObject(project)) return
    const all_fonts = getAllFonts(project)

    loadStyleSheets(all_fonts)
}

export const getAllProjectsFonts = (projects_arr) => {
    let all_fonts = []
    projects_arr.forEach((p) => {
        const fonts = getAllFonts(p)
        all_fonts = all_fonts.concat(fonts)
    })
    const unique = [...new Set(all_fonts.map((item) => item))]
    return unique
}
