import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { intercom } from './intercom'
import mixpanel from 'mixpanel-browser'

const is_production = process.env.NODE_ENV === 'production'

mixpanel.init('1c26d722c8b30acaa938e43c895a2411', { debug: false })

const UPGRADE_EVENT = 'Click Upgrade'

class Tracker {
    _track = (...args) => {
        if (is_production) return mixpanel.track(...args)
    }
    _track_links = (selector, event_name, properties) => {
        if (is_production) return mixpanel.track_links(selector, event_name, properties)
    }
    trackUpgradeLink = (selector, source) => this._track_links(selector, UPGRADE_EVENT, source)
    clickUpgrade = (source, cb) => this._track(UPGRADE_EVENT, source, cb)
    pageView = () => this._track('video_page_view')
    newVideo = (source) => this._track('Create New', source)
    edit = (source) => this._track('Edit', source)
    preview = (source) => this._track('Preview', source)
    download = (source) => this._track('Download', source)
    replaceAllScenes = (source) => this._track('Replace All Scenes', source)
    addScene = (source) => this._track('Add Scene', source)
    duplicateScene = (source) => this._track('Duplicate Scene', source)
    deleteScene = (source) => this._track('Delete Scene', source)
    modifySceneDuration = (source) => this._track('Modify Scene Duration', source)
    reorderScene = (source) => this._track('Reorder Scene', source)
    addElement = (source) => this._track('Add Element', source)
    addText = (source) => this._track('Add Text', source)
    addImage = (source) => this._track('Add Image', source)
    addVideo = (source) => this._track('Add Video', source)
    addAudio = (source) => this._track('Add Audio', source)
    uploadImage = (source) => this._track('Upload Image', source)
    uploadVideo = (source) => this._track('Upload Video', source)
    uploadAudio = (source) => this._track('Upload Audio', source)
    deleteAudio = (source) => this._track('Delete Audio', source)
    removeBackground = (source) => this._track('Remove Image Background', source)
    undo = (source) => this._track('Undo', source)
    redo = (source) => this._track('Redo', source)
    duplicate = (source) => this._track('Duplicate', source)
    delete = (source) => this._track('Delete', source)
    moveUp = (source) => this._track('Move Up', source)
    moveDown = (source) => this._track('Move Down', source)
    lock = (source) => this._track('Lock', source)
    zoomIn = (source) => this._track('Zoom In', source)
    zoomOut = (source) => this._track('Zoom Out', source)
    applyBackground = (source) => this._track('Apply Background', source)
    resize = (source) => this._track('Rezise', source)
}

export const tracker = new Tracker()

export const TrackPageView = () => {
    const location = useLocation()
    useEffect(() => tracker.pageView(), [location])
    intercom.update()
    return null
}

export const FROM = {
    BACKGROUND: 'Background Removal Limit',
    PROJECT: 'Project Limit',
    EXPORT: 'Export Limit',
    DASHBOARD: 'Dashboard',
    DUPLICATION: 'Duplication',
    TEMPLATE: 'Template',
    ACTION_MENU: 'Action Menu',
    EDITOR: 'Editor',
    TIMELINE: 'Timeline',
    SHAPE: 'Shape',
    QR_CODE: 'QR Code',
    LIBRARY: 'Library',
    PREVIEW: 'Preview',
    GIPHY: 'Giphy',
    LOTTIE: 'Lottie',
    PEXELS: 'Pexels',
    STICKERS: 'Stickers',
    THREE_D_STICKERS: '3D Stickers',
    UNSPLASH: 'Unsplash',
}
