import { useContext } from 'react'
import AuthUserContext from './auth-context'
import { AuthProvider } from './auth-provider'

const useAuth = () => {
    const auth = useContext(AuthUserContext)

    return auth
}

export { AuthUserContext, AuthProvider, useAuth }
