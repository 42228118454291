import { useEffect } from 'react'
import { Button } from '../UI'
import { SUBSCRIPTION_TYPES, VENNGAGE_SUBSCRIPTION_PLAN_MAPPING } from '../../constants/user'
import { useUser } from '../Query/use-user'
import { INFOGRAPH_URL } from '../../constants/auth'
import { tracker } from '../Track/track'

const getUpgradeLink = (subscription_type, venngage_subscription_plan) => {
    switch (subscription_type) {
        case SUBSCRIPTION_TYPES.FREE:
            return `${INFOGRAPH_URL}/account/subscription`
        case SUBSCRIPTION_TYPES.PREMIUM:
            return `${INFOGRAPH_URL}/account/subscription/business`
        case SUBSCRIPTION_TYPES.BUSINESS:
            return `${INFOGRAPH_URL}/infographics/my_team`
        default:
            break
    }
}

const subscriptionPlanHasUpgradeButton = (venngage_subscription_plan) => {
    switch (venngage_subscription_plan) {
        case VENNGAGE_SUBSCRIPTION_PLAN_MAPPING.EDUCATION:
            return false
        case VENNGAGE_SUBSCRIPTION_PLAN_MAPPING.EDUCATION_MEMBER:
            return false
        default:
            return true
    }
}

const getUpgradeText = (subscription_type) => {
    if (subscription_type === SUBSCRIPTION_TYPES.BUSINESS) return 'Invite Team'
    return 'Upgrade'
}

export const UpgradeButton = ({ template_id, from, project_id }) => {
    const { data: db_user } = useUser()
    const { subscription_type, venngage_subscription_plan } = db_user
    const has_upgrade_button = subscriptionPlanHasUpgradeButton(venngage_subscription_plan)
    const button_id = 'upgrade-button'

    useEffect(() => {
        if (has_upgrade_button) {
            const track_properties = { from }
            if (template_id) track_properties.template_id = template_id
            if (project_id) track_properties.composition_id = project_id

            tracker.trackUpgradeLink(`#${button_id}`, track_properties)
        }
    }, [has_upgrade_button, template_id, from, project_id])

    if (!has_upgrade_button) return null

    return (
        <Button
            fontWeight="500"
            as="a"
            id={button_id}
            variantColor="main"
            href={getUpgradeLink(subscription_type, venngage_subscription_plan)}
            size="sm"
            width="132px"
        >
            {getUpgradeText(subscription_type)}
        </Button>
    )
}
