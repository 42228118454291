import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import './index.css'
import * as serviceWorker from './serviceWorker'

import App from './components/App/app'

const loader = document.querySelector('.initial-loader')
const hideLoader = () => loader.classList.add('loader--hide')

const initSentry = () => {
    const is_production = process.env.NODE_ENV === 'production'
    const is_rendering = window.location.pathname.includes('/view')

    if (is_rendering) return
    if (!is_production) return

    Sentry.init({
        dsn: 'https://5de74211936148ddaec0272274914cd5@o508246.ingest.sentry.io/6044117',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    })
}

initSentry()
ReactDOM.render(<App hideLoader={hideLoader} />, document.getElementById('root'))

serviceWorker.unregister()
