import { Spinner as BaseSpinner } from '@chakra-ui/core'
import { Flex } from './stack'
import { Text } from './text'

export const Spinner = ({ size = 'xl', s_w, s_h, s_t, ...props }) => (
    <Flex height="100%" width="100%" alignItems="center" justifyContent="center" {...props}>
        <BaseSpinner
            size={size}
            width={s_w ? s_w : null}
            height={s_h ? s_h : null}
            thickness={s_t ? s_t : '4px'}
            speed="0.65s"
            emptyColor="gray.200"
            color="var(--main)"
        />
    </Flex>
)

export const FullWidthSpinner = ({ text }) => (
    <Flex minHeight="80vh" justifyContent="center" alignItems="center" width="100%" direction="column">
        {text && <Text mb="1rem">{text}</Text>}
        <Spinner />
    </Flex>
)
