import { ALL_FONTS, FONT_SIZES } from '../../constants/fonts'
import { loadStyleSheet } from '../../shared-fns/shared-fns'
import { Select } from './select'

export const FontWeightSelect = ({ onChange, defaultValue, font_name }) => {
    const current_font = ALL_FONTS.find((fs) => fs.name === font_name)
    if (!current_font) return null

    const { weights } = current_font

    return (
        <Select
            backgroundColor="var(--input-bg)"
            size="sm"
            onChange={(e) => {
                const { value } = e.target
                onChange(value)
            }}
            value={defaultValue}
            placeHolder={''}
        >
            {weights.map((fs) => (
                <option key={fs} value={fs}>
                    {fs}
                </option>
            ))}
        </Select>
    )
}

const LINE_HEIGHTS = [1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3]
export const LineHeightSelect = ({ onChange, defaultValue }) => {
    const onChangeAlignment = (e) => onChange(+e.target.value, e)

    return (
        <Select
            backgroundColor="var(--input-bg)"
            size="sm"
            onChange={onChangeAlignment}
            value={defaultValue}
            placeHolder={defaultValue}
        >
            {LINE_HEIGHTS.map((lh) => (
                <option key={lh} value={lh}>
                    {lh}
                </option>
            ))}
        </Select>
    )
}
const LETTER_SPACING = [
    -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
]
export const LetterSpacingSelect = ({ onChange, defaultValue }) => {
    const onChangeAlignment = (e) => onChange(+e.target.value)
    return (
        <Select
            backgroundColor="var(--input-bg)"
            size="sm"
            onChange={onChangeAlignment}
            value={defaultValue}
            placeHolder={defaultValue}
        >
            {LETTER_SPACING.map((lh) => (
                <option key={lh} value={lh}>
                    {lh}
                </option>
            ))}
        </Select>
    )
}

export const FontSizeSelect = ({ onChange, value }) => {
    const value_exists = FONT_SIZES.find((f) => f === value)

    return (
        <Select
            backgroundColor="var(--input-bg)"
            size="sm"
            onChange={(e) => {
                const { value } = e.target
                onChange(+value)
            }}
            value={value === 'Auto-fit' ? 300 : value}
            placeHolder={value}
        >
            {!value_exists && <option value={value}>{value}</option>}
            {FONT_SIZES.map((fs) => (
                <option key={fs} value={fs}>
                    {fs}
                </option>
            ))}
        </Select>
    )
}
const single_quote_regex = /'/g
const removeSingleQuotes = (string) => string.replace(single_quote_regex, '')
const alphabeticalSort = (a, b) => a.name.localeCompare(b.name)

export const FontStyleSelect = ({ onChange, defaultValue, has_custom_font }) => {
    const onChangeFont = (font_name) => {
        const f = ALL_FONTS.find((fs) => fs.name === font_name)
        loadStyleSheet(f.link, font_name).then(() => {
            onChange(font_name)
        })
    }

    return (
        <Select
            backgroundColor="var(--input-bg)"
            size="sm"
            onChange={(e) => onChangeFont(e.target.value)}
            value={defaultValue}
            placeHolder={removeSingleQuotes(defaultValue)}
        >
            {ALL_FONTS.sort(alphabeticalSort).map((fs) => (
                <option key={fs.name} value={fs.name}>
                    {removeSingleQuotes(fs.name)}
                </option>
            ))}
        </Select>
    )
}
