import { pop, slide, fade, fadeUp, fadeDown, bigIn, grow, smallIn } from './shared-marker-animations'
import { kenBurns, expand, panOut } from './media-animations'

const all_animations = {
    pop,
    slide,
    fade,
    fadeUp,
    fadeDown,
    big_in: bigIn,
    smallIn,
    expand,
    slow_grow: grow,
    kenBurns: kenBurns('center'),
    kenBurnsTopLeft: kenBurns('top left'),
    kenBurnsTopRight: kenBurns('top right'),
    panOut,
}

export const getAnimationFn = (animation) => {
    if (all_animations[animation]) return all_animations[animation]
    return fade
}
