import styled from '@emotion/styled'
import { useCallback, useEffect, useRef, useState, forwardRef } from 'react'
import { useTimerOnTime } from '../Timer/timer-hooks'
import { videoCurrentTime, useVideoTimeline } from '../Marker/use-video-timeline'
import { getAnimationFn } from './animations/animations'
import { show, hide, resetMarker } from './animations/shared-marker-animations'
import { useEdit } from '../Edit/edit-context'
import { emptyFn } from '../../shared-fns/shared-fns'

export const getMarkerTimeMs = (time) => {
    if (time) return [time[0] * 1000, time[1] * 1000]
}

const StyledItem = styled.div`
    &:hover {
        cursor: ${(props) => (props.visibility ? 'move' : '')};
    }
`

const isOverflowHidden = (animation) => {
    if (animation === 'slide') return true
    if (animation === 'kenBurns') return true
    if (animation === 'kenBurnsTopLeft') return true
    if (animation === 'kenBurnsTopRight') return true
    if (animation === 'panOut') return true
    return false
}

const MARKER_STATE = { IN: 'IN', OUT: 'OUT' }

const animationStateToOut = (animation_state) => {
    if (!animation_state) return null
    if (animation_state === 'enter_exit') return null
    if (animation_state === 'enter') return 0
    return null
}

export const MarkerBase = ({ override_animation, ...props }) => {
    const edit_context = useEdit()
    // in case you need to override the items animation (e.g. text)
    const animation = override_animation ? override_animation : props.item.animation

    return (
        <TimelineMarkerBase
            {...props}
            animation={animation}
            should_set_drag={edit_context ? edit_context.should_set_drag : null}
        />
    )
}

const removeDrag = (id) => {
    const marker = document.getElementById('drag' + id)
    if (marker) {
        marker.classList.remove('draggable-marker-box')
        marker.style.pointerEvents = 'none'
        // marker.style.contentVisibility = 'hidden'
    } else {
        console.log('removeDrag NOT FOUND: ', id)
    }
}

const addDrag = (id) => {
    const marker = document.getElementById('drag' + id)
    if (marker) {
        marker.classList.add('draggable-marker-box')
        marker.style.pointerEvents = 'unset'
        // TODO: this hides certain animation overflows
        // marker.style.contentVisibility = 'auto'
    } else {
        console.log('NOT FOUND: ', id)
    }
}

const hasNoEnterTransition = (animation) => {
    return animation === 'kenBurns' || animation === 'none'
}

export const TimelineMarkerBase = ({
    children,
    is_refs_assigned,
    markerAnimationIn = emptyFn,
    markerAnimationOut = emptyFn,
    canvas_ref,
    should_set_drag,
    animation,
    ...props
}) => {
    const animation_ref = useRef()
    const { timer, video_duration_ms, item } = props
    const [timeline, setTimeline] = useState()

    const setAnimation = () => {
        if (!animation_ref || !animation_ref.current) return
        if (timeline) {
            timeline.seek(0.01)
            timeline.clear()
            timeline.kill()
            if (animation === 'none') resetMarker(animation_ref.current)
        }
        if (animation === 'none') {
            setTimeline(null)
            return
        }

        const animationFn = getAnimationFn(animation)
        const duration = item.time[1] - item.time[0]

        // TODO: add offset of 30ms?
        const is_full_length = item.time[1] * 1000 === video_duration_ms

        const out = animationStateToOut(item.animation_state)
        const [animation_timeline] = animationFn({
            is_view_start: item.is_view_start,
            target_ref: animation_ref,
            duration,
            start: item.time[0],
            is_full_length,
            animation_speed: item.animation_speed ? item.animation_speed : 1,
            out,
        })

        if (animation_timeline) {
            const t = timer.getMs()

            const start = videoCurrentTime(t, item.time[0])
            animation_timeline.seek(t === video_duration_ms ? 0.001 : start)
        }

        setTimeline(animation_timeline)
    }

    useEffect(() => {
        setAnimation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        item.time,
        animation,
        item.animation_speed,
        video_duration_ms,
        animation_ref,
        item.size,
        item.scale,
        item.animation_state,
        item.position,
    ])

    const playVideo = () => {
        if (timeline && timeline.data >= 0) timeline.play()
    }

    const pauseVideo = () => {
        if (timeline) timeline.pause()
    }

    const setVideoTime = (t) => {
        const start = videoCurrentTime(t, item.time[0])

        if (timeline) {
            timeline.data = start
            if (start < 0) {
                timeline.seek(0)
            } else {
                timeline.seek(start)
            }
        }
    }

    useVideoTimeline({ timer, video_duration_ms, item, setVideoTime, playVideo, pauseVideo })

    return (
        <MarkerBaseController
            ref={animation_ref}
            animation={animation}
            setAnimation={setAnimation}
            animationIn={(t) => {
                setVideoTime(t)
                if (timer.isPlaying()) playVideo()

                markerAnimationIn(t)
                if (animation === 'none') show(animation_ref)

                if (should_set_drag) {
                    addDrag(item.id)
                }
            }}
            animationOut={(t) => {
                setVideoTime(t)
                markerAnimationOut()

                if (animation === 'none') hide(animation_ref)
                if (should_set_drag) {
                    removeDrag(item.id)
                }
            }}
            {...props}
        >
            {children}
        </MarkerBaseController>
    )
}

export const MarkerBaseController = forwardRef(
    (
        {
            setAnimation,
            children,
            start_ms,
            end_ms,
            animationIn,
            animationOut,
            animation,
            video_duration_ms,
            timer,
            item,
            visible_markers,
        },
        ref
    ) => {
        const marker_state_ref = useRef(MARKER_STATE.OUT)
        const markerTransitionHandler = useCallback(
            (ms) => {
                const t = video_duration_ms - ms
                const is_entering = marker_state_ref.current !== MARKER_STATE.IN && t >= start_ms && t < end_ms
                if (is_entering) {
                    marker_state_ref.current = MARKER_STATE.IN
                    animationIn(t)
                }

                const is_exiting = t < start_ms || t > end_ms
                if (is_exiting) {
                    if (marker_state_ref.current !== MARKER_STATE.OUT) {
                        animationOut(t)
                    }
                    marker_state_ref.current = MARKER_STATE.OUT
                }
            },
            [end_ms, start_ms, animationIn, animationOut, video_duration_ms]
        )

        useTimerOnTime(markerTransitionHandler, timer)

        useEffect(() => {
            function resetAndForceMarkerAnimation() {
                marker_state_ref.current = ''
                markerTransitionHandler(timer.getMs())
            }
            if (animation && marker_state_ref.current === MARKER_STATE.IN) {
                resetAndForceMarkerAnimation()
            } else {
                if (animation === 'none') return resetAndForceMarkerAnimation()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [animation, video_duration_ms])

        useEffect(() => {
            markerTransitionHandler(timer.getMs())

            // When slider updates --> marker might be in/out of time
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [start_ms, end_ms])

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(() => setAnimation(), [])

        const visibility = hasNoEnterTransition(animation) ? '' : 'hidden'

        return (
            <div style={{ height: '100%', width: '100%', overflow: isOverflowHidden(animation) ? 'hidden' : null }}>
                <StyledItem
                    id="MarkerBaseController"
                    style={{
                        height: '100%',
                        width: '100%',
                        visibility,
                    }}
                    ref={ref}
                >
                    {children}
                </StyledItem>
            </div>
        )
    }
)
