import styled from '@emotion/styled'
import { Tab } from '@chakra-ui/core'

export const StyledTab = styled(Tab)`
    box-shadow: none !important;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: var(--grey-5) !important;

    &[aria-selected='true'] {
        color: var(--main) !important;
    }
`
