import { round } from '../../shared-fns/shared-fns'
import { markerUtil } from '../Marker/marker-fns'
import { MAX_SCENE_DURATION, MAX_VIDEOS_PER_PROJECT } from '../../constants/api'

// frame icons
import { ReactComponent as FrameIcon } from '../../assets/icons/bkground.svg'
import { ReactComponent as TextIcon } from '../../assets/icons/text.svg'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'
import { ReactComponent as ElementIcon } from '../../assets/icons/shapes.svg'
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg'
import { ReactComponent as VideoIcon } from '../../assets/icons/video.svg'
import { ReactComponent as LibraryIcon } from '../../assets/icons/book-open.svg'
import { ReactComponent as AudioIcon } from '../../assets/icons/music.svg'

export const SIDEBAR_WIDTH = 54
export const EDIT_NAVBAR_HEIGHT = '60px'
export const BOTTOM_SECTION_HEIGHT = '191px'

export const MAX_VIDEOS_PER_PROJECT_TOAST = {
    title: 'Video Limit Reached',
    description: `A project cannot have more than ${MAX_VIDEOS_PER_PROJECT} videos`,
    status: 'error',
}

export const SIDE_BAR_LABELS = {
    LIBRARY: 'Library',
    BACKGROUND: 'Background',
    TEXT: 'Text',
    ELEMENTS: 'Elements',
    IMAGES: 'Images',
    VIDEOS: 'Videos',
    AUDIO: 'Audio',
    UPLOAD: 'Upload',
}

export const side_bar_items = [
    { text: SIDE_BAR_LABELS.LIBRARY, test_id: 'library' },
    { text: SIDE_BAR_LABELS.BACKGROUND, keycode: 'B', test_id: 'background' },
    { text: SIDE_BAR_LABELS.TEXT, keycode: 'T', test_id: 'text' },
    { text: SIDE_BAR_LABELS.ELEMENTS, keycode: 'E', test_id: 'elements' },
    { text: SIDE_BAR_LABELS.IMAGES, keycode: 'I', test_id: 'images' },
    { text: SIDE_BAR_LABELS.VIDEOS, keycode: 'V', test_id: 'videos' },
    { text: SIDE_BAR_LABELS.AUDIO, keycode: 'A', test_id: 'audio' },
    { text: SIDE_BAR_LABELS.UPLOAD, keycode: 'U', test_id: 'upload' },
]

export const SIDE_BAR_ICON_MAP = {
    [SIDE_BAR_LABELS.BACKGROUND]: FrameIcon,
    [SIDE_BAR_LABELS.LIBRARY]: LibraryIcon,
    [SIDE_BAR_LABELS.TEXT]: TextIcon,
    [SIDE_BAR_LABELS.ELEMENTS]: ElementIcon,
    [SIDE_BAR_LABELS.IMAGES]: ImageIcon,
    [SIDE_BAR_LABELS.VIDEOS]: VideoIcon,
    [SIDE_BAR_LABELS.AUDIO]: AudioIcon,
    [SIDE_BAR_LABELS.UPLOAD]: UploadIcon,
}

export const hasBorderRadiusSelect = (item) => {
    if (item && item.type) {
        if (markerUtil.isBox(item) && item.text === 'Circle') return false
        if (markerUtil.isImage(item) && item.text === 'Icon') return false
        if (markerUtil.isText(item)) return false
        if (markerUtil.isQRCode(item)) return false
        if (markerUtil.isLottie(item)) return false
        if (markerUtil.isAudio(item)) return false
        if (item.svg) return false
        return true
    }
    return false
}

export const getDragItemId = (el) => el.id.replace('drag', '')
export const getAllActiveItemIds = (active_item) => active_item.map(getDragItemId)
export const getActiveItemId = (active_item) => {
    if (!active_item.length) return null
    if (active_item.length > 1) return null

    const id = getDragItemId(active_item[0])
    return id
}
export const getNewVideoEndTime = (duration) => {
    const new_time = round(duration > MAX_SCENE_DURATION ? MAX_SCENE_DURATION : duration, 1)
    return new_time
}

export const isTopLayer = (timeline, item_idx) => item_idx === timeline.length - 1
export const isBottomLayer = (item_idx) => item_idx === 0
export const getLayerIdx = (timeline, id) => timeline.findIndex((t) => t.id === id)
export const CLIPBOARD_KEY = 'clipboard_id'
export const SCENE_CLIPBOARD_KEY = 'SCENE_COPY_'
