import { ThemeProvider, CSSReset, theme } from '@chakra-ui/core'
import styled from '@emotion/styled'
import Navigation from '../Navigation/navigation'
import { DEVICE } from '../../constants/device-breakpoints'
import { Box } from '../UI/stack'

const Main = styled.main`
    height: 100%;
`
const modified_theme = {
    ...theme,
    colors: {
        ...theme.colors,
        green: { 500: '#00A780', 600: '#14d0a5' },
        main: { 500: '#0082FF', 600: '#0273dd' },
    },
    fonts: {
        body: "'Inter', sans-serif;",
        heading: "'Inter', sans-serif;",
        mono: "'Inter', sans-serif;",
    },
}

export const Theme = ({ children }) => (
    <ThemeProvider theme={modified_theme}>
        <CSSReset />
        {children}
    </ThemeProvider>
)

export const Layout = ({ children }) => {
    return (
        <Theme>
            <Navigation />
            <Main>{children}</Main>
        </Theme>
    )
}

export const Container = styled(Box)`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 85%;

    @media ${DEVICE.desktop} {
        max-width: 1500px;
    }
    @media ${DEVICE.laptopL} {
        width: 90%;
    }
    @media ${DEVICE.desktopL} {
        max-width: 1700px;
    }
    @media ${DEVICE.tabletL} {
        width: 95%;
        padding-left: 0;
        padding-right: 0;
    }
`
