import { Flex } from '../UI/stack'
import { Text } from '../UI/text'
import { Theme } from './layout'
import { Button } from '../UI/button'

export const MobileInProgressBanner = () => (
    <Theme>
        <Flex
            bg="black"
            width="100vw"
            height="100vh"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="8px"
        >
            <Text pb="8px" fontWeight="bold" fontSize="24px" textAlign="center" color="white">
                <span style={{ paddingRight: '8px' }} role="img" aria-label="Builder">
                    👷‍♂️
                </span>
                Under construction
            </Text>
            <Text fontSize="16px" color="white" textAlign="center">
                Venngage video is coming to mobile soon. For now, please use the desktop version.
            </Text>
            <Button onClick={() => window.history.back()} mt="16px" variantColor="main">
                Go Back
            </Button>
        </Flex>
    </Theme>
)
