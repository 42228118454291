import { useCallback, useRef } from 'react'
import { videoCurrentTime, useVideoTimeline } from '../Marker/use-video-timeline'
import { MarkerBase, getMarkerTimeMs } from './marker-base'

export const AudioMarker = ({ item, timer, video_duration_ms }) => {
    const audio_ref = useRef()

    const { url, time, id } = item

    const pauseVideo = () => audio_ref?.current.pause()
    const playVideo = () => audio_ref?.current.play()
    const [start_ms, end_ms] = getMarkerTimeMs(time)

    const setVideoTime = useCallback(
        async (t) => {
            if (!audio_ref || !audio_ref.current || !audio_ref.current.duration) return
            const start = videoCurrentTime(t, time[0], audio_ref.current.duration)
            audio_ref.current.currentTime = start
        },
        [time]
    )

    useVideoTimeline({ timer, video_duration_ms, item, setVideoTime, playVideo, pauseVideo })

    return (
        <MarkerBase
            item={item}
            video_duration_ms={video_duration_ms}
            timer={timer}
            animation={'none'}
            markerAnimationIn={(t) => {
                setVideoTime(t)
                if (timer.isPlaying()) playVideo()
            }}
            markerAnimationOut={(t) => {
                pauseVideo()
            }}
            start_ms={start_ms}
            end_ms={end_ms}
        >
            <audio style={{ display: 'none' }} id={`drag${id}`} ref={audio_ref} loop src={url} />
        </MarkerBase>
    )
}
