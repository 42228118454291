import { gsap } from 'gsap'
import { ExpoScaleEase } from 'gsap/EasePack'
gsap.registerPlugin(ExpoScaleEase)

export const reset = (el) => gsap.to(el, { autoAlpha: 1, scale: 1, x: 0, y: 0, rotation: 0, rotationY: 0, duration: 0 })

const grow = (el) => {
    gsap.fromTo(
        el,
        { autoAlpha: 1, scale: 1 },
        {
            autoAlpha: 1,
            scale: 1.3,
            duration: 0.7,
            display: 'block',
            ease: 'power1.out',
            rotation: 0.01,
            force3D: true,
        }
    )
}
const kenBurns = (direction) => (el, cb) => {
    gsap.fromTo(
        el,
        { autoAlpha: 1, scale: 1 },
        {
            autoAlpha: 1,
            scale: 1.3,
            duration: 1,
            transformOrigin: direction,
            display: 'block',
            ease: ExpoScaleEase.config(1, 1.3),
            rotation: 0.01,
            force3D: true,
            onComplete: cb,
        }
    )
}
const panOut = (el, cb) => {
    gsap.fromTo(
        el,
        { autoAlpha: 1, scale: 1.4 },
        {
            autoAlpha: 1,
            scale: 1,
            duration: 1,
            transformOrigin: 'center',
            display: 'block',
            ease: ExpoScaleEase.config(1.4, 1),
            rotation: 0.01,
            force3D: true,
            onComplete: cb,
        }
    )
}

const none = (el) => gsap.fromTo(el, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0, display: 'block' })

const fade = (el) => {
    gsap.fromTo(el, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.4, ease: 'power2.in', display: 'block' })
}

const pop = (el) =>
    gsap.fromTo(
        el,
        { scale: 0.5, autoAlpha: 0 },
        { autoAlpha: 1, scale: 1, duration: 0.5, display: 'block', ease: 'back.out(2)' }
    )

const big_in = (el) =>
    gsap.fromTo(
        el,
        { autoAlpha: '0', scale: 2 },
        { autoAlpha: '1', scale: 1, duration: 0.4, display: 'block', ease: 'power1.out' }
    )
const smallIn = (el) =>
    gsap.fromTo(
        el,
        { autoAlpha: 0, scale: 0 },
        { autoAlpha: 1, scale: 1, duration: 0.4, display: 'block', ease: 'power1.out' }
    )

const slide = (el) =>
    gsap.fromTo(
        el,
        { autoAlpha: 0, x: -50 },
        { autoAlpha: 1, x: 0, duration: 0.3, display: 'block', ease: 'power2.in' }
    )

const fadeUp = (el) =>
    gsap.fromTo(
        el,
        { autoAlpha: 0, y: '25%' },
        { autoAlpha: 1, y: 0, duration: 0.3, display: 'block', ease: 'power2.in' }
    )

const fadeDown = (el) =>
    gsap.fromTo(
        el,
        { autoAlpha: 0, y: '-25%' },
        { autoAlpha: 1, y: 0, duration: 0.3, display: 'block', ease: 'power2.in' }
    )

const reveal = (el, cb) => {
    gsap.fromTo(
        el,
        { width: '0%', marginLeft: '50%' },
        {
            width: '100%',
            force3D: true,
            rotation: 0.01,
            marginLeft: 0,
            duration: 0.35,
            ease: 'sine.inOut',
            onComplete: cb,
        }
    )
    gsap.to(el, { autoAlpha: 1, scale: 1, x: 0, y: 0, rotation: 0, rotationY: 0, duration: 0, delay: 0.7 })
}

export const scale = (el, scale) => {
    gsap.to(el, {
        duration: 0.2,
        scale,
        ease: 'power4.in',
    })
}

export const fill = (el, width) => {
    gsap.to(el, {
        width,
        duration: 0.4,
        ease: 'power2.in',
    })
}

const all_preview_animations = {
    slow_grow: grow,
    none,
    fade,
    pop,
    big_in,
    smallIn,
    slide,
    fadeUp,
    fadeDown,
    expand: reveal,
}

const media_preview_animations = {
    panOut,
    kenBurns: kenBurns('center'),
    kenBurnsTopLeft: kenBurns('top left'),
    kenBurnsTopRight: kenBurns('top right'),
}
export const getMediaAnimationIn = (animation) => {
    return media_preview_animations[animation]
}

export const getButtonAnimationIn = (animation) => {
    return all_preview_animations[animation]
}
